import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import PDFViewer from "pdf-viewer-reactjs";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    height: "30rem",
    overflowY: "scroll",
  },
}));

export default function ViewDoc({ path, open, handleClose, setOpen }) {
  const classes = useStyles();

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            {path && (
              <PDFViewer
                document={{
                  url: `${process.env.REACT_APP_DEV}/api/admin/video?videoPath=${path}`,
                }}
              />
            )}
          </div>
        </Fade>
      </Modal>
    </div>
  );
}
