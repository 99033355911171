import React, { useState } from "react";
import "./HotelAddress.css";
import { MdModeEdit } from "react-icons/md";
import { FaTrashAlt } from "react-icons/fa";
import axios from "axios";
import { getCommonListAction } from "../../../redux/actions/getCommonList";
import { useDispatch, useSelector } from "react-redux";
import { columns } from "./columns";
import PaginationTable from "../../adminUi/PaginationTable";
import Snackbar from "../../../Components/uiComponets/snackbar/Snackbar";
import EditModal from "./HotelEditModal";
import DeleteModal from "./DeleteHotel";
import { updateNavbarHeading } from "../../../redux/actions/uiActions";

let url = `${process.env.REACT_APP_DEV}/api/Hr/getAccommodation`;

function HotelAddress() {
  const dispatch = useDispatch();
  const { loading, error, list } = useSelector(
    (state) => state.getCommonListReducer
  );
  const [hotelState, setHotelState] = useState({
    hotelName: "",
    hotelAddress: "",
    hotelAddress2: "",
    hotelAddress3: "",
    hotelAddress4: "",
    hotelAddress5: "",
  });

  const [notification, setNotification] = React.useState({
    success: null,
    fail: null,
  });
  React.useEffect(() => {
    dispatch(getCommonListAction(url));
    dispatch(updateNavbarHeading("Hotel Address"));
    setNotification({ success: false, fail: false });
  }, []);
  const handleChange = (e) => {
    setHotelState({
      ...hotelState,
      [e.target.name]: e.target.value,
    });
  };
  const handleSubmit = (e) => {
    e.preventDefault();

    axios
      .post(
        `${process.env.REACT_APP_DEV}/api/Hr/addAccommodationWithAddress`,
        hotelState
      )
      .then((res) => {
        setNotification({ success: true, fail: false });
        dispatch(getCommonListAction(url));
        setHotelState({
          hotelName: "",
          hotelAddress: "",
          hotelAddress2: "",
          hotelAddress3: "",
          hotelAddress4: "",
          hotelAddress5: "",
        });
      })
      .catch((err) => setNotification({ success: false, fail: true }));
  };
  console.log(list);
  const data =
    list.length &&
    list.map((li, idx) => {
      return {
        idx: idx + 1,
        hotelName: li.hotelName,
        hotelAddress: li.hotelAddress,
        hotelAddress2: li.hotelAddress2,
        hotelAddress3: li.hotelAddress3,
        hotelAddress4: li.hotelAddress4,
        hotelAddress5: li.hotelAddress5,
        action_button: <EditModal id={li.id} />,
        action_button2: <DeleteModal id={li.id} />,
      };
    });
  return (
    <>
      <div className="content">
        <div className="content-wrapper">
          <div className="container-fluid ">
            <form onSubmit={handleSubmit}>
              <div className="row">
                <div class="col-md-4 mb-3">
                  <span className=" input-labels">Hotel Name</span>
                  <input
                    name="hotelName"
                    onChange={handleChange}
                    className="form-control"
                    pattern="[a-zA-Z ]+"
                    title="** only alphabets allowed"
                    value={hotelState.hotelName}
                    required
                  />
                </div>
                <div class="col-md-4 mb-3">
                  <span className=" input-labels">
                    Hotel Address
                    {/* <sup className="fa fa-asterisk asterisk"></sup> */}
                  </span>
                  <input
                    name="hotelAddress"
                    onChange={handleChange}
                    className="form-control"
                    value={hotelState.hotelAddress}
                    required
                  />
                </div>
                <div class="col-md-4 mb-3">
                  <span className="input-labels">
                    City
                    {/* <sup className="fa fa-asterisk asterisk"></sup> */}
                  </span>
                  <input
                    name="hotelAddress2"
                    onChange={handleChange}
                    className="form-control"
                    value={hotelState.hotelAddress2}
                    required
                  />
                </div>
                <div class="col-md-4 mb-3">
                  <span className=" input-labels">State</span>
                  <input
                    name="hotelAddress3"
                    onChange={handleChange}
                    className="form-control"
                    value={hotelState.hotelAddress3}
                  />
                </div>
                <div class="col-md-4 mb-3">
                  <span className=" input-labels">Country</span>
                  <input
                    name="hotelAddress4"
                    onChange={handleChange}
                    className="form-control"
                    value={hotelState.hotelAddress4}
                  />
                </div>
                <div class="col-md-4 mb-3">
                  <span className=" input-labels">PIN Code</span>
                  <input
                    name="hotelAddress5"
                    onChange={handleChange}
                    className="form-control"
                    value={hotelState.hotelAddress5}
                  />
                </div>
                <div>
                  <button className="btn6 mt-3 btn-primary">Submit</button>
                </div>
              </div>
            </form>

            <div className="row mt-5">
              <div className="col-lg-12 col-md-12 col-sm-12">
                {list.length > 0 && (
                  <PaginationTable columnsArr={columns} dataArr={data} />
                )}
                {notification.success && (
                  <Snackbar msg="Hotel addedd successfully" type="success" />
                )}
                {notification.fail && (
                  <Snackbar msg="Something went wrong" type="error" />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default HotelAddress;
