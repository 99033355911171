import { getHrAccessList } from "../constants/constants";

const { ALL_COMPANY_LIST,   HR_SELECTED_COMPANY} = getHrAccessList;

export const setHrAccessCompanyList = (list) => {
  return {
    type: ALL_COMPANY_LIST,
    payload: list,
  };
};

export const hrSelectedCompany = (item) => {
    return {
      type: HR_SELECTED_COMPANY,
      payload: item,
    };
  };
