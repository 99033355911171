import axios from "axios";
import React from "react";
import { useDispatch } from "react-redux";
import { Redirect } from "react-router-dom";
import Navbar from "../HR/navbar/NavbarHR";
import HrSidebar from "../HR/Sidebar/SidebarHR";
import { getCompanyDetailsAction } from "../redux/actions/getCompanyDetails";
import {
  getCompanyList,
  getCompanyListFail,
  getCompanyListSuccess,
} from "../redux/actions/getCompanyList";
import { setHrAccessCompanyList } from "../redux/actions/hrAccessCompanyList";
import { getHrRoleDataById } from "../redux/actions/hrCandidateList";

let companyObj = {
  SEEDS: { name: "Namdhari Seeds Pvt.Ltd", id: 156, value: "SEEDS" },
  AGRO: { name: "Namdhari Agro Fresh", id: 157, value: "AGRO" },
  ANIMAL: { name: "Dairy Tales Namdharis", id: 158, value: "ANIMAL" },
  HEALTHCARE: { name: "SJS Health Care Ltd.", id: 159, value: "HEALTHCARE" },
};
const HrLayout = ({ children }) => {
  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(getCompanyList());
    axios
      .get(`${process.env.REACT_APP_DEV}/api/getAllLocationListEntityWise`)
      .then((res) => {
        let list = res.data.map((company) => {
          return {
            companyName: company.companyName,
            companyId: parseInt(company.companyId),
          };
        });
        dispatch(getCompanyListSuccess(list));
      })
      .catch((err) => dispatch(getCompanyListFail(err)));
  }, []);
  let hr = JSON.parse(sessionStorage.getItem("hr"));
  if (hr === null) return <Redirect to="/" />;
  let hrCompanyList = JSON.parse(localStorage.getItem("hrList"));
  if (hrCompanyList) {
    const { adminId } = JSON.parse(localStorage.getItem("hrList"));
    dispatch(getHrRoleDataById(adminId));
  }
  if (!hrCompanyList) {
    return <Redirect to="/" />;
  }
  if (hrCompanyList) {
    let temp = hrCompanyList?.entity?.split(",")?.map((cm) => companyObj[cm]);
    dispatch(setHrAccessCompanyList(temp));
  }
  return (
    <>
      <HrSidebar />
      <Navbar />
      {children}
    </>
  );
};

export default HrLayout;
