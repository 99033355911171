import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateNavbarHeading } from "../../../redux/actions/uiActions";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Snackbar from "../../../Components/uiComponets/snackbar/Snackbar";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import PaginationTable from "../../adminUi/PaginationTable";
import { getLeaderAction } from "../../../redux/actions/getleader";
import axios from "axios";
import {
  Leader_Request,
  Leader_Success,
  Leader_Fail,
} from "../../../redux/actions/companyleader";
import EditModal from "./LeaderEditModal";
import DeleteModal from "./DeleteModal";
import ImageModal from "./ImageOpenModal";
import { Loader } from "../../../loader/Loader";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    width: "40rem",
  },
  card: {
    marginBottom: "0",
    width: "100%",
  },
}));
const leaderObj = {
  file2: " ",
  selectCompany: " ",
  country: " ",
  location: " ",
  itLeader: " ",
  jobTitle: " ",
  description: " ",
  description2: " ",
};

const CompanyLeader = () => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state.getLeaderReducer);
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [newLeader, setNewLeader] = useState(leaderObj);
  
  const [loader, setLoader] = useState(false);

  const [notification, setNotification] = useState({
    success: null,
    fail: null,
  });
  const { companyList } = useSelector((state) => state.getCompanyListReducer);

  useEffect(() => {
    setNotification({ fail: false, success: false });
    dispatch(updateNavbarHeading("Company Leaders"));
    dispatch(getLeaderAction());
  }, []);

  const handleChange = (e) => {
    if (e.target.type === "file") {
      setNewLeader({
        ...newLeader,
        [e.target.name]: e.target.files[0],
      });
    } else {
      setNewLeader({
        ...newLeader,
        [e.target.name]: e.target.value,
      });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let newCompanyLeader = new FormData();
    newCompanyLeader.append("file2", newLeader.file2);
    newCompanyLeader.append("selectCompany", parseInt(newLeader.selectCompany));
    newCompanyLeader.append("country", newLeader.country);
    newCompanyLeader.append("location", newLeader.location);
    newCompanyLeader.append("itLeader", newLeader.itLeader);
    newCompanyLeader.append("jobTitle", newLeader.jobTitle);
    newCompanyLeader.append("description", newLeader.description);
    newCompanyLeader.append("description2", newLeader.description2);
    
    dispatch(Leader_Request());
    
    let url = `${process.env.REACT_APP_DEV}/api/addIGTLeader`;
    setLoader(true);
    axios
    .post(url, newCompanyLeader)
    .then((res) => {
        setLoader(false);
        dispatch(Leader_Success(res.newCompanyLeader));
        setNewLeader(leaderObj);
        setNotification({ fail: false, success: true });
        dispatch(getLeaderAction());
      })
      .catch((err) => {
        setLoader(false);
        dispatch(Leader_Fail(err));
        setNotification({ fail: true, success: false });
      });
    setOpen(false);
  };

  const columns = [
    {
      Header: "S.no",
      accessor: "idx",
    },
    {
      Header: "Region",
      accessor: "region",
    },
    {
      Header: "Location",
      accessor: "locationn",
    },
    {
      Header: "Business Leader",
      accessor: "leeaders",
    },
    {
      Header: "Company Name",
      accessor: "companyName",
    },

    {
      Header: "Designation",
      accessor: "Designations",
    },
    {
      Header: "Description1",
      accessor: "description_one",
    },
    {
      Header: "Description2",
      accessor: "description_two",
    },
    {
      Header: "Leader Photo",
      accessor: "leader_image",
    },
    {
      Header: "Action",
      accessor: "Action_Button",
    },
    {
      Header: "",
      accessor: "Action_Button2",
    },
  ];

  const data =
    state?.leaderList?.length > 0 &&
    state?.leaderList?.map((leader, idx) => {
      return {
        idx: idx + 1,

        region: leader.country || "No data found",
        locationn: leader.location || "No data found",
        leeaders: leader.itLeader || "No data found",
        companyName: leader.companyName,
        leader_image: <ImageModal src={leader.itPhoto} /> || "No data found",
        Designations: leader.jobTitle || "No data found",
        description_one: leader.description || "No data found",
        description_two: leader.description2 || "No data found",
        Action_Button: <EditModal id={leader.id} />,
        Action_Button2: <DeleteModal id={leader.id} />,
      };
    });
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  if (loader)
    return (
        <Loader open="true" />
    );
  return (
    <>
      <div className="content">
        <div className="content-wrapper">
          <div className="container-fluid">
            <div>
              {state?.leaderList.length > 0 && (
                <PaginationTable
                  columnsArr={columns}
                  dataArr={data}

                />
              )}

            </div>
            <div>
              <div className="submmit">
                <button
                  class="btn6 btn- mt-4 mb-4 ml-2"
                  style={{ color: "white" }}
                  onClick={handleOpen}
                >
                  + ADD LEADER
                </button>
              </div>
            </div>
            <Modal
              aria-labelledby="transition-modal-title"
              aria-describedby="transition-modal-description"
              className={classes.modal}
              open={open}
              onClose={handleClose}
              closeAfterTransition
              BackdropComponent={Backdrop}
              BackdropProps={{
                timeout: 500,
              }}
            >
              <Fade in={open}>
                <div className={classes.paper}>
                  <div className={`card ${classes.card}`}>
                    <div className="card-header">
                      <h3 className="card-title">ADD LEADER</h3>
                    </div>
                    <div className="card-body leader-modal">
                      <h6>
                        <strong>NOTE:</strong>Kindly upload jpg/jpeg file only
                      </h6>
                      <form onSubmit={handleSubmit} style={{ width: "100%" }}>
                        <section className="d-flex justify-content-between">
                          <div className="form-group d-flex flex-column">
                            <label>Company  <sup className="fa fa-asterisk asterisk"></sup></label>
                            <select
                              name="selectCompany"
                              onChange={handleChange}
                              className="form-control"
                              required
                            >
                              <option value="" selected disabled="selected">
                                Select Company
                              </option>
                              {companyList?.map((company) => (
                                <option value={company.companyId} key={company}>
                                  {company.companyName}
                                </option>
                              ))}
                            </select>
                          </div>
                          <div className="form-group d-flex flex-column">
                            <label>Bussiness Leader Photo  <sup className="fa fa-asterisk asterisk"></sup></label>
                            <input
                              name="file2"
                              onChange={handleChange}
                              type="file"
                              className="form-control"
                              required
                              accept=".jpg, .jpeg, .png"
                            />
                          </div>
                        </section>
                        <section className="d-flex justify-content-between">
                          <div className="form-group d-flex flex-column">
                            <label>Bussiness Leader  <sup className="fa fa-asterisk asterisk"></sup></label>
                            <input
                              name="itLeader"
                              onChange={handleChange}
                              className="form-control"
                              required
                              pattern="[a-zA-Z ]+"
                              title="** only alphabets allowed"
                            />
                          </div>
                          <div className="form-group d-flex flex-column">
                            <label>Designation  <sup className="fa fa-asterisk asterisk"></sup></label>
                            <input
                              name="jobTitle"
                              onChange={handleChange}
                              className="form-control"
                              required
                              pattern="[a-zA-Z ]+"
                              title="** only alphabets allowed"
                            />
                          </div>
                        </section>
                        <section className="d-flex justify-content-between">
                          <div className="form-group d-flex flex-column">
                            <label>location  <sup className="fa fa-asterisk asterisk"></sup></label>
                            <input
                              name="location"
                              onChange={handleChange}
                              className="form-control"
                              required
                              pattern="[a-zA-Z ]+"
                              title="** only alphabets allowed"
                            />
                          </div>
                          <div className="form-group d-flex flex-column">
                            <label>Region  <sup className="fa fa-asterisk asterisk"></sup></label>
                            <input
                              name="country"
                              onChange={handleChange}
                              className="form-control"
                              required
                              pattern="[a-zA-Z ]+"
                              title="** only alphabets allowed"
                            />
                          </div>
                        </section>

                        <section className="d-flex justify-content-between">
                          <div className="form-group d-flex flex-column">
                            <label>Description 1  <sup className="fa fa-asterisk asterisk"></sup></label>
                            <textarea
                              name="description"
                              onChange={handleChange}
                              className="form-control"
                              required
                            />
                          </div>
                          <div className="form-group d-flex flex-column">
                            <label>Description 2</label>
                            <textarea
                              name="description2"
                              onChange={handleChange}
                              // required
                              className="form-control"
                            />
                          </div>
                        </section>
                        <section className="text-right d-flex ">
                          <button
                            type="submit"
                            className="btn btn-sm btn-primary text-white"
                          >
                            Submit
                          </button>
                          <button
                            onClick={handleClose}
                            type="submit"
                            className="btn btn-sm btn-primary ml-1 text-white"
                          >
                            close
                          </button>
                        </section>
                      </form>
                      {notification.success && (
                        <Snackbar
                          msg="Company Leader addedd successfully"
                          type="success"
                        />
                      )}
                      {notification.fail && (
                        <Snackbar msg="Something went wrong" type="error" />
                      )}
                    </div>
                  </div>
                </div>
              </Fade>
            </Modal>
          </div>
        </div>
      </div>
    </>
  );
};
export default CompanyLeader;
