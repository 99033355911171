import { benefitList } from "../constants/constants";
const intialState = {
  loading: null,
  benefitList: [],
  error: "",
};

export const getBenefitListReducer = (state = intialState, action) => {
  switch (action.type) {
    case benefitList.LOADING:
      return {
        ...state,
        loading: true,
      };
    case benefitList.BENEFIT_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        benefitList: action.payload,
      };
    case benefitList.BENEFIT_LIST_fail:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
