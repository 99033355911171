import axios from "axios";

export const playVideo = (src) => {
  if (src) {
    const config = {
      method: "get",
      url: `${process.env.REACT_APP_DEV}/api/admin/video?videoPath=${src}`,
      responseType: "blob",
    };
    return axios(config);
  }
  return "/assets/noimage.png"
};
