import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import { AiFillEye } from "react-icons/ai";
import { playVideo } from "./adminServices/admin.services";
import "./model.css";
import PDFViewer from "pdf-viewer-reactjs";

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: 500,
    height: 500,
    boxShadow: theme.shadows[5],
  },
}));

export default function SimpleModal({ src }) {
  console.log({ src });
  const classes = useStyles();
  const [modalStyle] = React.useState(getModalStyle);
  const [open, setOpen] = React.useState(false);
  const [imageSrc, setImageSrc] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const handleOpen = async () => {
    setLoading(true);
    const res = await playVideo(src);
    if (res.status === 200) {
      setImageSrc(res?.data);
      setOpen(true);
      setLoading(false);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };
  if (loading) return <div>Loading Image...</div>;
  const body = (
    <div style={modalStyle} className={classes.paper}>
      {loading && (
        <section style={{ width: "100%", height: "100%" }}>
          <h3>Loading Image</h3>
        </section>
      )}
      <section style={{ width: "100%", height: "100%" }}>
        {imageSrc && (
          <img
            style={{ width: "100%", height: "100%" }}
            src={`${URL.createObjectURL(imageSrc)}`}
            alt=""
          />
        )}
        <button onClick={handleClose} className="pop-up-close-btn">
          X
        </button>
      </section>
    </div>
  );

  const pdfBody = (
    <div style={modalStyle} className={classes.paper}>
      <section style={{ width: "100%", height: "100%", overflow: "auto" }}>
        {src && (
          <PDFViewer
            document={{
              url: `${process.env.REACT_APP_DEV}/api/admin/video?videoPath=${src}`,
            }}
            hideZoom
          />
        )}
      </section>
    </div>
  );
  // if (src.slice(src.length - 3) === "pdf") {
  //   return (
  //     <div style={{ display: "inline" }}>
  //       <AiFillEye
  //         style={{
  //           fontSize: "2rem",
  //           cursor: "pointer",
  //         }}
  //         onClick={handleOpen}
  //       />
  //       <Modal
  //         open={open}
  //         onClose={handleClose}
  //         aria-labelledby="simple-modal-title"
  //         aria-describedby="simple-modal-description"
  //       >
  //         {pdfBody}
  //       </Modal>
  //     </div>
  //   );
  // }
  return (
    <div style={{ display: "inline" }}>
      <AiFillEye
        style={{
          fontSize: "2rem",
          cursor: "pointer",
        }}
        onClick={handleOpen}
      />
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {body}
      </Modal>
    </div>
  );
}
