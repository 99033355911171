import axios from "axios";
import { getEmployeeTestimonial } from "../constants/constants";
const { LOADING, EMPLOYEE_TESTIMONIAL_SUCCESS, EMPLOYEE_TESTIMONIAL_FAIL } =
  getEmployeeTestimonial;

let url = `${process.env.REACT_APP_DEV}/api/admin/getEmployeeTestimonialList`;

const getTestimonialRequest = () => {
  return {
    type: LOADING,
  };
};

const getTestimonialSuccess = (list) => {
  return {
    type: EMPLOYEE_TESTIMONIAL_SUCCESS,
    payload: list,
  };
};
const getTestimonialFail = (error) => {
  return {
    type: EMPLOYEE_TESTIMONIAL_FAIL,
    payload: error,
  };
};

export function getTestimonialAction() {
  return (dispatch) => {
    dispatch(getTestimonialRequest());
    try {
      axios
        .get(url)
        .then((res) => dispatch(getTestimonialSuccess(res.data)))
        .catch((err) => dispatch(getTestimonialFail(err)));
    } catch (error) {
      dispatch(getTestimonialFail(error));
    }
  };
}
