import axios from "axios";
import { assetList } from "../constants/constants";
const { LOADING, ASSET_LIST_SUCCESS, ASSET_LIST_FAIL } = assetList;


let url = `${process.env.REACT_APP_DEV}/api/getManagementTaskList`;
export const getAssetList = () => {
  return {
    type: LOADING,
  };
};

export const getAssetListSuccess = (list) => {
  return {
    type: ASSET_LIST_SUCCESS,
    payload: list,
  };
};

export const getAssetListFail = (error) => {
  return {
    type: ASSET_LIST_FAIL,
    payload: error,
  };
};


export function getAssetListAction(data) {
  return (dispatch) => {
    dispatch(getAssetList());
    try {
      axios
        .post(url,data)
        .then((res) => dispatch(getAssetListSuccess(res.data)))
        .catch((err) => dispatch(getAssetListFail(err)));
    } catch (error) {
      dispatch(getAssetListFail(error));
    }
  };
}