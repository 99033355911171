import React from "react";

import AboutEdit from "../companyAbout/AboutCompanyEdit";
import VisionEdit from "../pages/companyVision/VisionEdit";
import ValuesEdit from "../pages/companyValue/ValuesEdit";

function EditModal({ name, id, companyNameId }) {
  switch (name) {
    case "about":
      return <AboutEdit id={id} />;

    case "vision":
      return <VisionEdit id={id} />;

    case "value":
      return <ValuesEdit id={id} />;
  }
}

export default EditModal;
