import "./App.css";
import React, { Suspense } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import DashboardLayout from "./layout/DashboardLayout";
import AuthLayout from "./layout/AuthLayout";
import Login from "./Components/home/auth/login/Login";
import Dashboard from "./admin/dashboard/Dashboard";
import CompanyAbout from "./admin/companyAbout/CompanyAbout";
import MyCompanyLogo from "./admin/companyLogo/MyCompanyLogo";
import CompanySocialLink from "./admin/companySocial/CompanySocialLink";
import CompanyVision from "./admin/pages/companyVision/CompanyVision";
import Company_Gallery from "./admin/pages/companyGallery/Company_Gallery";
import Company_Tour from "./admin/pages/companyTour/Company_Tour";
import CompanyValue from "./admin/pages/companyValue/CompanyValue";
import Company_Holiday from "./admin/pages/companyHoliday/Company_Holiday";
import Buddies from "./admin/pages/buddies/Buddies";
import Key_spocs from "./admin/pages/keySpocs/Key_spocs";
import Compliance from "./admin/pages/compliance/Compliance";
import AssignProcess from "./admin/pages/assignProcess/AssignProcess";
import EmployeeTestimonials from "./admin/pages/employeeTestimonials/EmployeeTestimonials";
import CompanyLeader from "./admin/pages/companyLeaders/CompanyLeader";
import CreateUser from "./admin/pages/createUser/CreateUser";
import PageNotFound from "./Components/uiComponets/notFound/PageNotFound";
import HotelAddress from "./admin/pages/hotelAddress/HotelAddress";
import Notification from "./admin/notification/Notification";
import HrLayout from "./layout/HrLayout";
import { routes } from "./routes/RouteAll";
import CandidateLayoutRoute from "./layout/CandidateLayout";
import { candidateRoutesArr } from "./routes/CandidateRoutes";
import Loader from "./Components/uiComponets/loader/Loader";
import ResetPassword from "./Components/home/auth/login/ResetPassword"
import CreatePassword from "./Components/home/auth/login/CreatePassword"

//admin layout
const DashboardRoutes = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => (
        <DashboardLayout>
          <Component {...props} />
        </DashboardLayout>
      )}
    />
  );
};

//login route
const LoginLayout = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => (
        <AuthLayout>
          <Component {...props} />
        </AuthLayout>
      )}
    />
  );
};

//hr layout
const HrRoutes = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => (
        <HrLayout>
          <Component {...props} />
        </HrLayout>
      )}
    />
  );
};

// candidate routes
const CandidateRoutes = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => (
        <CandidateLayoutRoute>
          <Component {...props} />
        </CandidateLayoutRoute>
      )}
    />
  );
};

function App() {
  return (
    <div className="wrapper">
      <Router>
        <Suspense fallback={<Loader />}>
          <Switch>
            <LoginLayout exact path="/" component={Login} />
            <LoginLayout
              exact
              path="/reset-password"
              component={ResetPassword}
            />
            <LoginLayout
              exact
              path="/create-password"
              component={CreatePassword}
            />

            {candidateRoutesArr.map((li) => (
              <CandidateRoutes
                exact={li.excat}
                path={li.path}
                component={li.component}
              />
            ))}
            {routes.map((li) => (
              <HrRoutes
                exact={li.excat}
                path={li.path}
                component={li.component}
              />
            ))}

            <DashboardRoutes
              exact
              path="/admin/dashboard"
              component={Dashboard}
            />
            <DashboardRoutes exact path="/admin" component={Dashboard} />
            <DashboardRoutes
              exact
              path="/admin/companyManagement/companyAbout"
              component={CompanyAbout}
            />
            <DashboardRoutes
              exact
              path="/admin/companyManagement"
              component={CompanyAbout}
            />
            <DashboardRoutes
              exact
              path="/admin/companyManagement/companyTour"
              component={Company_Tour}
            />
            <DashboardRoutes
              exact
              path="/admin/companyManagement/companyValue"
              component={CompanyValue}
            />
            <DashboardRoutes
              exact
              path="/admin/companyManagement/companySocial"
              component={CompanySocialLink}
            />
            <DashboardRoutes
              exact
              path="/admin/companyManagement/companyGallery"
              component={Company_Gallery}
            />
            <DashboardRoutes
              exact
              path="/admin/companyManagement/companyVision"
              component={CompanyVision}
            />
            <DashboardRoutes
              exact
              path="/admin/companyManagement/companyLogo"
              component={MyCompanyLogo}
            />
            <DashboardRoutes
              exact
              path="/admin/companyManagement/companyHoliday"
              component={Company_Holiday}
            />
            <DashboardRoutes
              exact
              path="/admin/employeeManagement/companyBuddies"
              component={Buddies}
            />
            <DashboardRoutes
              exact
              path="/admin/employeeManagement/companySpocks"
              component={Key_spocs}
            />
            <DashboardRoutes
              exact
              path="/admin/employeeManagement/employeeTestimonials"
              component={EmployeeTestimonials}
            />
            <DashboardRoutes
              exact
              path="/admin/employeeManagement/compliance"
              component={Compliance}
            />
            <DashboardRoutes
              exact
              path="/admin/employeeManagement"
              component={EmployeeTestimonials}
            />
            <DashboardRoutes
              exact
              path="/admin/roleManagement/assignProcess"
              component={AssignProcess}
            />
            <DashboardRoutes
              exact
              path="/admin/roleManagement"
              component={CreateUser}
            />
            <DashboardRoutes
              exact
              path="/admin/companyManagement/companyLeaders"
              component={CompanyLeader}
            />
            <DashboardRoutes
              exact
              path="/admin/roleManagement/assignProcess"
              component={AssignProcess}
            />
            <DashboardRoutes
              exact
              path="/admin/roleManagement/createUser"
              component={CreateUser}
            />
            <DashboardRoutes
              exact
              path="/admin/companyManagement/hotelAddress"
              component={HotelAddress}
            />
            <DashboardRoutes
              exact
              path="/admin/companyManagement/companyNotification"
              component={Notification}
            />
            <Route component={PageNotFound} />
          </Switch>
        </Suspense>
      </Router>
    </div>
  );
}

export default App;
