import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { useSelector } from "react-redux";
import axios from "axios";
import { useDispatch } from "react-redux";
import { getBuddiesAction } from "../../../redux/actions/getBuddiesList";
import Snackbar from "../../../Components/uiComponets/snackbar/Snackbar";
import Validation from "../../../Components/Validations";
import { Loader } from "../../../loader/Loader";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],

    width: "40rem",
  },
  card: {
    marginBottom: "0",
    width: "100%",
  },
}));

export default function TransitionsModal() {
  const dispatch = useDispatch();
  const { companyList } = useSelector((state) => state.getCompanyListReducer);
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  
  const [loader, setLoader] = useState(false);
  const [notification, setNotification] = React.useState({
    success: null,
    fail: null,
  });

  const [addKey, setAddKey] = React.useState({
    companyId: "",
    photo: "",
    name: "",
    designation: "",
    department: "",
    region: "",
    email: "",
    phone: "",
    detail: "",
  });

  const [errors, setErrors] = useState({});

  const [values, setValues] = useState({
    name: "",
    designation: "",
    region: "",
  });
  const [phone, setPhone] = useState({
    phone: "",
  });

  const handleChange = (e) => {
    if (e.target.type === "file") {
      setAddKey({
        ...addKey,
        [e.target.name]: e.target.files[0],
      });
    } else {
      setAddKey({
        ...addKey,
        [e.target.name]: e.target.value,
      });
    }
    setValues({
      ...values,
      [e.target.name]: e.target.value.replace(/[^ ^A-Za-z]/gi, ""),
    });
    setPhone({
      ...phone,
      [e.target.name]: e.target.value.replace(/[^0-9]/gi, ""),
    });
  };
  const handleSubmit = (e) => {
    setLoader(true);
    e.preventDefault();
    setErrors(Validation(values));
    
    const data = new FormData();
    data.append("file", addKey.photo);
    data.append("name", addKey.name);
    data.append("companyId", addKey.companyId);
    data.append("department", addKey.designation);
    data.append("region", addKey.region);
    data.append("email", addKey.email);
    data.append("phone", addKey.phone);
    data.append("detail", addKey.detail);
    axios
    .post(`${process.env.REACT_APP_DEV}/api/addIGTBuddieAlongPhoto`, data)
    .then((res) => {
      setLoader(false);
      dispatch(getBuddiesAction());
      setNotification({ success: true, fail: false });
    })
    .catch((err) => {
        setLoader(false);
        setNotification({ success: false, fail: true });
      });
  };
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  if (loader)
    return (
        <Loader open="true" />
    );
  return (
    <div>
      <span type="button" onClick={handleOpen}>
        ADD BUDDIES
      </span>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <div className={`card ${classes.card}`}>
              <div className="card-header">
                <h3 className="card-title">ADD BUDDIES</h3>
              </div>
              <div className="card-body">
                <h6>
                  <strong>NOTE: </strong>Kindly upload jpg/jpeg file only.
                </h6>
                <form onSubmit={handleSubmit} style={{ width: "100%" }}>
                  <section className="d-flex justify-content-between">
                    <div className="form-group d-flex flex-column">
                      <label>Company</label>
                      <select
                        name="companyId"
                        onChange={handleChange}
                        className="form-control"
                        required
                      >
                        <option disabled selected value="">
                          Select Company
                        </option>
                        {companyList.map((company) => (
                          <option value={company.companyId} key={company}>
                            {company.companyName}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="form-group d-flex flex-column">
                      <label>Photo*</label>
                      <input
                        name="photo"
                        onChange={handleChange}
                        type="file"
                        className="form-control"
                        required
                        accept=".jpg, .jpeg, .png"
                      />
                    </div>
                  </section>
                  <section className="d-flex justify-content-between">
                    <div className="form-group d-flex flex-column">
                      <label>Name*</label>
                      <input
                        type="text"
                        name="name"
                        // pattern="[A-Za-z]*"
                        value={values.name}
                        onChange={handleChange}
                        className="form-control"
                        required
                      />
                    </div>
                    <div className="form-group d-flex flex-column">
                      <label>Designation*</label>
                      <input
                        name="designation"
                        onChange={handleChange}
                        className="form-control"
                        required
                        value={values.designation}
                      />
                    </div>
                  </section>
                  <section className="d-flex justify-content-between">
                    <div className="form-group d-flex flex-column">
                      <label>Department</label>
                      <select
                        name="department"
                        onChange={handleChange}
                        className="form-control"
                      >
                        <option disabled selected value="">
                          Select Department
                        </option>
                        <option>HOD</option>
                        <option>Superviser</option>
                      </select>
                    </div>
                    <div className="form-group d-flex flex-column">
                      <label>Region*</label>
                      <input
                        name="region"
                        onChange={handleChange}
                        className="form-control"
                        required
                        value={values.region}
                      />
                    </div>
                  </section>

                  <section className="d-flex justify-content-between">
                    <div className="form-group d-flex flex-column">
                      <label>Email*</label>
                      <input
                        name="email"
                        type="email"
                        onChange={handleChange}
                        className="form-control"
                        required
                      />
                    </div>
                    <div className="form-group d-flex flex-column">
                      <label for="phone">Phone</label>
                      <input
                        type="text"
                        id="phone"
                        name="phone"
                        // pattern="[0-9]*"
                        onChange={handleChange}
                        className="form-control"
                        value={phone.phone}
                        maxlength="10"
                        // minLength='10'
                      />
                    </div>
                  </section>
                  <section>
                    <div className="form-group d-flex flex-column">
                      <label>Details*</label>
                      <textarea
                        name="detail"
                        onChange={handleChange}
                        className="form-control"
                        required
                      />
                    </div>
                  </section>
                  <section className="text-right d-flex">
                    <button
                      type="submit"
                      className="btn btn-sm btn-primary text-white"
                    >
                      Submit
                    </button>
                    <button
                      onClick={handleClose}
                      type="submit"
                      className="btn btn-sm btn-primary ml-1 text-white"
                    >
                      close
                    </button>
                  </section>
                  {notification.success && (
                    <Snackbar msg="HR created successfully" type="success" />
                  )}
                  {notification.fail && (
                    <Snackbar msg="Something went wrong" type="error" />
                  )}
                </form>
              </div>
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}
