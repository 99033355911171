import { companyNotification } from "../constants/constants";

const { LOADING, COMPANY_NOTIFI_SUCCESS,COMPANY_NOTIFI_FAIL } = companyNotification;

export const companyNotificationRequest = () => {
  return {
    type: LOADING,
  };
};

export const companyNotificationSuccess = (notifi) => {
  return {
    type: COMPANY_NOTIFI_SUCCESS,
    payload: notifi,
  };
};

export const companyNotificationFail = (error) => {
  return {
    type: COMPANY_NOTIFI_FAIL,
    payload: error,
  }; 
};