import { Company_Leader } from "../constants/constants";
import axios from "axios";

const { LOADING, LEADER_SUCCESS, LEADER_FAIL } = Company_Leader;


export const Leader_Request = () => {
  return {
    type: LOADING,
  };
};

export const  Leader_Success = (users) => {
  return {
    type: LEADER_SUCCESS,
    payload: users,
  };
};

export const  Leader_Fail = (error) => {
  return {
    type: LEADER_FAIL,
    payload: error,
  };
};
