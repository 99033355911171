export const columns = [
  {
    Header: "S.No.",
    accessor: "idx",
  },
  {
    Header: "Name",
    accessor: "name",
  },
  {
    Header: "Type",
    accessor: "type",
  },
  {
    Header: "Image",
    accessor: "image",
  },
  {
    Headers: "Action",
    accessor: "delete",
  },
];
