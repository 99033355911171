import React from "react";

function Skeleton({ children }) {
  return (
    <section className="content">
      <div className="content-wrapper">
        <div className="container-fluid">{children}</div>
      </div>
    </section>
  );
}

export default Skeleton;
