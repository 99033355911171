import { holidayCalender} from "../constants/constants";
const { LOADING,HOLIDAY_CALENDER_SUCCESS, HOLIDAY_CALENDER_FAIL } = holidayCalender;


const intialState = {
  loading: null,
 holiday:null,
  error: "",
};

export const companyHolidayReducer = (state = intialState, action) => {
  switch (action.type) {
    case LOADING:
      return {
        ...state,
        loading: true,
      };
    case HOLIDAY_CALENDER_SUCCESS:
      return {
        ...state,
        loading: false,
        holiday: action.payload,
      };
    case HOLIDAY_CALENDER_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};
