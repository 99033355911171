import React from "react";
import Model from "../adminUi/Model";
import VideoModel from "../adminUi/VideoModel";
import EditModal from "../adminUi/EditModal";

function AudioVideoTable({
  labels,
  data,
  imgSrc,
  videoSrc,
  name,
  id,
  companyNameId,
}) {
  return (
    <div class="table-responsive">
      <table class="table">
        <thead>
          <tr>
            {labels.length > 0 &&
              labels.map((thead) => (
                <th key={thead} className="table-heading-top" scope="row">
                  {thead}
                </th>
              ))}
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <Model src={imgSrc} />
            </td>
            <td
              style={{ display: "flex", marginLeft: "3rem" }}
              className="text-center"
            >
              <VideoModel src={videoSrc} />
            </td>
            <td>
              <EditModal name={name} id={id} />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default AudioVideoTable;
