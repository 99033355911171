import axios from "axios";
import { getAssignProcess } from "../constants/constants";
const { LOADING, GET_ASSIGN_LIST_SUCCESS, GET_ASSIGN_LIST_FAIL } =
  getAssignProcess;

let url = `${process.env.REACT_APP_DEV}/api/getCompanyWiseHrRoleList`;

const getAssignProcessRequest = () => {
  return {
    type: LOADING,
  };
};

const getAssignProcessSuccess = (list) => {
  return {
    type: GET_ASSIGN_LIST_SUCCESS,
    payload: list,
  };
};
const getAssignProcessFail = (error) => {
  return {
    type: GET_ASSIGN_LIST_FAIL,
    payload: error,
  };
};

export function getAssignProceessAction() {
  return  (dispatch) => {
    dispatch(getAssignProcessRequest());
    try {
     axios
        .get(url)
        .then((res) => dispatch(getAssignProcessSuccess(res.data)))
        .catch((err) => dispatch(getAssignProcessFail(err)));
    } catch (error) {
      dispatch(getAssignProcessFail(error));
    }
  };
}
