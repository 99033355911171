export const getCandidateStorageData = () => {
  const { CompanyId, hrCandidateId, applicantId } = JSON.parse(
    sessionStorage.getItem("candidate")
  );
  return {
    CompanyId,
    hrCandidateId,
    applicantId,
  };
};
