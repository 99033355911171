import React, { useEffect, useState } from "react";
import {
  EditorState,
  convertToRaw,
  convertFromHTML,
  ContentState,
  htmlToDraft,
} from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import Snackbar from "../../../Components/uiComponets/snackbar/Snackbar";
import { getBuddiesAction } from "../../../redux/actions/getBuddiesList";
import { MdModeEdit } from "react-icons/md";
import draftToHtml from "draftjs-to-html";
import { editCompanyValue } from "../../adminMainServices/companyServices/companyServices";
import { getCompanyDetailsAction } from "../../../redux/actions/getCompanyDetails";
import { Loader } from "../../../loader/Loader";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    width: "40rem",
  },
  card: {
    marginBottom: "0",
    width: "100%",
    overflowY: "scroll",
    height: "20rem",
  },
}));

export default function TransitionsModal({ id }) {
  const dispatch = useDispatch();
  const { companyList } = useSelector((state) => state.getCompanyListReducer);
  const [companyDetails, setCompanyDetails] = useState(null);
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  
  const [loader, setLoader] = useState(false);
  const [notification, setNotification] = useState({
    success: null,
    fail: null,
  });

  const [editorLengthValidation, setEditorLengthValidation] = useState({
    mission: null,
    aboutUsOneLength: null,
  });

  useEffect(() => {
    setTimeout(() => {
      setNotification({ success: false, fail: false });
    }, 4000);
  }, [notification]);

  const { loading, error, details } = useSelector(
    (state) => state.getCompanyDetailsListReducer
  );

  const [content, setContent] = React.useState({
    editorStateOne: EditorState.createEmpty(),
  });

  const [editorValue, setEditorValue] = React.useState({
    editorOneValue: null,
    editorTwoValue: null,
  });

  const htmlDocs = (html, name) => {
    const blocksFromHTML = convertFromHTML(html);
    const state = ContentState.createFromBlockArray(
      blocksFromHTML.contentBlocks,
      blocksFromHTML.entityMap
    );

    return state;
  };

  useEffect(() => {
    if (details.length > 0) {
      let temp = details.find((li) => li.id === id);
      setCompanyDetails(temp);
      let data1 = temp?.mission
        ? htmlDocs(temp?.mission, "editorStateOne")
        : htmlDocs("<p>No data</p>");

      setContent({
        editorStateOne: EditorState?.createWithContent(data1),
      });
    }
  }, [id]);

  const editorTextOne = convertToRaw(content.editorStateOne.getCurrentContent())
    .blocks.map((rowText) => {
      return rowText.text;
    })
    .join("");

  const handleClose = (e) => {
    e.preventDefault();
    setOpen(false);
  };

  const handleSubmit = async (e) => {
    setLoader(true);
    e.preventDefault();
    
    if (editorLengthValidation.aboutUsOneLength) {
      return;
    }
    let editValue = {
      id: id,
      mission: editorValue.editorOneValue,
    };
    const res = await editCompanyValue(editValue);
    if (res.status === 200) {
      setLoader(false);
      setNotification({ fail: false, success: true });
      window.location.reload();
    } else {
      setLoader(false);
      setNotification({ fail: true, success: false });
    }
  };
  
  useEffect(() => {
    setEditorValue({
      ...editorValue,
      editorOneValue: draftToHtml(
        convertToRaw(content?.editorStateOne?.getCurrentContent())
      ),
    });
  }, [content.editorStateOne]);
  const onEditorStateChangeOne = (editorState) => {
    setContent({
      ...content,
      editorStateOne: editorState,
    });
  };

  const handleOpen = () => {
    setOpen(true);
  };

  if (loader)
    return (
        <Loader open="true" />
    );
  return (
    <div>
      <MdModeEdit size={20} onClick={handleOpen} />
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <div className={`card ${classes.card}`}>
              <div className="card-header">
                <h3 className="card-title">Edit Value</h3>
              </div>
              <div className="card-body">
                <section style={{ width: "100%" }}>
                  {editorLengthValidation.aboutUsOneLength && (
                    <p style={{ color: "red" }}>Please Enter 500 words only</p>
                  )}
                  {/* {editorLengthValidation.aboutUs && (
                    <p style={{ color: "red" }}>This is editor is mandatory</p>
                  )} */}
                  <form onSubmit={handleSubmit} style={{ width: "100%" }}>
                    <section className="d-flex justify-content-between">
                      <div className="form-group d-flex flex-column">
                        <div className="card-header">
                          <h3 className="card-title bold-card-header ">
                            Values
                          </h3>
                        </div>
                        <Editor
                          editorState={content.editorStateOne}
                          toolbarClassName="toolbarClassName"
                          wrapperClassName="wrapperClassName"
                          editorClassName="editorClassName"
                          onEditorStateChange={onEditorStateChangeOne}
                        />
                      </div>
                    </section>

                    <section className="text-right d-flex ">
                      <button
                        type="submit"
                        className="btn btn-sm btn-primary text-white"
                        onClick={handleSubmit}
                        style={{backgroundColor: "#03356D"}}
                        >
                        Save
                      </button>
                      <button
                        onClick={handleClose}
                        type="submit"
                        className="btn btn-sm btn-primary ml-1 text-white"
                        style={{backgroundColor: "#03356D"}}
                      >
                        close
                      </button>
                    </section>
                  </form>
                </section>
                {notification.success && (
                  <Snackbar msg="Value updated successfully" type="success" />
                )}
                {notification.fail && (
                  <Snackbar msg="Something went wrong" type="error" />
                )}
              </div>
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}
