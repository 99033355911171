import "./Footer.css";

const Footer = () => {
  return (
    <div>
      <footer className="footer">
        <div className="row footer-content">
          <div className="col-sm footer-left-para">
            Powered by <b>MyJoining</b>
          </div>
          <div className="col-sm">
            <a className="footer-anchor" href="#">
              Help center
            </a>
            <span className="anchor-dot"></span>
            <a className="footer-anchor" href="#">
              Terms of use
            </a>
            <span className="anchor-dot"></span>
            <a className="footer-anchor" href="#">
              Privacy Policy
            </a>
          </div>
          <div className="col-sm footer-right-para">
            © 2022 Orane Consuting Pvt Ltd, India. All rights reserved
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
