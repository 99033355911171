import { navbarHeading } from "../constants/constants";
import { snackbarHeading } from "../constants/constants";

const { UPDATE_HEADING } = navbarHeading;
const { SHOW_NOTIFICATION_SNACKBAR } = snackbarHeading;
const initialState = {
  navbarHeading: "About Company",
  stepper: ["personal-details"]
};
const initialSnackbar = {
  error: "",
  severity: "",
  open: null,
  successSnackbarOpen: false,
  successSnackbarMessage: "",
  errorSnackbarOpen: false,
  infoSnackbarOpen: false,
  saverity: null,

};

export const updateNavbarHeadingReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_HEADING:
      return {
        ...state,
        navbarHeading: action.payload,
      };
    case "SNACKBAR_SUCCESS":
      return {
        ...state,
        successSnackbarOpen: true,
        successSnackbarMessage: action.message,
        saverity: action.saverity,
      };
    case "SNACKBAR_ERROR":
      return {
        ...state,
        successSnackbarOpen: true,
        successSnackbarMessage: action.message,
        saverity: action.saverity,
      };
    case "SNACKBAR_CLEAR":
      return {
        ...state,
        successSnackbarOpen: false,
        errorSnackbarOpen: false,
        infoSnackbarOpen: false,
      };
    case "UPDATE_STEPPER":
      return {
        ...state,
        stepper: [...state?.stepper, action.payload]
      };
    case "BACK_STEPPER":
      return {
        ...state,
        stepper: state?.stepper?.filter(li => li !== action.payload)
      };
    default:
      return state;
  }
};

export const snackbarReducer = (state = initialSnackbar, action) => {
  switch (action.type) {
    case SHOW_NOTIFICATION_SNACKBAR:
      return {
        ...state,
        error: action?.payload?.message ?? null,
        severity: action?.payload?.saverity ?? "",
        open: action?.payload?.open,
      };
    default:
      return state;
  }
};
// console.log(initialSnackbar);
