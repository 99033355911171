import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateNavbarHeading } from "../../../redux/actions/uiActions";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Snackbar from "../../../Components/uiComponets/snackbar/Snackbar";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { getLeaderAction } from "../../../redux/actions/getleader";
import axios from "axios";
import {
  Leader_Request,
  Leader_Success,
  Leader_Fail,
} from "../../../redux/actions/companyleader";
import { MdModeEdit } from "react-icons/md";
import { Loader } from "../../../loader/Loader";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    width: "40rem",
  },
  card: {
    marginBottom: "0",
    width: "100%",
  },
}));
const leaderObj = {
  country: " ",
  location: " ",
  itLeader: " ",
  jobTitle: " ",
  description: " ",
  description2: " ",
};

const LeaderEditModal = ({ id }) => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state.getLeaderReducer);
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [newLeader, setNewLeader] = useState(leaderObj);
  
  const [loader, setLoader] = useState(false);

  const [notification, setNotification] = useState({
    success: null,
    fail: null,
  });


  useEffect(() => {
    setNotification({ fail: false, success: false });
    dispatch(updateNavbarHeading("Company Leaders"));

  }, []);

  useEffect(() => {
    if (state.leaderList.length > 0) {
      let temp = state.leaderList.find((li) => li.id === id);
      setNewLeader({
        country: temp.country,
        location: temp.location,
        itLeader: temp.itLeader,
        jobTitle: temp.jobTitle,
        description: temp.description,
        description2: temp.description2,
      });
    }
  }, [id]);

  const handleChange = (e) => {
    if (e.target.type === "file") {
      setNewLeader({
        ...newLeader,
        [e.target.name]: e.target.files[0],
      });
    } else {
      setNewLeader({
        ...newLeader,
        [e.target.name]: e.target.value,
      });
    }
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    let editLeader = {
      id: id,
      country: newLeader.country,
      location: newLeader.location,
      itLeader: newLeader.itLeader,
      jobTitle: newLeader.jobTitle,
      description: newLeader.description,
      description2: newLeader.description2,
    };
    const editValue = { editLeader };
    dispatch(Leader_Request());
    
    let url = `${process.env.REACT_APP_DEV}/api/admin/editLeaderInfo`;
    setLoader(true);
    axios
    .post(url, editValue)
    .then((res) => {
        setLoader(false);
        dispatch(Leader_Success(res.editValue));
        dispatch(getLeaderAction());
        handleClose();
        setNotification({ fail: false, success: true });
      })
      .catch((err) => {
        setLoader(false);
        dispatch(Leader_Fail(err));
        setNotification({ fail: true, success: false });
      });
  };

  if (loader)
    return (
        <Loader open="true" />
    );
  return (
    <>
      <span type="button" onClick={handleOpen}>
        <MdModeEdit size={20} />
      </span>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <div className={`card ${classes.card}`}>
              <div className="card-header">
                <h3 className="card-title">EDIT LEADER</h3>
              </div>
              <div className="card-body">
                <form onSubmit={handleSubmit} style={{ width: "100%" }}>
                  <section className="d-flex justify-content-between">
                    <div className="form-group d-flex flex-column">
                      <label>Bussiness Leader</label>
                      <input
                        name="itLeader"
                        onChange={handleChange}
                        className="form-control"
                        value={newLeader.itLeader}
                        required
                      />
                    </div>
                    <div className="form-group d-flex flex-column">
                      <label>Designation</label>
                      <input
                        name="jobTitle"
                        onChange={handleChange}
                        className="form-control"
                        value={newLeader.jobTitle}
                        required
                      />
                    </div>
                  </section>
                  <section className="d-flex justify-content-between">
                    <div className="form-group d-flex flex-column">
                      <label>location</label>
                      <input
                        name="location"
                        onChange={handleChange}
                        className="form-control"
                        value={newLeader.location}
                        required
                      />
                    </div>
                    <div className="form-group d-flex flex-column">
                      <label>Region</label>
                      <input
                        name="country"
                        onChange={handleChange}
                        className="form-control"
                        value={newLeader.country}
                        required
                      />
                    </div>
                  </section>

                  <section className="d-flex justify-content-between">
                    <div className="form-group d-flex flex-column">
                      <label>Description 1</label>
                      <textarea
                        name="description"
                        onChange={handleChange}
                        className="form-control"
                        value={newLeader.description}
                        required
                      />
                    </div>
                    <div className="form-group d-flex flex-column">
                      <label>Description 2</label>
                      <textarea
                        name="description2"
                        onChange={handleChange}
                        className="form-control"
                        value={newLeader.description2}
                        required
                      />
                    </div>
                  </section>
                  <section className="text-right d-flex ">
                    <button
                      type="submit"
                      className="btn btn-sm btn-primary text-white"
                    >
                      Submit
                    </button>
                    <button
                    type="button"
                      onClick={handleClose}
                      className="btn btn-sm btn-primary ml-1 text-white"
                    >
                      close
                    </button>
                  </section>
                </form>
                {notification.success && (
                  <Snackbar
                    msg="Company Leader addedd successfully"
                    type="success"
                  />
                )}
                {notification.fail && (
                  <Snackbar msg="Something went wrong" type="error" />
                )}
              </div>
            </div>
          </div>
        </Fade>
      </Modal>
    </>
  );
};
export default LeaderEditModal;
