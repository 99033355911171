import { companyList } from "../constants/constants";
const { LOADING, COMPANY_LIST_SUCCESS, COMPANY_LIST_FAIL } = companyList;

export const getCompanyList = () => {
  return {
    type: LOADING,
  };
};

export const getCompanyListSuccess = (list) => {
  return {
    type: COMPANY_LIST_SUCCESS,
    payload: list,
  };
};

export const getCompanyListFail = (error) => {
  return {
    type: COMPANY_LIST_FAIL,
    payload: error,
  };
};
