// components/SuccessSnackbar.js or whatever you wanna call it
import { useDispatch, useSelector } from "react-redux";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import { Icon } from "@material-ui/core";
import { clearSnackbar } from "../../../redux/actions/uiActions";
import MuiAlert from "@material-ui/lab/Alert";

export default function SuccessSnackbar() {
  const dispatch = useDispatch();

  const { successSnackbarMessage, successSnackbarOpen, saverity } = useSelector(
    (state) => state.updateNavbarHeadingReducer
  );

  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }
  function handleClose() {
    dispatch(clearSnackbar());
  }

  return (
    // <Snackbar
    //   anchorOrigin={{
    //     vertical: "bottom",
    //     horizontal: "left"
    //   }}
    //   open={successSnackbarOpen}
    //   autoHideDuration={4000}
    //   onClose={handleClose}
    //   aria-describedby="client-snackbar"
    //   message={
    //     <span id="client-snackbar">
    //       {/* <Icon>check_circle</Icon> */}
    //       {successSnackbarMessage}
    //     </span>
    //   }
    //   action={[
    //     <IconButton
    //       key="close"
    //       aria-label="close"
    //       color="inherit"
    //       onClick={handleClose}
    //     >
    //       <Icon>close</Icon>
    //     </IconButton>
    //   ]}
    // />
    <Snackbar
      open={successSnackbarOpen}
      autoHideDuration={5000}
      onClose={handleClose}
    >
      <Alert onClose={handleClose} severity={saverity}>
        {successSnackbarMessage}
      </Alert>
    </Snackbar>
  );
}
