import React, { useState } from "react";
import { editAssignProcess } from "../../adminMainServices/otherServices/assignProcess";
import Snackbar from "../../../Components/uiComponets/snackbar/Snackbar";
import { getAssignProceessAction } from "../../../redux/actions/getAssignProcess";
import { useDispatch } from "react-redux";
import { Loader } from "../../../loader/Loader";

function AssignProcessCard({ item }) {
  const dispatch = useDispatch();
  
  const [loader, setLoader] = useState(false);
  const [assignProcess, setAssignProcess] = React.useState({
    adminId: false,
    campusPreOfferCandidate: false,
    campusOfferCandidate: false,
    accommodation: false,
    uploadCampusCandidateData: false,
    offeredCandidate: false,
    taskStatus: false,
    docVerification: false,
    formVerificaion: false,
    preOfferCandidate: false,
    verifyDetail: false,
    hireCandidate: false,
    readyToHire: false,
    fieldWise: false,
    locationWise: false,
    bandWise: false,
    companyWise: false,
    candidateSearch: false,
    generateReport: false,
    uploadTaleoData: false,
    onboardingPlan: false,
    onboardingBenifit: false,
    onboardingFaq: false,
    onboardingSpoc: false,
    onboardingBirthday: false,
    assetManagement: false,
    cancilHiring: false,
    candidateQueries: false,
    bgv: false,
    onboarding: false,
    massNotification: false,
    analyticsReports: false,
  });
  const [notification, setNotification] = React.useState({
    success: null,
    fail: null,
  });
  const onChange = (e) => {
    setAssignProcess({
      ...assignProcess,
      [e.target.name]: e.target.checked,
    });
  };
  let newArr = [];
  let val = Object.values(item);
  let key = Object.keys(item);
  for (let i = 0; i < val.length; i++) {
    let obj = {};
    obj.label = key[i];
    obj.val = val[i];
    newArr.push(obj);
  }
  React.useEffect(() => {
    setNotification({ success: false, fail: false });
    if (newArr.length > 0) {
      let temp = {};
      newArr?.forEach((li) => {
        temp["adminId"] = item?.adminId;
        if (typeof li.val === "boolean") {
          temp[li?.label] = li?.val;
        }
      });
      setAssignProcess(temp);
    }
  }, []);
  const handleEdit = async (e) => {
    setLoader(true);
    const res = await editAssignProcess(assignProcess);
    if (res.status === 200) {
      setLoader(false);
      dispatch(getAssignProceessAction());
      setNotification({ success: true, fail: false });
    } else {
      setLoader(false);
      setNotification({ success: false, fail: true });
    }
  };
  React.useEffect(() => {
    if (notification.success) {
      setTimeout(() => {
        dispatch(getAssignProceessAction());
      }, 3000);
    }
  }, [notification]);
  const generateLabel = (item) => {
    let name = "";
    let arr = item.split("").map((c, i) => {
      if (c >= "A" && c <= "Z") {
        name += " ";
      }
      name += c;
    });
    return name[0].toUpperCase() + name.substring(1)
  };

  if (loader)
    return (
        <Loader open="true" />
    );
  return (
    <div className="card mt-2">
      <div className="card-header">
        <strong className="mr-5">User Id:</strong> {item?.adminId}
      </div>
      <div className="card-body">
        {newArr.length > 0 &&
          newArr.map((li, idx) => {
            if (typeof li?.val === "boolean") {
              return (
                <div className="d-flex justify-content-around align-items-center">
                  <label>{generateLabel(li?.label)}</label>
                  <input
                    name={li?.label}
                    onChange={onChange}
                    type="checkbox"
                    className="ml-5"
                    checked={assignProcess[li.label]}
                    acc
                    style={{
                      accentColor: "#03356D",
                    }}
                  />
                  <hr />
                </div>
              );
            }
          })}
      </div>
      <div className="card-footer">
        {newArr.length > 0 && (
          <div className="form-group d-flex justify-content-center align-items-center">
            <label>
              {" "}
              <strong>
                {newArr?.find((li) => li?.label === "entity")?.label}
              </strong>
            </label>
            <select className="form-control ml-4" required>
              {newArr?.find((li) => li?.label === "entity")?.val?.split(",").map((a) => (
                <option disabled selected value="" key={a}>{a}</option>
              ))}
            </select>
          </div>
        )}
        <div>
          <button onClick={handleEdit} className="btn btn-sm btn-primary" style={{backgroundColor: "#03356D"}}>
            Save
          </button>
          {notification.success && (
            <Snackbar msg="HR role updated successfully" type="success" />
          )}
          {notification.fail && (
            <Snackbar msg="Something went wrong" type="error" />
          )}
        </div>
      </div>
    </div>
  );
}

export default AssignProcessCard;
