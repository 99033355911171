import React,{useState} from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import axios from "axios";
import Snackbar from "../../../../Components/uiComponets/snackbar/Snackbar";
import { useSelector, useDispatch } from "react-redux";
import { getAssetListAction } from "../../../../redux/actions/getAssetList";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    width: "40rem",
  },
  card: {
    marginBottom: "0",
    width: "100%",
  },
}));

export default function TransitionsModal({ companyId }) {
  const dispatch = useDispatch();
  // const { assetList } = useSelector((state) => state.getAssetListReducer);

  const { companyList } = useSelector((state) => state.getCompanyListReducer);
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [notification, setNotification] = React.useState({
    success: null,
    fail: null,
  });
  const [addKey, setAddKey] = React.useState({
    contactEmail: "",
    contactPerson: "",
    requirmentType: "",
    requirmentName: "",
  });

  const [values, setValues] = useState({
    contactPerson: "",
    requirmentType: "",
    requirmentName: "",

  });

  const handleChange = (e) => {
    if (e.target.type === "file") {
      setAddKey({
        ...addKey,
        [e.target.name]: e.target.files[0],
      });
    } else {
      setAddKey({
        ...addKey,
        [e.target.name]: e.target.value,
      });
    }
    setValues({
      ...values,
      [e.target.name]: e.target.value.replace(/[^ ^A-Za-z]/ig, ''),

    });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    let data = {
      companyId,
      contactEmail: addKey.contactEmail,
      contactPerson: addKey.contactPerson,
      requirmentType: addKey.requirmentType,
      requirmentName: addKey.requirmentName,
    };
    axios
      .post(`${process.env.REACT_APP_DEV}/api/addHrManagementTask`, data)
      .then((res) => {
        dispatch(getAssetListAction(companyId));
        setNotification({ success: true, fail: false });
        handleClose();
      })
      .catch((err) => {
        setNotification({ success: false, fail: true });
      });
  };
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <span type="button" onClick={handleOpen}>
        ADD ASSET
      </span>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <div className={`card ${classes.card}`}>
              <div className="card-header">
                <h3 className="card-title">ADD ASSET</h3>
              </div>
              <div className="card-body">
                <form onSubmit={handleSubmit} style={{ width: "100%" }}>
                  <section>
                    <div className="form-group d-flex flex-column">
                      <label>Contact Person Name</label>
                      <input
                        name="contactPerson"
                        onChange={handleChange}
                        className="form-control"
                        // value={values.contactPerson}
                        required
                      />
                    </div>
                  </section>
                  <section>
                    <div className="form-group d-flex flex-column">
                      <label>Requirement Type</label>
                      <input
                        name="requirmentType"
                        onChange={handleChange}
                        className="form-control"
                        required
                      />
                    </div>
                  </section>
                  <section>
                    <div className="form-group d-flex flex-column">
                      <label>Requirement Name</label>
                      <input
                        name="requirmentName"
                        onChange={handleChange}
                        // value={values.requirmentName}
                        className="form-control"
                        required
                      />
                    </div>
                  </section>

                  <section>
                    <div className="form-group d-flex flex-column">
                      <label>Contact Person Email</label>
                      <input
                        name="contactEmail"
                        onChange={handleChange}
                        className="form-control"
                        required
                      />
                    </div>
                  </section>

                  <section className="text-right d-flex ">
                    <button
                      type="submit"
                      className="btn btn-sm btn-primary text-white"
                    >
                      Submit
                    </button>
                    <button
                      type="submit"
                      className="btn btn-sm btn-primary ml-1 text-white"
                      onClick={handleClose}
                    >
                      close
                    </button>
                  </section>
                </form>
                {notification.success && (
                  <Snackbar msg="Asset addedd successfully" type="success" />
                )}
                {notification.fail && (
                  <Snackbar msg="Something went wrong" type="error" />
                )}
              </div>
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}
