import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./Notification.css";
import {
  updateNavbarHeading,
  showErrorSnackbar,
} from "../../redux/actions/uiActions";
import axios from "axios";
import {
  companyNotificationRequest,
  companyNotificationSuccess,
  companyNotificationFail,
} from "../../redux/actions/companyNotification";
import Snackbar from "../../Components/uiComponets/snackbar/Snackbar";
import { Loader } from "../../loader/Loader";
const initialObj = {
  id: "",
  last3DaysReminder: "",
  last3DaysNotification: "",
  birthdayNotification: "",
  otherFunctionalityReminder: "",
  taskReminder: "",
  loginReminder: "",
};

function Notification() {
  const dispatch = useDispatch();
  const { companyList } = useSelector((state) => state.getCompanyListReducer);
  const [newNotifi, setNewNotifi] = useState(initialObj);
  
  const [loader, setLoader] = useState(false);

  const [notification, setNotification] = useState({
    checkboxSuccess: null,
    checkboxFail: null,
    fileSuccess: null,
    fileFail: null,
  });
  const [noti, setNoti] = React.useState({
    file0: "",
    id1: "",
  });
  const restFileRef = useRef();
  const restInputFile = () => {
    restFileRef.current.value = "";
  };
  useEffect(() => {
    dispatch(updateNavbarHeading("Company Notification"));
  }, []);
  const handleChange = (e) => {
    if (e.target.type === "file") {
      let file = e.target.files[0];
      // const fileType = about?.type === "image" ? 2097152 : 10485760;
      if (file?.size >= 2097152) {
        const input = document.querySelector("#imageInputFile");
        input.value = "";
        return dispatch(
          showErrorSnackbar(
            "File not supported, Please check the size and type of file",
            "error"
          )
        );
      }
      setNoti({
        ...noti,
        [e.target.name]: e.target.files[0],
      });
      setNewNotifi({
        ...newNotifi,
        [e.target.name]: e.target.files[0],
      });
    } else {
      setNewNotifi({
        ...newNotifi,

        [e.target.name]: e.target.value,
      });
      setNoti({
        ...noti,
        [e.target.name]: e.target.value,
      });
    }
  };

  const clearingCheckbox = () => {
    document.querySelector('input[name="loginReminder"]').checked = false;
    document.querySelector('input[name="taskReminder"]').checked = false;
    document.querySelector(
      'input[name="otherFunctionalityReminder"]'
    ).checked = false;
    document.querySelector(
      'input[name="birthdayNotification"]'
    ).checked = false;
    document.querySelector(
      'input[name="last3DaysNotification"]'
    ).checked = false;
    document.querySelector('input[name="last3DaysReminder"]').checked = false;
  };

  const handleSubmit = (e) => {
    setLoader(true);
    e.preventDefault();
    
    dispatch(companyNotificationRequest());
    let url = `${process.env.REACT_APP_DEV}/api/admin/updateNotification`;
    axios
    .post(url, newNotifi)
    .then((res) => {
        setLoader(false);
        dispatch(companyNotificationSuccess(res.newCompanyNotifi));
        setNewNotifi(initialObj);
        clearingCheckbox();
        setNotification({ checkboxFail: false, checkboxSuccess: true });
      })
      
      .catch((error) => {
        setLoader(false);
        dispatch(companyNotificationFail(error));
        setNotification({ checkboxFail: true, checkboxSuccess: false });
      });
  };

  const handleSubmit2 = (e) => {
    setLoader(true);
    e.preventDefault();
    dispatch(companyNotificationRequest());
    restInputFile();
    let data = new FormData();
    data.append("companyId", parseInt(noti.id1));
    data.append("file", noti.file0);
    
    axios
    .post(`${process.env.REACT_APP_DEV}/api/about/addDisclaimer`, data)
    .then((res) => {
        setLoader(false);
        dispatch(companyNotificationSuccess(res.data));
        setNoti({ id1: "", file0: "" });
        setNotification({ fileSuccess: true, fileFail: false });
      })
      .catch((err) => {
        setLoader(false);
        dispatch(companyNotificationFail(err));
        setNotification({ fileSuccess: false, fileFail: true });
      });
  };

  if (loader)
    return (
        <Loader open="true" />
    );
  return (
    <>
      <div className="content">
        <div className="content-wrapper">
          <div className="container-fluid">
            <form onSubmit={handleSubmit}>
              <div className="card">
                <div className="col-md-6 mb-3 p-4">
                  <span className="input-labels">
                    Company <sup className="fa fa-asterisk asterisk"></sup>
                  </span>
                  <div className="">
                    <select
                      class="form-control"
                      aria-label=".form-select-sm example"
                      name="id"
                      onChange={handleChange}
                      required
                      // value={noti.id}
                    >
                      <option value="" selected disabled="selected">
                        Select Company{" "}
                      </option>
                      {companyList.map((company) => (
                        <option value={company.companyId} key={company}>
                          {company.companyName}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="card"></div>
                <div className="card"></div>
                <div>
                  <table class="table">
                    <thead className="table-heading-top">
                      <tr>
                        <th scope="row">S.No.</th>
                        <th scope="row">Types of Reminder</th>
                        <th scope="row">Select</th>
                      </tr>
                    </thead>
                    <tbody className="table-body-texts">
                      <tr>
                        <td>1</td>
                        <td>Login Reminders</td>
                        <td>
                          <input
                            type="checkbox"
                            name="loginReminder"
                            onChange={handleChange}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>2</td>
                        <td>Task Reminders</td>
                        <td>
                          <input
                            type="checkbox"
                            name="taskReminder"
                            onChange={handleChange}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>3</td>
                        <td>Other Reminders</td>
                        <td>
                          <input
                            type="checkbox"
                            name="otherFunctionalityReminder"
                            onChange={handleChange}
                          />
                        </td>
                      </tr>

                      <tr>
                        <td>4</td>
                        <td>Birthday/Anniversary Notification</td>
                        <td>
                          <input
                            type="checkbox"
                            name="birthdayNotification"
                            onChange={handleChange}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>5</td>
                        <td>Last 3 days to joining notifications</td>
                        <td>
                          <input
                            type="checkbox"
                            name="last3DaysNotification"
                            onChange={handleChange}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>6</td>
                        <td>Last 3 days to joining reminders</td>
                        <td>
                          <input
                            type="checkbox"
                            name="last3DaysReminder"
                            onChange={handleChange}
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="submmit">
                  <button type="Submit" class="btn6 btn-primary m-3">
                    SAVE
                  </button>
                </div>
              </div>
            </form>

            <form onSubmit={handleSubmit2}>
              <div className="card">
                <div className="card"></div>
                <div className="card"></div>
                <h3 className="card-title notification-head all-card-headers">
                  <strong>Note: </strong>
                  Kindly upload JPEG/JPG image only, Image size should not be
                  more
                  <span className="note-bold"> than 2MB. </span>
                </h3>
                <div className="notification">
                  <div className="row">
                    <div className="col-md-6 mb-3">
                      <span className="input-labels">
                        Company <sup className="fa fa-asterisk asterisk"></sup>
                      </span>
                      <div className="">
                        <select
                          class="form-control"
                          aria-label=".form-select-sm example"
                          name="id1"
                          onChange={handleChange}
                          required
                          value={noti.id1}
                        >
                          <option value="" selected disabled="selected">
                            Select Company{" "}
                          </option>
                          {companyList.map((company) => (
                            <option value={company.companyId} key={company}>
                              {company.companyName}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="col-md-6 mb-3">
                      <span className="Upload-Image input-labels">
                        Upload Image{" "}
                        <sup className="fa fa-asterisk asterisk"></sup>
                      </span>
                      <div className="">
                        {" "}
                        <input
                          className="form-control"
                          type="file"
                          id="imageInputFile"
                          name="file0"
                          accept=".jpg, .jpeg, .png"
                          onChange={handleChange}
                          required
                          ref={restFileRef}
                        ></input>{" "}
                      </div>{" "}
                    </div>
                  </div>
                </div>
                <div className="submmit">
                  <button
                    type="Submit"
                    class="btn6 btn-primary m-3"
                    // onClick={handleSubmit}
                  >
                    SUBMIT
                  </button>
                </div>
              </div>
            </form>
            {notification.checkboxSuccess && (
              <Snackbar msg="Notification addedd successfully" type="success" />
            )}
            {notification.checkboxFail && (
              <Snackbar msg="Something went wrong" type="error" />
            )}
            {notification.fileSuccess && (
              <Snackbar msg="Notification addedd successfully" type="success" />
            )}
            {notification.fileFail && (
              <Snackbar msg="Something went wrong" type="error" />
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default Notification;
