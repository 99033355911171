import { assetList } from "../constants/constants";
const intialState = {
  loading: null,
  assetList: [],
  error: "",
};

export const getAssetListReducer = (state = intialState, action) => {
  switch (action.type) {
    case assetList.LOADING:
      return {
        ...state,
        loading: true,
      };
    case assetList.ASSET_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        assetList: action.payload,
      };
    case assetList.ASSET_LIST_fail:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
