import axios from "axios";
import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import "./EmployeeTestimonials.css";
import {
  employeeTestimonialRequest,
  employeeTestimonialSuccess,
  employeeTestimonialFail,
} from "../../../redux/actions/postEmployeeTestimonials";
import { getTestimonialAction } from "../../../redux/actions/getEmployeeTestimonial";
import Snackbar from "../../../Components/uiComponets/snackbar/Snackbar";
import PaginationTable from "../../adminUi/PaginationTable";
import ModalEdit from "./EmployeeTestimonialsEdit";
import DeleteModal from "./EmployeeTestimonialsDelete";
import { columns } from "./columns";
import { updateNavbarHeading } from "../../../redux/actions/uiActions";
import Model from "../../adminUi/Model";
import { showErrorSnackbar ,showSuccessSnackbar} from "../../../redux/actions/uiActions";
import { Loader } from "../../../loader/Loader";

const intialTestimonial = {
  companyLocationId: "",
  employeeName: "",
  employeeDesignation: "",
  employeeMessage: "",
  employeeImage: null,
};

let companyObj = {
  156: "Namdhari Seeds Pvt.Ltd",
  157: "Namdhari Agro Fresh",
  158: "Namdhari Radio Tales",
  159: "SJS Health Care Ltd.",
};
function EmployeeTestimonials() {
  const { companyList } = useSelector((state) => state.getCompanyListReducer);
  
  const [loader, setLoader] = useState(false);

  const { loading, testimonial, error } = useSelector(
    (state) => state.getEmployeeTestimonialReducer
  );

  const postTestimonial = useSelector(
    (state) => state.employeeTestimonialReducer
  );

  const dispatch = useDispatch();
  const [testimonials, setTestimonials] = useState(intialTestimonial);
  const [rowId, setRowId] = useState(null);
  const [notification, setNotification] =   useState({
    success: null,
    fail: null,
  });
  React.useEffect(() => {
    dispatch(getTestimonialAction());
    dispatch(updateNavbarHeading("Employee Testimonial"));
    setNotification({ success: false, fail: false });
  }, []);
  const handleChange = (e) => {
    if (e.target.type === "file") {
      let file = e.target.files[0];
      // const fileType = about?.type === "image" ? 2097152 : 10485760;
      if (file?.size >= 2097152) {
        const input = document.querySelector("#imageInputFile");
        input.value = "";
        return dispatch(
          showErrorSnackbar(
            "File not supported, Please check the size and type of file",
            "error"
          )
        );
      }
      setTestimonials({
        ...testimonials,
        [e.target.name]: e.target.files[0],
      });
    } else {
      setTestimonials({
        ...testimonials,
        [e.target.name]: e.target.value,
      });
    }
  };

  const handleSubmit = (e) => {
    setLoader(true);
    e.preventDefault();
    
    dispatch(employeeTestimonialRequest());
    let newTestimonial = new FormData();
    newTestimonial.append(
      "selectCompany",
      parseInt(testimonials.companyLocationId)
      );
      newTestimonial.append("employeeName", testimonials.employeeName);
      newTestimonial.append(
        "employeeDesignation",
      testimonials.employeeDesignation
      );
      newTestimonial.append("employeeMessage", testimonials.employeeMessage);
      newTestimonial.append("file0", testimonials.employeeImage);
      axios
      .post(
        `${process.env.REACT_APP_DEV}/api/admin/addEmployeeTestimonial`,
        newTestimonial
        )
        .then((res) => {
        setLoader(false);
        dispatch(employeeTestimonialSuccess(res.data));
        setTestimonials(intialTestimonial);
        setNotification({ success: true, fail: false });
        dispatch(getTestimonialAction());
      })
      .catch((err) => {
        setLoader(false);
        dispatch(employeeTestimonialFail(err));
        setNotification({ success: false, fail: true });
      });

  };

  //table data
  let data = testimonial?.map((item, idx) => {
    return {
      idx: idx + 1,
      employeeName: item.employeeName,
      employeeDesignation: item.employeeDesignation,
      companyName: companyObj[item.compayLocationId],
      employeeMessage: item.employeeMessage,
      employeeImage: item.testimonialImage ? (
        <Model src={item.testimonialImage} />
      ) : (
        "No data found"
      ),
      action: <ModalEdit id={item.id} />,
      action2: <DeleteModal id={item.id} />,
    };
  });

  if (loader)
    return (
        <Loader open="true" />
    );
  return (
    <>
      <div className="content">
        <div className="content-wrapper">
          <div className="container-fluid">
            <div className="card p-3">
              <h3 className="card-title pb-3">
                <small>
                  <strong>Note: </strong>
                  Kindly upload JPEG/JPG image only, Image size should not be
                  more
                  <span className="note-bold"> than 2MB. </span>
                </small>
              </h3>
              <form onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-md-3 pb-3">
                    <span className="input-labels">Company-Location </span>
                    <sup className="fa fa-asterisk asterisk"></sup>

                    <select
                      class="form-control"
                      aria-label=".form-select-sm example"
                      onChange={handleChange}
                      name="companyLocationId"
                      value={testimonials.companyLocationId}
                      required
                    >
                      <option value="" selected disabled="selected">
                        Select Company{" "}
                      </option>

                      {companyList?.map((company) => (
                        <option value={company.companyId} key={company}>
                          {company.companyName}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="col-md-3 pb-3">
                    <span className="input-labels">Employee Name</span>
                    <sup className="fa fa-asterisk asterisk"></sup>
                    <input
                      name="employeeName"
                      className="form-control"
                      onChange={handleChange}
                      value={testimonials.employeeName}
                      pattern="[a-zA-Z ]+"
                      title="** only alphabets allowed"
                      required
                    />
                  </div>
                  <div className="col-md-3 pb-3">
                    <span className="input-labels">Designation</span>
                    <sup className="fa fa-asterisk asterisk"></sup>
                    <input
                      name="employeeDesignation"
                      className="form-control"
                      onChange={handleChange}
                      value={testimonials.employeeDesignation}
                      pattern="[a-zA-Z ]+"
                      title="** only alphabets allowed"
                      required
                    />
                  </div>
                  <div className="col-md-3 pb-3">
                    <span className="input-labels">Upload Employee Image</span>
                    <sup className="fa fa-asterisk asterisk"></sup>
                    <input
                      className="form-control"
                      onChange={handleChange}
                      type="file"
                      name="employeeImage"
                      id="imageInputFile"
                      required
                      // value={testimonials.employeeImage}
                      accept=".png, .gif, .jpeg, .jpg"
                    />
                  </div>
                  <div className="col-md-6">
                    <span className="input-labels">Message</span>
                    <sup className="fa fa-asterisk asterisk"></sup>
                    <textarea
                      name="employeeMessage"
                      className="form-control"
                      onChange={handleChange}
                      value={testimonials.employeeMessage}
                      required
                    />
                  </div>
                </div>

                <div className="submmit">
                  <button
                    // onClick={handleSubmit}
                    type="Submit"
                    class="btn6 btn-primary m-3"
                    style={{backgroundColor: "#03356D"}}
                  >
                    SUBMIT
                  </button>
                </div>
              </form>
            </div>

            {notification.success && (
              <Snackbar msg="Testimonial created successfully" type="success" />
            )}
            {notification.fail && (
              <Snackbar msg="Something went wrong" type="error" />
            )}
            {testimonial.length > 0 && (
              <PaginationTable columnsArr={columns} dataArr={data} />
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default EmployeeTestimonials;
