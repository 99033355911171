import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateNavbarHeading } from "../../../redux/actions/uiActions";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Snackbar from "../../../Components/uiComponets/snackbar/Snackbar";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { getCommonListAction } from "../../../redux/actions/getCommonList";
import axios from "axios";
import { getLeaderReducer } from "../../../redux/reducers/getleaderreducer";
import { MdModeEdit } from "react-icons/md";
let url = `${process.env.REACT_APP_DEV}/api/Hr/getAccommodation`;

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    width: "40rem",
  },
  card: {
    marginBottom: "0",
    width: "100%",
  },
}));

const HotelEditModal = ({ id }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const { loading, error, list } = useSelector(
    (state) => state.getCommonListReducer
  );
  const [hotelState, setHotelState] = useState({
    hotelName: "",
    hotelAddress: "",
    hotelAddress2: "",
    hotelAddress3: "",
    hotelAddress4: "",
    hotelAddress5: "",
  });

  const [notification, setNotification] = React.useState({
    success: null,
    fail: null,
  });

  const handleChange = (e) => {
    setHotelState({
      ...hotelState,
      [e.target.name]: e.target.value,
    });
  };

  const editHotelInfo = (editValue) => {
    const config = {
      method: "post",
      url: `${process.env.REACT_APP_DEV}/api/admin/editHotelInfo`,
      data: { editHotel: editValue },
    };
    return axios(config);
  };

  function handleSubmit(e) {
    e.preventDefault();
    let editHotel = {
      id: id,
      hotelName: hotelState.hotelName,
      hotelAddress: hotelState.hotelAddress,
      hotelAddress2: hotelState.hotelAddress2,
      hotelAddress3: hotelState.hotelAddress3,
      hotelAddress4: hotelState.hotelAddress4,
      hotelAddress5: hotelState.hotelAddress5,
    };

    const res = editHotelInfo(editHotel);
    if (res.status === 200) {
      window.location.reload();
      handleClose();
    } else {
      dispatch(getCommonListAction(url));
      setNotification({ fail: false, success: true });
    }
    setOpen(false);
  }
  console.log("Hotel Edit Model", list);

  useEffect(() => {
    if (list.length > 0) {
      let temp = list.find((li) => li.id === id);
      setHotelState({
        hotelName: temp.hotelName,
        hotelAddress: temp.hotelAddress,
        hotelAddress2: temp.hotelAddress2,
        hotelAddress3: temp.hotelAddress3,
        hotelAddress4: temp.hotelAddress4,
        hotelAddress5: temp.hotelAddress5,
      });
    }
  }, [id]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      <span type="button" onClick={handleOpen}>
        <MdModeEdit size={20} />
      </span>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <div className={`card ${classes.card}`}>
              <div className="card-header">
                <h3 className="card-title">EDIT HOTEL ADDRESS</h3>
              </div>
              <div className="card-body">
                <form onSubmit={handleSubmit} style={{ width: "100%" }}>
                  <section className="d-flex justify-content-between">
                    <div className="form-group d-flex flex-column">
                      <label>Hotel Name</label>
                      <input
                        name="hotelName"
                        onChange={handleChange}
                        className="form-control"
                        value={hotelState.hotelName}
                        pattern="[a-zA-Z ]+"
                        title="** only alphabets allowed"
                        required
                      />
                    </div>
                    <div className="form-group d-flex flex-column">
                      <label>Hotel Address Line 1</label>
                      <input
                        name="hotelAddress"
                        onChange={handleChange}
                        className="form-control"
                        value={hotelState.hotelAddress}
                        required
                      />
                    </div>
                  </section>
                  <section className="d-flex justify-content-between">
                    <div className="form-group d-flex flex-column">
                      <label>Hotel Address Line 2</label>
                      <input
                        name="hotelAddress2"
                        onChange={handleChange}
                        className="form-control"
                        value={hotelState.hotelAddress2}
                        required
                      />
                    </div>
                    <div className="form-group d-flex flex-column">
                      <label>Hotel Address Line 3</label>
                      <input
                        name="hotelAddress3"
                        onChange={handleChange}
                        className="form-control"
                        value={hotelState.hotelAddress3}
                      />
                    </div>
                  </section>

                  <section className="d-flex justify-content-between">
                    <div className="form-group d-flex flex-column">
                      <label>Hotel Address Line 4</label>
                      <textarea
                        name="hotelAddress4"
                        onChange={handleChange}
                        className="form-control"
                        value={hotelState.hotelAddress4}
                      />
                    </div>
                    <div className="form-group d-flex flex-column">
                      <label>Hotel Address Line 5</label>
                      <textarea
                        name="hotelAddress5"
                        onChange={handleChange}
                        className="form-control"
                        value={hotelState.hotelAddress5}
                      />
                    </div>
                  </section>
                  <section className="text-right d-flex ">
                    <button
                      type="submit"
                      className="btn btn-sm btn-primary text-white"
                    >
                      Submit
                    </button>
                    <button
                      onClick={handleClose}
                      type="submit"
                      className="btn btn-sm btn-primary ml-1 text-white"
                    >
                      close
                    </button>
                  </section>
                </form>
                {notification.success && (
                  <Snackbar
                    msg="Company Leader addedd successfully"
                    type="success"
                  />
                )}
                {notification.fail && (
                  <Snackbar msg="Something went wrong" type="error" />
                )}
              </div>
            </div>
          </div>
        </Fade>
      </Modal>
    </>
  );
};
export default HotelEditModal;
