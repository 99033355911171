import React, { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import BenefitModal from "./complianceModals/BenefitModal";
import PolicyModal from "./complianceModals/PolicyModal";
import AssetModal from "./complianceModals/AssetModal";
import { updateNavbarHeading } from "../../../redux/actions/uiActions";
import { useSelector, useDispatch } from "react-redux";
import { getPolicyListAction } from "../../../redux/actions/getPolicyList";
import { getBenefitListAction } from "../../../redux/actions/getBenefitList";
import { getAssetListAction } from "../../../redux/actions/getAssetList";
import PolicyViewModal from "./complianceModals/PolicyViewModal";
import PolicyDeleteModal from "./complianceModals/PolicyDeleteModal";
import BenefitEditModal from "./complianceModals/BenefitEditModal";
import BenefitDeleteModal from "./complianceModals/BenefitDeleteModal";
// import Model from "../adminUi/Model";
import Model from "../../adminUi/Model";
import BenefitPdfModal from "./complianceModals/BenefitPdfModal";
import { AiFillEye } from "react-icons/ai";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`wrapped-tabpanel-${index}`}
      aria-labelledby={`wrapped-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `wrapped-tab-${index}`,
    "aria-controls": `wrapped-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function TabsWrappedLabel() {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [value, setValue] = React.useState("one");
  const [open, setOpen] = useState(false);
  const [pdfPath, setPdfPath] = useState(null);
  const [companyId, setCompanyId] = React.useState("");
  const { policyList } = useSelector((state) => state.getPolicyListReducer);
  const { benefitList } = useSelector((state) => state.getBenefitListReducer);
  const { assetList } = useSelector((state) => state.getAssetListReducer);
  const { companyList } = useSelector((state) => state.getCompanyListReducer);

  const handleClose = () => {
    setOpen(false);
  };
  React.useEffect(() => {
    dispatch(updateNavbarHeading("Compliance"));
  }, []);
  React.useEffect(() => {
    if (pdfPath) {
      setOpen(true);
    }
  }, [pdfPath]);
  React.useEffect(() => {
    if (companyId) {
      let data = { companyId };
      dispatch(getPolicyListAction(data));
      dispatch(getBenefitListAction(data));
      dispatch(getAssetListAction(data));
    }
  }, [companyId]);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <div className="content">
      <div className="content-wrapper">
        <div className="content-fluid">
          <div className="row">
            <div className="col-md-4">
              <div className="form-group" style={{ padding: "15px 0 0 15px" }}>
                <select
                  onChange={(e) => setCompanyId(e.target.value)}
                  className="form-control"
                >
                  <option>Select Company</option>
                  {companyList.map((company) => (
                    <option value={company.companyId} key={company}>
                      {company.companyName}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>

          <div className={classes.root}>
            <AppBar position="static" color="default">
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="wrapped label tabs example"
                indicatorColor="primary"
                textColor="primary"
                variant="scrollable"
                scrollButtons="auto"
              >
                <Tab value="one" label="Policy" wrapped {...a11yProps("one")} />
                {/* <Tab
                  value="two"
                  label="Asset Management"
                  {...a11yProps("two")}
                /> */}
                <Tab value="three" label="Benefits" {...a11yProps("three")} />
              </Tabs>
            </AppBar>
            <TabPanel value={value} index="one">
              <div className="row">
                <div className="table-responsive m-0 w-100">
                  <table className="table table-hover admin-compliance-table ">
                    <thead>
                      <tr>
                        <th>S.No</th>
                        <th>Policy Name</th>
                        <th>Policy Type</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {policyList.length > 0 &&
                        policyList.map((li, idx) => (
                          <tr>
                            <td>{idx + 1}</td>
                            <td>{li.policyName || "No data"}</td>
                            <td>{li.policyType || "No data"}</td>
                            <td>
                              <Model src={li.policyPdf} />

                              <PolicyDeleteModal
                                companyId={companyId}
                                id={li.id - 1}
                              />
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
                <div></div>
              </div>
              <div className="submmit">
                {companyId === "" ? (
                  <button
                    disabled
                    className="btn btn-secondary text-right disabled-btn mt-2 mt-4"
                  >
                    Select a company
                  </button>
                ) : (
                  <button
                    disabled={companyId === ""}
                    type="Submit"
                    class="btn6 btn-primary mt-5"
                  >
                    <PolicyModal companyId={companyId} />
                  </button>
                )}
              </div>
            </TabPanel>
            <TabPanel value={value} index="two">
              <div className="row">
                <div className="table-responsive m-0 w-100">
                  <table className="table table-hover admin-compliance-table ">
                    <thead>
                      <tr>
                        <th>S.No</th>
                        {/* <th>Applicant Id</th> */}
                        <th>Contact Person Name</th>
                        <th>Requirement Type</th>
                        <th>Requirement Name</th>
                      </tr>
                    </thead>
                    <tbody>
                      {assetList.length > 0 &&
                        assetList.map((li, idx) => (
                          <tr>
                            <td>{idx + 1}</td>
                            {/* <td>{li?.applicantId || "No data"}</td> */}
                            <td>{li?.contactPerson || "No data"}</td>
                            <td>{li?.requirmentType || "No data"}</td>
                            <td>{li?.requirmentName || "No data"}</td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="submmit">
                {companyId === "" ? (
                  <button
                    disabled
                    className="btn btn-secondary text-right disabled-btn mt-2 mt-4"
                  >
                    Select a company
                  </button>
                ) : (
                  <button
                    disabled={companyId === ""}
                    type="Submit"
                    class="btn6 btn-primary mt-5"
                  >
                    <AssetModal companyId={companyId} />
                  </button>
                )}
              </div>
            </TabPanel>

            <TabPanel value={value} index="three">
              <div className="row">
                <div className="table-responsive m-0 w-100">
                  <table className="table table-hover admin-compliance-table ">
                    <thead>
                      <tr>
                        <th>S.No</th>
                        <th>Benefit List</th>
                        <th>Type</th>
                        <th>Upload Pdf</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {benefitList.length > 0 &&
                        benefitList?.reverse()?.map((li, idx) => (
                          <tr>
                            <td>{idx + 1}</td>
                            <td>{li.benefitType || "No data"}</td>
                            <td>{li.benefitName || "No data"}</td>
                            <td
                              style={{
                                textOverflow: "ellipsis",
                                overflow: "hidden",
                                whiteSpace: "nowrap",
                              }}
                            >
                              {li?.filePath.slice(li?.filePath.length - 3) ===
                              "pdf" ? (
                                <AiFillEye
                                  style={{
                                    fontSize: "2rem",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    setPdfPath(li?.filePath);
                                  }}
                                />
                              ) : (
                                <Model src={li.filePath} />
                              )}
                            </td>
                            <td>
                              <BenefitEditModal
                                companyId={companyId}
                                id={li.id}
                              />
                            </td>
                            <td>
                              <BenefitDeleteModal
                                id={li.id - 1}
                                companyId={companyId}
                              />
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="submmit mt-4">
                {companyId === "" ? (
                  <button
                    disabled
                    className="btn btn-secondary text-right disabled-btn mt-2 mt-4"
                  >
                    Select a company
                  </button>
                ) : (
                  <button
                    disabled={companyId === ""}
                    type="Submit"
                    class="btn6 btn-primary mt-5"
                  >
                    <BenefitModal companyId={companyId} />
                  </button>
                )}
              </div>
            </TabPanel>
            <BenefitPdfModal
              handleClose={handleClose}
              open={open}
              setOpen={setOpen}
              path={pdfPath}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
