import React, { useState } from "react";
import "./Login.css";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { adminLoginAction } from "../../../../redux/actions/adminActions";
import Snackbar from "../../../uiComponets/snackbar/Snackbar";
import NamdhariLogo from "../../../../Images/Nmlogo.png"
import { Loader } from "../../../../loader/Loader";

const Login = () => {
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.adminReducer);
  const [authError, setAuthError] = useState(null);
  const [user, setUser] = React.useState({
    email: "",
    password: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUser({ ...user, [name]: value });
  };
  const checkLoginValidation = (e) => {

    e.preventDefault();
    dispatch(adminLoginAction(user.email, user.password));
  };

  if (loading) {
    return <Loader open={true} />
  }
  return (
    <>
      <div class="loginPage">

        <form onSubmit={checkLoginValidation} className="login-container">
          <div className="imgHead">
            <img className="loginHead" src={NamdhariLogo} alt="NamdhariLogo"></img>
          </div>

          <div className="container">
            <p className="login">LOGIN</p>
            <div class="form-group">
              <input type="text" class="form-control InputField"
                placeholder="Username*"
                onChange={handleChange}
                name="email"
                required />
            </div>
            <div class="form-group">
              <input type="password" class="form-control InputField"
                id="exampleInputPassword1"
                placeholder="Enter password*"
                onChange={handleChange}
                name="password"
                required />

            </div>
            <Link to="/reset-password">
              <a class="forget">Can't log in ?</a>
            </Link>
            <div style={{ textAlign: "center", marginTop: '1rem' }}>
              <button type="submit" className="loginBtn btn btn-success">
                {" "}
                Log In
              </button>
            </div>
          </div>
        </form>

        <div className="login-footer">
          <p className="label4">
            Powered by <b>MyJoining</b>
          </p>
          <br />
          <p className="label5">
            © 2022 Orane Consuting Pvt Ltd, India. All rights reserved
          </p>
        </div>
        {authError && <Snackbar msg={authError} type="error" />}
      </div>
    </>
  );
};
export default Login;
