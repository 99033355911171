import React, { useState } from "react";
import "./CreatePassword.css";
import axios from "axios";
import {
  showErrorSnackbar,
  showSuccessSnackbar,
} from "../../../../redux/actions/uiActions";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";
import { Loader } from "../../../../loader/Loader";

import TextField from "@material-ui/core/TextField";

function CreatePassword() {
  const { applicantId } = JSON.parse(sessionStorage.getItem("resetApplicant"));

  const intialObj = {
    otp: "",
    applicantId,
  };

  // const [otpInput, setOtpInput] = useState({
  //   otp1: "",
  //   otp2: "",
  //   otp3: "",
  //   otp4: "",
  // });


  const [verify, setVerify] = useState(intialObj);
  const [vData, setVdata] = useState("");
  const history = useHistory();
  const dispatch = useDispatch();
  const { out } = JSON.parse(sessionStorage.getItem("Otp"));
  const resendOtp = JSON.parse(sessionStorage.getItem("resetApplicant"));
  // loader state
  const [loading, setLoading] = useState(false);

  const handleChange2 = (e) => {
    setVerify({
      ...verify,
      [e.target.name]: e.target.value,
    });
  };
  const resend = () => {
    setLoading(true);
    axios
      .post(`${process.env.REACT_APP_DEV}/api/generateOtp`, resendOtp)
      .then((res) => {
        if (res?.status === 200) {
          setVerify({
            intialObj,
          });
          setLoading(false);
          dispatch(showSuccessSnackbar(res?.data?.message, "success"));
        } else {
          setLoading(false);
          dispatch(showErrorSnackbar("Something went wrong", "warning"));
        }
      })
      .catch((err) => {
        setLoading(false);
        dispatch(showErrorSnackbar("Something went wrong", "warning"));
      });
  };

  const handleSubmit2 = (e) => {
    e.preventDefault();
    setLoading(true);
    axios
      .post(`${process.env.REACT_APP_DEV}/api/verifyOtp`, verify)
      .then((res) => {
        if (res?.status === 200) {
          setVdata({
            ...vData,
            res,
          });
          setVerify({
            intialObj,
          });
          setLoading(false);
          dispatch(showSuccessSnackbar(res?.data?.message, "success"));
        } else {
          setLoading(false);
          dispatch(showErrorSnackbar("Something went wrong", "warning"));
        }
      })
      .catch((err) => {
        setLoading(false);
        dispatch(showErrorSnackbar("Something went wrong", "warning"));
      });
  };

  const checkLoginValidation = (data) => {
    setLoading(true);
    axios
      .post(`${process.env.REACT_APP_DEV}/api/resetNewPassword`, data)
      .then((res) => {
        if (res?.status === 200) {
          setLoading(false);
          dispatch(
            showSuccessSnackbar("Password change successfully ", "success")
          );
          history.push(`/`);
        } else {
          setLoading(false);
          dispatch(showErrorSnackbar("Something went wrong", "warning"));
        }
      })
      .catch((err) => {
        setLoading(false);
        dispatch(showErrorSnackbar("Something went wrong", "error"));
      });
  };

  const Schema = Yup.object().shape({
    password: Yup.string().required("This field is required"),
    changepassword: Yup.string().when("password", {
      is: (val) => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf(
        [Yup.ref("password")],
        "Both password need to be the same"
      ),
    }),
  });

  // loader render logic
  if (loading) {
    return <Loader open={true} />;
  }

  // const handleChange = e => {
  //   const { maxLength, value, name } = e.target;
  //   const [fieldName, fieldIndex] = name.split("-");
  
  //   // Check if they hit the max character length
  //   if (value.length >= maxLength) {
  //     // Check if it's not the last input field
  //     if (parseInt(fieldIndex, 10) < 3) {
  //       // Get the next input field
  //       const nextSibling = document.querySelector(
  //         `input[name=otp${parseInt(fieldIndex, 10) + 1}]`
  //       );
  
  //       // If found, focus the next field
  //       if (nextSibling !== null) {
  //         nextSibling.focus();
  //       }
  //     }
  //   }
  //   setOtpInput({
  //     ...value,
  //     [`otp${fieldIndex}`]: value
  //   });
  // }
  // const HandleChange = event => {
  //   setOtp(event.target.value);
  //   console.log(event);
  //   console.log('value is:', event.target.value);
  // };

  // const inputfocus = (elmnt) => {
  //   if (elmnt.key === "Delete" || elmnt.key === "Backspace") {
  //     const next = elmnt.target.tabIndex - 2;
  //     if (next > -1) {

  //       elmnt.target.form.elements[next].focus()
  //     }
  //   }
  //   else {
  //     console.log("next");
     
  //       const next = elmnt.target.tabIndex;
  //       if (next < 5) {
  //         elmnt.target.form.elements[next].focus()
  //       }
  //   }

  // }

  return (
    <div className="backImg">
      <div className="card createContainer">
        <div /* style={{ padding: "1rem" }} */>
          <h3 /* style={{ color: "green", textAlign: "center", padding: "1rem" }} */
          >
            Create New Password
          </h3>

          <p style={{ color: "gray" }}>
            Enter the four digit verification <br />
            code sent to <span style={{ color: "blue" }}>{out}</span>
          </p>
        </div>
        <div className="main-container" /* style={{ padding: "1rem" }} */>
          <div className="otp-group">
            {/* <div className="form-row pass-box"> */}
            {/* <input
              className="form-control"
              type="text"
              name="otp"
              placeholder="One Time Password"
              onChange={handleChange2}
              maxlength="4"
              required
            /> */}
            <div className="input-container">
              {[1, 2, 3, 4].map((digit, index) => (
                <input
                  key={index}
                  type="text"
                  name= {"otp"+digit}
                  inputMode="numeric"
                  class="m-1 text-center form-control rounded"
                  pattern="\d{1}"
                  maxlength={1}
                  autoComplete="one-time-code"
                  placeholder="-"
                  // onChange={handleChange}
                  tabIndex={index+1}
                  // onKeyUp={(e) => inputfocus(e)}
                />
              ))}
            </div>
            <button
              type="verify"
              className="btn btn-lg verify-otp"
              onClick={handleSubmit2}
            >
              Verify OTP
            </button>
            {/* </div> */}
          </div>
          {/* <div> */}
          <p /* style={{ marginLeft: "2rem" }} */ onClick={resend}>
            Don't recieve the code? <a href="#">Resend code</a>
          </p>
          {/* </div> */}
          {vData?.res?.status === 200 ? (
            <Formik
              initialValues={{
                password: "",
                changepassword: "",
                username: applicantId,
              }}
              validationSchema={Schema}
              onSubmit={checkLoginValidation}
            >
              {({ values, errors, handleSubmit, handleChange, handleBlur }) => {
                return (
                  <form onSubmit={handleSubmit} className="pass-form">
                    <div className="pwdBox">
                      {/* <label for="password"></label> */}
                      <TextField
                        className="txtBox"
                        id="outlined-basic"
                        label="New Password"
                        variant="outlined"
                        type="password"
                        placeholder="New Password"
                        name="password"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.password}
                        required
                      />
                      {/* <input
                      className="txtBox"
                      type="password"
                      placeholder="New Password"
                      name="password"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.password}
                      required
                    /> */}
                      {/* <i class="fa fa-lock icon"></i> */}

                      <span class="error" style={{ color: "red" }}>
                        {errors.password}
                      </span>
                      {/* <label for="password"></label> */}
                      <TextField
                        className="txtBox"
                        id="outlined-basic"
                        variant="outlined"
                        label="Confirm Password"
                        type="password"
                        name="changepassword"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.changepassword}
                        required
                      />
                      {/* <input
                      className="txtBox"
                      type="password"
                      placeholder="Confirm Password"
                      name="changepassword"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.changepassword}
                      required
                    /> */}
                      <span
                        class="error"
                        style={{ color: "red", marginLeft: "7rem" }}
                      >
                        {errors.changepassword}
                      </span>
                      {/* <i class="fa fa-lock icon2"></i> */}
                    </div>
                    {/* <div class="form-group " style={{ marginTop: "4rem" }}> */}
                    <div className="pass-form__button-row">
                      {/* <div className="col-md-6"> */}
                      <button type="submit" className="btn submitBtn">
                        {" "}
                        Reset
                      </button>
                      {/* </div> */}
                      <div className="">
                        <Link to="/">
                          <button
                            type="close"
                            className="btn btn-secondary closeBtn"
                          >
                            Close
                          </button>
                        </Link>
                      </div>
                    </div>
                    {/* </div> */}
                  </form>
                );
              }}
            </Formik>
          ) : (
            <div className="">
              <Link to="/">
                <button
                  // style={{ marginTop: "6rem", right: "0" }}
                  type="close"
                  className="btn closeBtn"
                >
                  Close
                </button>
              </Link>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default CreatePassword;
