import axios from "axios";

export const companyAboutPost = (formData) => {
  const config = {
    method: "post",
    url: `${process.env.REACT_APP_DEV}/api/admin/addCompanyOverview`,
    data: formData,
  };
  return axios(config);
};
//company value Post Api
export const companyValuePost = (formData) => {
  const config = {
    method: "post",
    url: `${process.env.REACT_APP_DEV}/api/admin/addCompanyValue`,
    data: formData,
  };
  return axios(config);
};


export const companyVisionPost = (formData) => {
  const config = {
    method: "post",
    url: `${process.env.REACT_APP_DEV}/api/admin/addCompanyVision`,
    data: formData,
  };
  return axios(config);
};

//edit api's

export const editCompanyValue = (value) => {
  const config = {
    method: "post",
    url: `${process.env.REACT_APP_DEV}/api/admin/editValue`,
    data: {editValue:value},
  };
  return axios(config);
};

export const editCompanyVision = (vision) => {
  const config = {
    method: "post",
    url: `${process.env.REACT_APP_DEV}/api/admin/editVision`,
    data: {editVision:vision},
  };
  return axios(config);
};
export const editCompanyAbout = (about) => {
  const config = {
    method: "post",
    url: `${process.env.REACT_APP_DEV}/api/admin/editAbout`,
    data: {editAbout:about},
  };
  return axios(config);
};
