import { Get_Leader } from "../constants/constants";
const { LOADING, LEADER_GET_SUCCESS, LEADER_GET_FAIL } = Get_Leader;

const intialState = {
  loading: null,
  leaderList: [],
  error: "",
};

export const getLeaderReducer = (state = intialState, action) => {
  switch (action.type) {
    case LOADING:
      return {
        ...state,
        loading: true,
      };
    case LEADER_GET_SUCCESS:
      return {
        ...state,
        loading: false,
        leaderList: action.payload,
      };
    case LEADER_GET_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};
