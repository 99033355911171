import React, { useEffect, useState, state, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./Company_Holiday.css";
import Snackbar from "../../../Components/uiComponets/snackbar/Snackbar";
import Skeleton from "../../adminUi/Skeleton";
import {
  updateNavbarHeading,
  showErrorSnackbar,
} from "../../../redux/actions/uiActions";
import Model from "../../adminUi/Model";

import axios from "axios";
import {
  companyHolidayRequest,
  companyHolidaySuccess,
  companyHolidayFail,
} from "../../../redux/actions/holidayCalender";
import { holidayCalender } from "../../../redux/constants/constants";

import { Loader } from "../../../loader/Loader";

const initialObj = {
  selectCompany: "",
  file0: " ",
};

function Company_Holiday() {
  const createUserState = useSelector((state) => state.companyHolidayReducer);
  const dispatch = useDispatch();

  const [newholiday, setNewholiday] = useState(initialObj);

  const [loader, setLoader] = useState(false);

  const [notification, setNotification] = useState({
    success: null,
    fail: null,
  });
  const [companyDetails, setCompanyDetails] = useState(null);

  const { loading, error, details } = useSelector(
    (state) => state.getCompanyDetailsListReducer
  );

  console.log(details);
  const { companyList } = useSelector((state) => state.getCompanyListReducer);
  useEffect(() => {
    dispatch(updateNavbarHeading("Company Holiday"));
  }, []);

  useEffect(() => {
    if (details.length > 0) {
      const temp = details.find(
        (li) => li.companyLocationId === parseInt(newholiday.selectCompany)
      );
      setCompanyDetails(temp);
    }
  }, [newholiday.selectCompany]);

  const handleChange = (e) => {
    if (e.target.type === "file") {
      let file = e.target.files[0];
      // const fileType = newholiday?.type === "image" ? 2097152 : 10485760;
      if (file?.size >= 2097152) {
        const input = document.querySelector("#imageInputFile");
        input.value = "";
        return dispatch(
          showErrorSnackbar(
            "File not supported, Please check the size and type of file",
            "error"
          )
        );
      }
      setNewholiday({
        ...newholiday,
        [e.target.name]: file,
      });
    } else {
      setNewholiday({
        ...newholiday,
        [e.target.name]: e.target.value,
      });
    }
  };

  const restFileRef = useRef();

  const restInputFile = () => {
    restFileRef.current.value = "";
  };

  console.log(restFileRef);

  const handleSubmit = (e) => {
    setLoader(true);
    e.preventDefault();
    let newCompanyHoliday = new FormData();
    newCompanyHoliday.append(
      "selectCompany",
      parseInt(newholiday.selectCompany)
      );
      newCompanyHoliday.append("file0", newholiday.file0);
      
      dispatch(companyHolidayRequest());
      let url = `${process.env.REACT_APP_DEV}/api/admin/addCompanyHolidayCalendar`;
    setLoader(true);
    axios
      .post(url, newCompanyHoliday)
      .then((res) => {
        setLoader(false);
        dispatch(companyHolidaySuccess(res.newCompanyHoliday));
        setNewholiday(initialObj);
        restInputFile();
        setNotification({ fail: false, success: true });
        window.location.reload();
      })
      .catch((error) => {
        setLoader(false);
        dispatch(companyHolidayFail(error));
        setNotification({ fail: true, success: false });
      });
  };

  if (loader) return <Loader open="true" />;
  return (
    <>
      <Skeleton>
        <section className="mb-3">
          <div className="Company_holiday Company_holiday-primary  ">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12">
                <p className="Note">
                  <b>Note:</b> Kindly upload the image in JPEG/PNG only, Image
                  should not be more than 2MB.
                </p>
              </div>
            </div>

            <form onSubmit={handleSubmit}>
              <div class="form-row row">
                <div class="col-md-6">
                  <div className="form-group">
                    <div className="required">
                      <label className="input-labels ">Company</label>
                    </div>
                    <select
                      className="form-control"
                      name="selectCompany"
                      onChange={handleChange}
                      value={newholiday.selectCompany}
                      required
                    >
                      <option value="" selected disabled="selected">
                        Select Company
                      </option>
                      {companyList?.map((company) => (
                        <option value={company.companyId} key={company}>
                          {company.companyName}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div class="col-md-6">
                  <div className="required">
                    <label className="input-labels">
                      Holiday Calendar Image
                    </label>
                  </div>

                  <div>
                    {/* <input
                      type="file"
                      class="form-control"
                      id="exampleInputFile"
                      name="file0"
                      onChange={handleChange}
                      accept=".jpg, .jpeg, .png"
                      ref={restFileRef}
                      required
                    /> */}
                    <input
                      type="file"
                      class="form-control"
                      id="imageInputFile"
                      name="file0"
                      accept=".jpg, .jpeg, .png"
                      required
                      onChange={handleChange}
                      ref={restFileRef}
                    />
                  </div>
                </div>
              </div>
              <div className="form-row">
                <button
                  type="submit"
                  className="btn btn-primary btn1"
                  // onClick={handleSubmit}
                >
                  SUBMIT
                </button>
              </div>
            </form>
          </div>
        </section>

        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12">
            <div className="card">
              <div className="card-header">
                <h4 className="card-title">Holiday Calendar Picture</h4>
              </div>
              <div className="card-body">
                {newholiday.selectCompany === "" ? (
                  ""
                ) : (
                  <Model src={companyDetails?.holidayCalenderPic} />
                )}
              </div>
            </div>
            {notification.success && (
              <Snackbar msg="Holiday addedd successfully" type="success" />
            )}
            {notification.fail && (
              <Snackbar msg="Something went wrong" type="error" />
            )}
          </div>
        </div>
      </Skeleton>
    </>
  );
}
export default Company_Holiday;
