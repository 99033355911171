import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import Snackbar from "../../../Components/uiComponets/snackbar/Snackbar";
import { getSpocksAction } from "../../../redux/actions/getBuddiesList";
import { showErrorSnackbar } from "../../../redux/actions/uiActions";
import { Loader } from "../../../loader/Loader";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    width: "40rem",
  },
  card: {
    marginBottom: "0",
    width: "100%",
  },
}));

export default function TransitionsModal() {
  const dispatch = useDispatch();
  const { companyList } = useSelector((state) => state.getCompanyListReducer);
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  
  const [loader, setLoader] = useState(false);
  const [notification, setNotification] = useState({
    success: null,
    fail: null,
  });
  const [addKey, setAddKey] = useState({
    companyId: "",
    photo: "",
    name: "",
    designation: "",
    department: "",
    region: "",
    email: "",
    phone: "",
    detail1: "",
    detail2: "",
  });

  const [errors, setErrors] = useState({});

  const [values, setValues] = useState({
    name: "",
    designation: "",
    department: "",
    region: "",
  });
  const [phone, setPhone] = useState({
    phone: "",
  });

  const handleChange = (e) => {
    if (e.target.type === "file") {
      let file = e.target.files[0];
      // const fileType = about?.type === "image" ? 2097152 : 10485760;
      if (file?.size >= 2097152) {
        const input = document.querySelector("#imageInputFile");
        input.value = "";
        return dispatch(
          showErrorSnackbar(
            "File not supported, Please check the size and type of file",
            "error"
          )
        );
      }
      setAddKey({
        ...addKey,
        [e.target.name]: e.target.files[0],
      });
    } else {
      setAddKey({
        ...addKey,
        [e.target.name]: e.target.value,
      });
    }
    setValues({
      ...values,
      [e.target.name]: e.target.value.replace(/[^ ^A-Za-z]/gi, ""),
    });
    setPhone({
      ...phone,
      [e.target.name]: e.target.value.replace(/[^0-9]/gi, ""),
    });
  };
  const handleSubmit = (e) => {
    setLoader(true);
    e.preventDefault();
    const data = new FormData();
    data.append("photo", addKey.photo);
    data.append("companyId", addKey.companyId);
    data.append("name", addKey.name);
    data.append("designation", addKey.designation);
    data.append("department", addKey.designation);
    data.append("region", addKey.region);
    data.append("email", addKey.email);
    data.append("phone", addKey.phone);
    data.append("details", addKey.detail1);
    data.append("details2", addKey.detail2);
    axios
    .post(`${process.env.REACT_APP_DEV}/api/addKeySpocs`, data)
    .then((res) => {
        setLoader(false);
        setNotification({ success: true, fail: false });
        dispatch(getSpocksAction());

        handleClose();
      })
      .catch((err) => {
        setLoader(false);
        setNotification({ success: false, fail: true });
      });
  };
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  if (loader)
    return (
        <Loader open="true" />
    );
  return (
    <div>
      <span type="button" onClick={handleOpen}>
        ADD KEY SPOCKS
      </span>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <div className={`card ${classes.card}`}>
              <div className="card-header">
                <h3 className="card-title">ADD KEY SPOCKS</h3>
              </div>
              <div className="card-body">
                <h6>
                  <strong>NOTE:</strong>Kindly upload jpg/jpeg file only
                </h6>
                <form onSubmit={handleSubmit} style={{ width: "100%" }}>
                  <section className="d-flex justify-content-between">
                    <div className="form-group d-flex flex-column">
                      <label>Company</label>
                      <select
                        name="companyId"
                        onChange={handleChange}
                        className="form-control"
                        required
                      >
                        <option disabled selected value="">
                          Select Company
                        </option>
                        {companyList.map((company) => (
                          <option value={company.companyId} key={company}>
                            {company.companyName}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="form-group d-flex flex-column">
                      <label>Photo*</label>
                      <input
                        name="photo"
                        onChange={handleChange}
                        type="file"
                        id="imageInputFile"
                        className="form-control"
                        accept=".jpg, .jpeg, .png"                        
                        required
                      />
                    </div>
                  </section>
                  <section className="d-flex justify-content-between">
                    <div className="form-group d-flex flex-column">
                      <label>Name</label>
                      <input
                        name="name"
                        onChange={handleChange}
                        value={values.name}
                        className="form-control"
                        required
                      />
                    </div>
                    <div className="form-group d-flex flex-column">
                      <label>Designation</label>
                      <input
                        name="designation"
                        onChange={handleChange}
                        value={values.designation}
                        className="form-control"
                        required
                      />
                    </div>
                  </section>
                  <section className="d-flex justify-content-between">
                    {/* <div className="form-group d-flex flex-column">
                      <label>Department</label>
                      <input
                        name="department"
                        onChange={handleChange}
                        value={values.department}
                        className="form-control"
                        required
                      />
                    </div> */}
                    <div className="form-group d-flex flex-column">
                      <label>Region</label>
                      <input
                        name="region"
                        onChange={handleChange}
                        value={values.region}
                        className="form-control"
                        required
                      />
                    </div>
                  </section>

                  <section className="d-flex justify-content-between">
                    <div className="form-group d-flex flex-column">
                      <label>Email</label>
                      <input
                        name="email"
                        onChange={handleChange}
                        className="form-control"
                        required
                      />
                    </div>
                    <div className="form-group d-flex flex-column">
                      <label>Phone</label>
                      <input
                        name="phone"
                        onChange={handleChange}
                        className="form-control"
                        // required
                        value={phone.phone}
                        maxLength="10"
                      />
                    </div>
                  </section>
                  <section className="d-flex justify-content-between">
                    <div className="form-group d-flex flex-column">
                      <label>Details1</label>
                      <textarea
                        name="detail1"
                        onChange={handleChange}
                        className="form-control"
                        required
                      />
                    </div>
                    {/* <div className="form-group d-flex flex-column">
                      <label>Details2</label>
                      <textarea
                        name="detail2"
                        onChange={handleChange}
                        required
                        className="form-control"
                      />
                    </div> */}
                  </section>
                  <section className="text-right d-flex ">
                    <button
                      type="submit"
                      className="btn btn-sm btn-primary text-white"
                    >
                      Submit
                    </button>
                    <button
                      onClick={handleClose}
                      type="submit"
                      className="btn btn-sm btn-primary ml-1 text-white"
                    >
                      close
                    </button>
                  </section>
                </form>
                {notification.success && (
                  <Snackbar
                    msg="Key Spocks addedd successfully"
                    type="success"
                  />
                )}
                {notification.fail && (
                  <Snackbar msg="Something went wrong" type="error" />
                )}
              </div>
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}
