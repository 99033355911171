import React from "react";
const DashboardHR = React.lazy(() => import("../HR/dashboard/DashboardHR"));
const PreOfferCandidateList = React.lazy(() =>
  import("../HR/PreOfferCandidateList/PreOfferCandidateList")
);
const OfferCandidateList = React.lazy(() =>
  import("../HR/OfferCandidate/OfferCandidate")
);
const RejectedCandidates = React.lazy(() =>
  import("../HR/RejectedCandidates/rejectedCandidates")
);
const OfferedCandidate = React.lazy(() =>
  import("../HR/offeredCandidates/OfferedCandidates")
);
const Accommodation = React.lazy(() =>
  import("../HR/Accommodation/Accommodation")
);
const DocumentVerification = React.lazy(() =>
  import("../HR/Document Verification/DocumentVerification")
);
const FormVerification = React.lazy(() =>
  import("../HR/FormVerification/FormVerification")
);
const HiredCandidates = React.lazy(() =>
  import("../HR/HiredCandidates/HiredCandidates")
);
const AssetManagement = React.lazy(() =>
  import("../HR/AssetManagement/AssetManagement")
);
const CandidateSetting = React.lazy(() =>
  import("../HR/CandidateSetting/CandidateSetting")
);
const ReferredCandidate = React.lazy(() =>
  import("../HR/ReferredCandidate/ReferredCandidate")
);
const ReachoutCandidate = React.lazy(() =>
  import("../HR/ReachoutCandidate/ReachoutCandidate")
);
const AnalyticalGraphs = React.lazy(() =>
  import("../HR/Analytical Graphs/AnalyticalGraphs")
);
const BackgroundVerification = React.lazy(() =>
  import("../HR/BackgroundVerification/BackgroundVerification")
);
const PreOfferCandidateDetails = React.lazy(() =>
  import("../HR/PreOfferCandidateDetails/PreOfferCandidateDetails")
);
const OfferCandidateDetails = React.lazy(() =>
  import("../HR/OfferedCandidateDetails/OfferedCandidateDetails")
);
const Reports = React.lazy(() => import("../HR/GenerateReports/Reports"));
const CancelledCandidates = React.lazy(() =>
  import("../HR/CancelledCandidates/CancelledCandidates")
);
const Queries = React.lazy(() => import("../HR/Queries/Queries"));
const UploadCandidate = React.lazy(() =>
  import("../HR/UploadCandidate/UploadCandidate")
);
const DepartmentData = React.lazy(() =>
  import("../HR/Analytical Graphs/DepartmentData")
);
const RecruiterData = React.lazy(() =>
  import("../HR/Analytical Graphs/RecruiterData")
);
const PositionClosed = React.lazy(() =>
  import("../HR/Analytical Graphs/PositionsClosed")
);
const Faq = React.lazy(() => import("../HR/CreateFaq/Faqs"));
const PreOfferDetailDocumnets = React.lazy(() =>
  import(
    "../HR/PreOfferCandidateDetailsDocuments/PreOfferCandidateDetailsDocument"
  )
);
const ReadyToHireCandidates = React.lazy(() =>
  import("../HR/ReadyToHireCandidate/ReadyToHireCandidate")
);

const OfferLetterSeeds = React.lazy(() =>
  import(
    "../HR/offerLetters/seedsOfferLetter/LetterOfEmploymentSeeds/LetterOfEmployment"
  )
);
const OfferLetterAgro = React.lazy(() =>
  import("../HR/offerLetters/agroOfferLetter/OfferLetterFreshFarms/OfferLetter")
);
const OfferLetterSjs = React.lazy(() =>
  import("../HR/offerLetters/sjsOfferLetter/Offer_letterSJS/Offer_Letter")
);

const OfferLetterDairy = React.lazy(() =>
  import(
    "../HR/offerLetters/dairyOfferLetter/Letter of Employment OfferDairy/OfferLetter"
  )
);

const OfferLetterSeeds1 = React.lazy(() =>
  import(
    "../HR/offerLetters/seedsOfferLetter/LetterOfEmploymentSeeds/LetterOfEmployment"
  )
);
const OfferLetterAgro1 = React.lazy(() =>
  import("../HR/offerLetters/agroOfferLetter/OfferLetterFreshFarms/OfferLetter")
);
const OfferLetterSjs1 = React.lazy(() =>
  import("../HR/offerLetters/sjsOfferLetter/Offer_letterSJS/Offer_Letter")
);

const OfferLetterDairy1 = React.lazy(() =>
  import(
    "../HR/offerLetters/dairyOfferLetter/Letter of Employment OfferDairy/OfferLetter"
  )
);

export const routes = [
  {
    path: "/hr/dashboard",
    component: DashboardHR,
    excat: true,
  },
  {
    path: "/hr",
    component: DashboardHR,
    excat: true,
  },
  {
    path: "/hr/preOfferCandidate",
    component: PreOfferCandidateList,
    excat: true,
  },
  {
    path: "/hr/preOffer/preOfferDocs",
    component: PreOfferDetailDocumnets,
    excat: true,
  },
  {
    path: "/hr/offerCandidate",
    component: OfferCandidateList,
    excat: true,
  },
  {
    path: "/hr/preOffer/preOfferCandidateDetails/:id",
    component: PreOfferCandidateDetails,
    excat: true,
  },
  {
    path: "/hr/offer/offerCandidateDetails/:id",
    component: OfferCandidateDetails,
    excat: true,
  },
  {
    path: "/hr/rejectedCandidates",
    component: RejectedCandidates,
    excat: true,
  },
  {
    path: "/hr/uploadCandidate",
    component: UploadCandidate,
    excat: true,
  },
  {
    path: "/hr/preOnBoard/offeredCandidates",
    component: OfferedCandidate,
    excat: true,
  },
  {
    path: "/hr/preOnBoard",
    component: OfferedCandidate,
    excat: true,
  },
  {
    path: "/hr/preOnBoard/accommodation",
    component: Accommodation,
    excat: true,
  },
  {
    path: "/hr/preOnBoard/documentVerification",
    component: DocumentVerification,
    excat: true,
  },
  {
    path: "/hr/preOnBoard/formVerification",
    component: FormVerification,
    excat: true,
  },
  {
    path: "/hr/preOnBoard/hiredCandidates",
    component: HiredCandidates,
    excat: true,
  },
  {
    path: "/hr/preOnBoard/assetsManagement",
    component: AssetManagement,
    excat: true,
  },
  {
    path: "/hr/candidateArchive/candidateSetting",
    component: CandidateSetting,
    excat: true,
  },
  {
    path: "/hr/candidateArchive",
    component: CandidateSetting,
    excat: true,
  },
  {
    path: "/hr/candidateArchive/referredCandidates",
    component: ReferredCandidate,
    excat: true,
  },
  {
    path: "/hr/reachOutCandidates",
    component: ReachoutCandidate,
    excat: true,
  },
  {
    path: "/hr/analytics/analyticalGraphs",
    component: AnalyticalGraphs,
    excat: true,
  },
  {
    path: "/hr/backgroundVerification",
    component: BackgroundVerification,
    excat: true,
  },
  {
    path: "/hr/analytics/generateReport",
    component: Reports,
    excat: true,
  },
  {
    path: "/hr/analytics",
    component: Reports,
    excat: true,
  },
  {
    path: "/hr/candidateArchive/cancelledCandidate",
    component: CancelledCandidates,
    excat: true,
  },
  {
    path: "/hr/help/queries",
    component: Queries,
    excat: true,
  },
  {
    path: "/hr/help",
    component: Queries,
    excat: true,
  },
  {
    path: "/hr/help/faq",
    component: Faq,
    excat: true,
  },
  {
    path: "/hr/analytics/departmentData",
    component: DepartmentData,
    excat: true,
  },
  {
    path: "/hr/analytics/RecruiterData",
    component: RecruiterData,
    excat: true,
  },
  {
    path: "/hr/analytics/positionsClosed",
    component: PositionClosed,
    excat: true,
  },
  {
    path: "/hr/preOnBoard/readyToHire",
    component: ReadyToHireCandidates,
    excat: true,
  },
  {
    path: "/hr/offer/seeds/offerLetter",
    component: OfferLetterSeeds,
    excat: true,
  },
  {
    path: "/hr/offer/agro/offerLetter",
    component: OfferLetterAgro,
    excat: true,
  },
  {
    path: "/hr/offer/dairy/offerLetter",
    component: OfferLetterDairy,
    excat: true,
  },
  {
    path: "/hr/offer/sjs/offerLetter",
    component: OfferLetterSjs,
    excat: true,
  },
  {
    path: "/hr/offer/seeds/offerLetter/:appliId",
    component: OfferLetterSeeds1,
    excat: true,
  },
  {
    path: "/hr/offer/agro/offerLetter/:appliId",
    component: OfferLetterAgro1,
    excat: true,
  },
  {
    path: "/hr/offer/dairy/offerLetter/:appliId",
    component: OfferLetterDairy1,
    excat: true,
  },
  {
    path: "/hr/offer/sjs/offerLetter/:appliId",
    component: OfferLetterSjs1,
    excat: true,
  },
];
