export const adminConstants = {
  LOADING: "LOADING",
  LOGIN_ADMIN_SUCCESS: "LOGIN_ADMIN_SUCCESS",
  LOGIN_ADMIN_FAIL: "LOGIN_ADMIN_FAIL",
  HR_ROLE_COMPANY:"HR_ROLE_COMPANY"
};

//get company list
export const companyList = {
  LOADING: "LOADING",
  COMPANY_LIST_SUCCESS: "COMPANY_LIST_SUCCESS",
  COMPANY_LIST_FAIL: "COMPANY_LIST_FAIL",
};

//navbar heading
export const navbarHeading = {
  UPDATE_HEADING: "UPDATE_HEADING",
};

//snackbar constants
export const snackbarHeading = {
  SHOW_NOTIFICATION_SNACKBAR: " SHOW_NOTIFICATION_SNACKBAR",
};

//create user
export const createRole = {
  LOADING: "LOADING",
  CREATE_USER_SUCCESS: "CREATE_USER_SUCCESS",
  CREATE_USER_FAIL: "CREATE_USER_FAIL",
};

//get roles
export const getRoles = {
  LOADING: "LOADING",
  USER_LIST_SUCCESS: "USER_LIST_SUCCESS",
  USER_LIST_FAIL: "USER_LIST_FAIL",
};

//post employee testimonials
export const postEmployeeTestimonials = {
  LOADING: "LOADING",
  POST_TESTIMONIALS_SUCCESS: "POST_TESTIMONIALS_SUCCESS",
  POST_TESTIMONIALS_FAIL: "POST_TESTIMONIALS_FAIL",
};

//get employee testimonials
export const getEmployeeTestimonial = {
  LOADING: "LOADING",
  EMPLOYEE_TESTIMONIAL_SUCCESS: "EMPLOYEE_TESTIMONIAL_SUCCESS",
  EMPLOYEE_TESTIMONIAL_FAIL: "EMPLOYEE_TESTIMONIAL_FAIL",
};

//get buddies
export const getBuddies = {
  LOADING: "LOADING",
  BUDDIES_LIST_SUCCESS: "BUDDIES_LIST_SUCCESS",
  BUDDIES_LIST_FAIL: "BUDDIES_LIST_FAIL",
};

//get company details
export const getCompanyDetails = {
  LOADING: "LOADING",
  GET_COMPANY_DETAILS_LIST_SUCCESS: "GET_COMPANY_DETAILS_LIST_SUCCESS",
  GET_COMPANY_DETAILS_LIST_FAIL: "GET_COMPANY_DETAILS_LIST_SUCCESS",
};

export const getAssignProcess = {
  LOADING: "LOADING",
  GET_ASSIGN_LIST_SUCCESS: "GET_ASSIGN_LIST_SUCCESS",
  GET_ASSIGN_LIST_FAIL: "GET_ASSIGN_LIST_SUCCESS",
};

//common lister
export const commonLister = {
  LOADING: "LOADING",
  COMMON_LIST_SUCCESS: "COMMON_LIST_SUCCESS",
  COMMON_LIST_FAIL: "COMMON_LIST_FAIL",
};

//getpolicylist
export const policyList = {
  LOADING: "LOADING",
  POLICY_LIST_SUCCESS: "POLICY_LIST_SUCCESS",
  POLICY_LIST_FAIL: "POLICY_LIST_FAIL",
};
//get benifit list
export const benefitList = {
  LOADING: "LOADING",
  BENEFIT_LIST_SUCCESS: "BENEFIT_LIST_SUCCESS",
  BENEFIT_LIST_FAIL: "BENEFIT_LIST_FAIL",
};

//get benifit list
export const assetList = {
  LOADING: "LOADING",
  ASSET_LIST_SUCCESS: "ASSET_LIST_SUCCESS",
  ASSET_LIST_FAIL: "ASSET_LIST_FAIL",
};

export const companyNotification = {
  LOADING: "LOADING",
  COMPANY_NOTIFI_SUCCESS: "COMPANY_NOTIFI_SUCCESS",
  COMPANY_NOTIFI_FAIL: "COMPANY_NOTIFI_FAIL",
};
//get Holiday Calendar
export const getholidayCalender= {
  LOADING:"LOADING",
  HOLIDAY_CALENDER_SUCCESS:"HOLIDAY_CALENDER_SUCCESS",
  HOLIDAY_CALENDER_FAIL:"HOLIDAY_CALENDER_FAIL"
}

export const holidayCalender = {
  LOADING: "LOADING",
  HOLIDAY_CALENDER_SUCCESS: "HOLIDAY_CALENDER_SUCCESS",
  HOLIDAY_CALENDER_FAIL: "HOLIDAY_CALENDER_SUCCESS",
};
export const Company_Leader= {
  LOADING: "LOADING",
  LEADER_SUCCESS: "LEADER_SUCCESS",
  LEADER_FAIL: "LEADER_FAIL",
};

export const Get_Leader= {
  LOADING: "LOADING",
  LEADER_GET_SUCCESS: "LEADER_GET_SUCCESS",
  LEADER_GET_FAIL: "LEADER_GET_FAIL",
};

export const getHrAccessList = {
  ALL_COMPANY_LIST:"ALL_COMPANY_LIST",
  HR_SELECTED_COMPANY:"HR_SELECTED_COMPANY"
}


export const candidateList = {
  SUCCESS:"SUCCESS",
  FAIL:"FAIL"
}



export const candidateDetailsPreOnboardConstants = {
  LOADING:"LOADING",
  FETCH_PRE_ONBOARD_DETAILS:"FETCH_PRE_ONBOARD_DETAILS",
  DATA_LIST_FAIL:"DATA_LIST_FAIL"
}
//Review form green tick 
export const reviewForm= {
  LOADING:"LOADING",
  REVIEW_FORM_SUCCESS:"REVIEW_FORM_SUCCESS",
  REVIEW_FORM_FAIL:"REVIEW_FORM_FAIL"
}
//Review form get api 
export const reviewFormGet= {
  LOADING:"LOADING",
  REVIEW_FORM_GET_SUCCESS:"REVIEW_FORM_SUCCESS",
  REVIEW_FORM_GET_FAIL:"REVIEW_FORM_FAIL"
}