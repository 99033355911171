import { candidateDetailsPreOnboardConstants } from "../constants/constants";
const { LOADING, FETCH_PRE_ONBOARD_DETAILS, DATA_LIST_FAIL } = candidateDetailsPreOnboardConstants;

const intialState = {
  data: null,
  loading: null,
  error: "",
};

export const candidatePreOnBoardReducer = (state = intialState, action) => {

  switch (action.type) {
    case LOADING:
      return {
        ...state,
        loading: true,
      };
    case DATA_LIST_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case FETCH_PRE_ONBOARD_DETAILS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        
      };
    default:
      return state;
  }


};
