import { adminConstants } from "../constants/constants";
const initialState = {
  loading: null,
  admin: null,
  error: "",
  hrAccessList: {},
};

const { LOADING, LOGIN_ADMIN_SUCCESS, LOGIN_ADMIN_FAIL, HR_ROLE_COMPANY } =
  adminConstants;

export const adminReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOADING:
      return {
        ...state,
        loading: true,
      };
    case LOGIN_ADMIN_SUCCESS:
      return {
        ...state,
        loading: false,
        admin: action.payload,
      };
    case LOGIN_ADMIN_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case HR_ROLE_COMPANY:
      return {
        ...state,
        loading: false,
        hrAccessList: action.payload,
      };
    default:
      return state;
  }
};
