import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { useSelector } from "react-redux";
import axios from "axios";
import { useDispatch } from "react-redux";
import { getBuddiesAction } from "../../../redux/actions/getBuddiesList";
import Snackbar from "../../../Components/uiComponets/snackbar/Snackbar";
import { MdModeEdit } from "react-icons/md";
import { editbudddies } from "../../adminMainServices/Buddiesservices/buddiesservice";
import { Loader } from "../../../loader/Loader";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],

    width: "40rem",
  },
  card: {
    marginBottom: "0",
    width: "100%",
  },
}));

export default function TransitionsModal({ id }) {
  const dispatch = useDispatch();
  const { loading, buddies, error } = useSelector(
    (state) => state.getBuddiesReducer
  );
  const { companyList } = useSelector((state) => state.getCompanyListReducer);
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  
  const [loader, setLoader] = useState(false);
  const [notification, setNotification] = React.useState({
    success: null,
    fail: null,
  });
  const [addKey, setAddKey] = React.useState({
    companyId: "",
    name: "",
    department: "",
    region: "",
    email: "",
    phone: "",
    detail: "",
  });

  const [errors, setErrors] = useState({});

  const [values, setValues] = useState({
    name: "",
    department: "",
    region: "",
  });
  const [phone, setPhone] = useState({
    phone: "",
  });

  console.log(buddies);

  useEffect(() => {
    if (buddies.length > 0) {
      let temp = buddies.find((li) => li.id === id);
      setAddKey({
        country: temp.companyId,
        department: temp.department,
        detail: temp.detail,
        email: temp.email,
        name: temp.name,
        phone: temp.phone,
        region: temp.region,
      });
    }
  }, [id]);

  const handleChange = (e) => {
    if (e.target.type === "file") {
      setAddKey({
        ...addKey,
        [e.target.name]: e.target.files[0],
      });
    } else {
      setAddKey({
        ...addKey,
        [e.target.name]: e.target.value,
      });
    }
    setValues({
      ...values,
      [e.target.name]: e.target.value.replace(/[^ ^A-Za-z]/gi, ""),
    });
    setPhone({
      ...phone,
      [e.target.name]: e.target.value.replace(/[^0-9]/gi, ""),
    });
  };
  const handleSubmit = async (e) => {
    setLoader(true);
    e.preventDefault();
    
    let Buddie = {
      id: id,
      country: addKey.companyId,
      department: addKey.department,
      detail: addKey.detail,
      email: addKey.email,
      name: addKey.name,
      phone: addKey.phone,
      region: addKey.region,
    };
    
    const res = await editbudddies(Buddie);
    if (res.status === 200) {
      setLoader(false);
      window.location.reload();
      handleClose();
    } else {
      setLoader(false);
      setNotification({ fail: true, success: false });
    }
    handleClose();

    // const data = new FormData();
    // data.append("file", addKey.photo);
    // data.append("name", addKey.name);
    // data.append("companyId", addKey.companyId);
    // data.append("department", addKey.designation);
    // data.append("region", addKey.region);
    // data.append("email", addKey.email);
    // data.append("phone", addKey.phone);
    // data.append("detail", addKey.detail);
    // axios
    //   .post(`${process.env.REACT_APP_DEV}/api/addIGTBuddieAlongPhoto`, data)
    //   .then((res) => {
    //     dispatch(getBuddiesAction());
    //     setNotification({ success: true, fail: false });
    //   })
    //   .catch((err) => {
    //     setNotification({ success: false, fail: true });
    //   });
  };
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  if (loader)
    return (
        <Loader open="true" />
    );
  return (
    <div>
      <MdModeEdit size={20} onClick={handleOpen} />
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <div className={`card ${classes.card}`}>
              <div className="card-header">
                <h3 className="card-title">EDIT BUDDIES</h3>
              </div>
              <div className="card-body">
                {/* <h6>
                  <strong>NOTE: </strong>Kindly upload jpg/jpeg file only.
                </h6> */}
                <form onSubmit={handleSubmit} style={{ width: "100%" }}>
                  <section className="d-flex justify-content-between">
                    <div className="form-group d-flex flex-column">
                      <label>Company</label>
                      <select
                        name="companyId"
                        onChange={handleChange}
                        className="form-control"
                        required
                        value={addKey.country}
                      >
                        <option disabled selected value="">
                          Select Company
                        </option>
                        {companyList.map((company) => (
                          <option value={company.companyId} key={company}>
                            {company.companyName}
                          </option>
                        ))}
                      </select>
                    </div>
                    {/* <div className="form-group d-flex flex-column">
                      <label>Photo*</label>
                      <input
                        name="photo"
                        onChange={handleChange}
                        type="file"
                        className="form-control"
                        required
                        accept=".jpg, .jpeg, .png"
                      />
                    </div> */}
                  </section>
                  <section className="d-flex justify-content-between">
                    <div className="form-group d-flex flex-column">
                      <label>Name*</label>
                      <input
                        name="name"
                        onChange={handleChange}
                        className="form-control"
                        value={values.name || addKey.name}
                        required
                      />
                    </div>
                    <div className="form-group d-flex flex-column">
                      <label>Department</label>
                      <input
                        name="department"
                        onChange={handleChange}
                        value={values.department || addKey.department}
                        className="form-control"
                      />
                    </div>
                  </section>
                  <section className="d-flex justify-content-between">
                    {/* <div className="form-group d-flex flex-column">
                      <label>Department*</label>
                      <select
                        name="department"
                        onChange={handleChange}
                        className="form-control"
                        required
                      >
                        <option>Select Department</option>
                      </select>
                    </div> */}
                    <div className="form-group d-flex flex-column">
                      <label>Region*</label>
                      <input
                        name="region"
                        onChange={handleChange}
                        className="form-control"
                        value={values.region || addKey.region}
                        required
                      />
                    </div>
                  </section>

                  <section className="d-flex justify-content-between">
                    <div className="form-group d-flex flex-column">
                      <label>Email*</label>
                      <input
                        type="email"
                        name="email"
                        onChange={handleChange}
                        className="form-control"
                        value={addKey.email}
                        required
                      />
                    </div>
                    <div className="form-group d-flex flex-column">
                      <label>Phone</label>
                      <input
                        name="phone"
                        onChange={handleChange}
                        className="form-control"
                        value={phone.phone || addKey.phone}
                        maxlength="10"
                      />
                    </div>
                  </section>
                  <section>
                    <div className="form-group d-flex flex-column">
                      <label>Details*</label>
                      <textarea
                        name="detail"
                        onChange={handleChange}
                        className="form-control"
                        value={addKey.detail}
                        required
                      />
                    </div>
                  </section>
                  <section className="text-right d-flex">
                    <button
                      type="submit"
                      className="btn btn-sm btn-primary text-white"
                      style={{backgroundColor: "#03356D"}}
                      >
                      Save
                    </button>
                    <button
                      onClick={handleClose}
                      type="submit"
                      className="btn btn-sm btn-primary ml-1 text-white"
                      style={{backgroundColor: "#03356D"}}
                    >
                      close
                    </button>
                  </section>
                  {notification.success && (
                    <Snackbar msg="HR created successfully" type="success" />
                  )}
                  {notification.fail && (
                    <Snackbar msg="Something went wrong" type="error" />
                  )}
                </form>
              </div>
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}
