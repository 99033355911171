import React from "react";
import { Switch } from "react-router-dom";
import Login from "../Components/home/auth/login/Login";
import ResetPassword from "../Components/home/auth/login/ResetPassword";

const DashboardLayoutRoute = ({ children }) => {
  return (
    <>
      <Switch>
        {children}

      </Switch>

    </>
  );
};

export default DashboardLayoutRoute;
