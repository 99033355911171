import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import Snackbar from "../../../Components/uiComponets/snackbar/Snackbar";
import { getBuddiesAction } from "../../../redux/actions/getBuddiesList";
import { MdModeEdit } from "react-icons/md";
import { editCompanyTestimonials } from "../../adminMainServices/employeeTesrimonials/employeeTestimonialsservice";
import { getTestimonialAction } from "../../../redux/actions/getEmployeeTestimonial";
import { Loader } from "../../../loader/Loader";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    width: "40rem",
  },
  card: {
    marginBottom: "0",
    width: "100%",
  },
}));

export default function KeySpocksModal({ id }) {
  console.log(id);
  const dispatch = useDispatch();
  const { companyList } = useSelector((state) => state.getCompanyListReducer);
  const { loading, testimonial, error } = useSelector(
    (state) => state.getEmployeeTestimonialReducer
  );
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  
  const [loader, setLoader] = useState(false);

  const [notification, setNotification] = React.useState({
    success: null,
    fail: null,
  });
  const [addKey, setAddKey] = React.useState({
    id: "",
    name: "",
    designation: "",
    message: "",
  });

  console.log("Employee Testimonial Edit Model", testimonial);
  useEffect(() => {
    if (testimonial.length > 0) {
      let temp = testimonial.find((li) => li.id === id);
      setAddKey({
        name: temp.employeeName,
        designation: temp.employeeDesignation,
        message: temp.employeeMessage,

      });
    }
  }, [id]);

  const handleChange = (e) => {
  
      setAddKey({
        ...addKey,
        [e.target.name]: e.target.value,
      });
    

  };
  const handleSubmit = async (e) => {
    setLoader(true);
    e.preventDefault();
    let Testimonial = {
      id: id,
      employeeName: addKey.name,
      employeeDesignation: addKey.designation,
      employeeMessage: addKey.message
    }
    console.log(Testimonial)
    const res = await editCompanyTestimonials(Testimonial);
    if (res.status === 200) {
      setLoader(false);
      dispatch(getTestimonialAction());
      handleClose()
    } else {
      setLoader(false);
      setNotification({ fail: true, success: false })
    }



    // const data = new FormData();
    // data.append("photo", addKey.photo);
    // data.append("companyId", addKey.companyId);
    // data.append("name", addKey.name);
    // data.append("designation", addKey.designation);
    // data.append("department", addKey.designation);
    // data.append("details2", addKey.message);
    // axios
    //   .post(`${process.env.REACT_APP_DEV}/api/addKeySpocs`, data)
    //   .then((res) => {
    //     setNotification({ success: true, fail: false });
    //     dispatch(getBuddiesAction());
    //     handleClose();
    //   })
    //   .catch((err) => {
    //     setNotification({ success: false, fail: true });
    //   });
  };


  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  if (loader)
    return (
        <Loader open="true" />
    );
  return (
    <div>
      <MdModeEdit onClick={handleOpen} size={20} />
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <div className={`card ${classes.card}`}>
              <div className="card-header">
                <h3 className="card-title">EDIT TESTIMONIAL</h3>
              </div>
              <div className="card-body">
                <form onSubmit={handleSubmit} style={{ width: "100%" }}>
                  <section className="d-flex justify-content-between">
                    {/* <div className="form-group d-flex flex-column">
                      <label>Company</label>
                      <select
                        class="form-select form-select-sm Select-Company-ET "
                        aria-label=".form-select-sm example"
                        onChange={handleChange}
                        name="companyLocationId"
                      >
                        <option selected>Select Company </option>
                        {companyList?.map((company) => (
                          <option value={company.companyId} key={company}>
                            {company.companyName}
                          </option>
                        ))}
                      </select>
                    </div> */}
                  </section>
                  <section>
                    {/* <div className="form-group d-flex flex-column">
                      <label>Photo*</label>
                      <input
                        name="photo"
                        onChange={handleChange}
                        type="file"
                        className="form-control"
                        required
                      />
                    </div> */}
                  </section>
                  <section className="d-flex justify-content-between">
                    <div className="form-group d-flex flex-column">
                      <label>Employee Name</label>
                      <input
                        name="name"
                        onChange={handleChange}
                        className="form-control"
                        value={addKey.name}
                        required
                        pattern="[a-zA-Z ]+"
                        title="** only alphabets allowed"
                      />
                    </div>
                  </section>
                  <section>
                    <div className="form-group d-flex flex-column">
                      <label>Designation</label>
                      <input
                        name="designation"
                        onChange={handleChange}
                        className="form-control"
                        value={ addKey.designation}
                        required
                        pattern="[a-zA-Z ]+"
                        title="** only alphabets allowed"
                      />
                    </div>
                  </section>
                  {/* <section className="d-flex justify-content-between">
                    <div className="form-group d-flex flex-column">
                      <label>Department</label>
                      <input
                        name="department"
                        onChange={handleChange}
                        className="form-control"
                        required
                      />
                    </div>
                  </section> */}
                  <section>
                    <div className="form-group d-flex flex-column">
                      <label>Message</label>
                      <textarea
                        name="message"
                        onChange={handleChange}
                        className="form-control"
                        value={addKey.message}
                        required
                      />
                    </div>
                  </section>
                  <section className="text-right d-flex ">
                    <button
                      type="submit"
                      className="btn btn-sm btn-primary text-white"
                      style={{backgroundColor: "#03356D"}}
                      >
                      Save
                    </button>
                    <button
                      onClick={handleClose}
                      type="submit"
                      className="btn btn-sm btn-primary ml-1 text-white"
                      style={{backgroundColor: "#03356D"}}
                    >
                      close
                    </button>
                  </section>
                </form>
                {notification.success && (
                  <Snackbar
                    msg="Employee Testimonials updated successfully"
                    type="success"
                  />
                )}
                {notification.fail && (
                  <Snackbar msg="Something went wrong" type="error" />
                )}
              </div>
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}
