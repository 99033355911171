import axios from "axios";
import { commonLister } from "../constants/constants";
const { LOADING, COMMON_LIST_SUCCESS, COMMON_LIST_FAIL } = commonLister;


const getCommonListRequest = () => {
  return {
    type: LOADING,
  };
};

const getCommonListSuccess = (list) => {
  return {
    type: COMMON_LIST_SUCCESS,
    payload: list,
  };
};
const getCommonListFail = (error) => {
  return {
    type: COMMON_LIST_FAIL ,
    payload: error,
  };
};

export function getCommonListAction(url) {
  return (dispatch) => {
    dispatch(getCommonListRequest());
    try {
      axios
        .get(url)
        .then((res) => dispatch(getCommonListSuccess(res.data)))
        .catch((err) => dispatch(getCommonListFail(err)));
    } catch (error) {
      dispatch(getCommonListFail(error));
    }
  };
}
