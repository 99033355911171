import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateNavbarHeading } from "../../../redux/actions/uiActions";
import "./Buddies.css";
import AddBuddy from "./BuddiesModel";
import { getBuddiesAction } from "../../../redux/actions/getBuddiesList";
import { columns } from "./columns";
import PaginationTable from "../../adminUi/PaginationTable";
// import Loader from "../../../Components/uiComponets/loader/Loader";
import { Loader } from "../../../loader/Loader";
import EditModal from "./BuddiesEdit";
import { NavItem } from "reactstrap";
import DeleteModal from "./Buddiesdelete";

const Buddies = () => {
  const dispatch = useDispatch();
  
  const [loader, setLoader] = useState(false);
  const { loading, buddies, error } = useSelector(
    (state) => state.getBuddiesReducer
  );
  useEffect(() => {
    dispatch(updateNavbarHeading("Buddies"));
    dispatch(getBuddiesAction());
  }, []);

  let data =
    buddies.length > 0 &&
    buddies.map((val, idx) => {
      const ImageObj = new Image();
      ImageObj.src = val.photo;
      return {
        idx: idx + 1,
        photo: (
          <img
          style={{ width: "2rem", height: "2rem" }}
          src={`data:image/png;base64,${val.photo}`}
          alt=""
          />
          ),
          name: val.name,
          department: val.department,
          email: val.email,
          phone: val.phone,
          detail: val.detail,
          region: val.region,
          action: <EditModal id={val.id} />,
          action2: <DeleteModal id={val.id} />,
        };
      });
  //     if (loading) {
  //   return (
  //     <div className="loader-wrapper">
  //       <Loader />
  //     </div>
  //   );
  // }
  if (loader || loading)
    return (
        <Loader open="true" />
    );
  return (
    <>
      <div className="content">
        <div className="content-wrapper">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12">
                <div>
                  <div className="card-header border-0">
                    <div className="d-flex justify-content-between">
                      <form className="form-inline ml-3">
                        {/* <div className="input-group input-group-sm">
                          <input
                            className="form-control form-control-navbar"
                            type="search"
                            placeholder="Search..."
                            aria-label="Search"
                          />
                          <div className="input-group-append Append">
                            <button className="btn btn-navbar" type="submit">
                              <i className="fas fa-search" />
                            </button>
                          </div>
                        </div> */}
                      </form>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12">
                      {buddies.length > 0 && (
                        <PaginationTable columnsArr={columns} dataArr={data} />
                      )}
                    </div>
                  </div>
                  <div className="submmit">
                    <button type="Submit" class="btn6 btn-primary m-3">
                      <AddBuddy />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Buddies;
