import axios from "axios";
import { benefitList } from "../constants/constants";
const { LOADING, BENEFIT_LIST_SUCCESS, BENEFIT_LIST_FAIL } = benefitList;

let url = `${process.env.REACT_APP_DEV}/api/getCompanyBenefitList`;

export const getBenefitList = () => {
  return {
    type: LOADING,
  };
};

export const getBenefitListSuccess = (list) => {
  return {
    type: BENEFIT_LIST_SUCCESS,
    payload: list,
  };
};

export const getBenefitListFail = (error) => {
  return {
    type: BENEFIT_LIST_FAIL,
    payload: error,
  };
};

export function getBenefitListAction(formData) {
  return (dispatch) => {
    dispatch(getBenefitList());
    try {
      axios
        .post(url, formData)
        .then((res) => {
          dispatch(getBenefitListSuccess(res.data));
        })
        .catch((err) => dispatch(getBenefitListFail(err)));
    } catch (error) {
      dispatch(getBenefitListFail(error));
    }
  };
}
