import { getBuddies } from "../constants/constants";
const { LOADING, BUDDIES_LIST_SUCCESS, BUDDIES_LIST_FAIL } = getBuddies;

const intialState = {
  loading: null,
  buddies: [],
  error: "",
};

export const getBuddiesReducer = (state = intialState, action) => {
  switch (action.type) {
    case LOADING:
      return {
        ...state,
        loading: true,
      };
    case BUDDIES_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        buddies: action.payload,
      };
    case BUDDIES_LIST_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};