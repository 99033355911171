import "./AssignProcess.css";
import { useDispatch, useSelector } from "react-redux";
import { updateNavbarHeading } from "../../../redux/actions/uiActions";
import { useEffect, useState } from "react";
import Skeleton from "../../adminUi/Skeleton";
import { getAssignProceessAction } from "../../../redux/actions/getAssignProcess";
import AssignProcessCard from "./AssignProcessCard";
import { Loader } from "../../../loader/Loader";

const AssignProcess = () => {
  const dispatch = useDispatch();
  
  const [loader, setLoader] = useState(false);
  const { loading, processList, error } = useSelector(
    (state) => state.getAssignProcessReducer
  );
  const [allList, setAllList] = useState(processList);
  useEffect(() => {
    dispatch(updateNavbarHeading("Assign Process"));
    dispatch(getAssignProceessAction());
  }, []);
  useEffect(() => {
    setAllList(processList)

  }, [processList]);


  useEffect(()=>{
    setAllList(processList)

  },[processList])

  // if (loading) {
  //   return <div> <h5 style={{textAlign:"center" ,color:"gray"}}>Loading...</h5> </div>;
  // }

  const onChangeSearch = (e) => {
    const obj = processList.filter((li) => li.adminId.includes(e.target.value));
    setAllList(
      obj
    );

    console.log("proccc", processList);
  };

  if (loader || loading)
    return (
        <Loader open="true" />
    );
  return (
    <>
      <Skeleton>
        <div className="form-group d-flex align-items-center" title="Enter User Id ">
          <strong>Search:</strong>
          <input
            className="form-control w-25 ml-2"
            onChange={onChangeSearch}
            placeholder="search..."
            name="search"
          />
        </div>
        <div className="row">
          {allList.length > 0 &&
            allList.map((li) => (
              <div className="col-lg-4">
                <AssignProcessCard item={li} />
              </div>
            ))}
        </div>
      </Skeleton>
    </>
  );
};

export default AssignProcess;
