import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateNavbarHeading } from "../../../redux/actions/uiActions";
import "./Key_spocs.css";
import AddKey from "./KeySpocsModel";
// import Loader from "../../../Components/uiComponets/loader/Loader";
import { Loader } from "../../../loader/Loader";
import PaginationTable from "../../adminUi/PaginationTable";
import { columns } from "../buddies/columns";
import { getSpocksAction } from "../../../redux/actions/getBuddiesList";
import { MdModeEdit } from "react-icons/md";
import EditModal from "./KeySpocksEditModal";
import DeleteModal from "./key_spocs_delete";

const Key_spocs = () => {
  const dispatch = useDispatch();
  
  const [loader, setLoader] = useState(false);
  const { loading, buddies, error } = useSelector(
    (state) => state.getBuddiesReducer
  );
  useEffect(() => {
    dispatch(updateNavbarHeading("Key Spocks"));
    dispatch(getSpocksAction());
  }, []);
  console.log(buddies);
  let data =
    buddies.length > 0 &&
    buddies.map((val, idx) => {
      const ImageObj = new Image();
      ImageObj.src = val.photo;
      return {
        idx: idx + 1,
        photo: (
          <img
            style={{ width: "2rem", height: "2rem" }}
            src={`data:image/png;base64,${val.photo}`}
            alt=""
          />
        ),
        name: val.name,
        department: val.department,
        email: val.email,
        phone: val.phone,
        detail: val.details,
        region: val.region,
        action: <EditModal id={val.id} />,
        action2: <DeleteModal id={val.id} />,
      };
    });
  // if (loading) {
  //   return (
  //     <div className="loader-wrapper">
  //       <Loader />
  //     </div>
  //   );
  // }

  if (loader || loading)
    return (
        <Loader open="true" />
    );
  return (
    <>
      <div className="content">
        <div className="content-wrapper">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12">
                <div>
                  <div className="card-header border-0">
                    <div className="d-flex justify-content-between">
                      <form className="form-inline ml-3">
                        {/* <div className="input-group input-group-sm">
                          <input
                            className="form-control form-control-navbar"
                            type="search"
                            placeholder="Search..."
                            aria-label="Search"
                          />
                          <div className="input-group-append Append">
                            <button className="btn btn-navbar" type="submit">
                              <i className="fas fa-search" />
                            </button>
                          </div>
                        </div> */}
                      </form>
                    </div>
                  </div>

                  {buddies.length > 0 && (
                    <PaginationTable columnsArr={columns} dataArr={data} />
                  )}

                  <div className="submmit">
                    <button type="Submit" class="btn6 btn-primary m-3">
                      <AddKey />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Key_spocs;
