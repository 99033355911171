import axios from "axios";
import { adminConstants } from "../constants/constants";
import { showErrorSnackbar, showSuccessSnackbar } from "../actions/uiActions";

const { LOADING, LOGIN_ADMIN_SUCCESS, LOGIN_ADMIN_FAIL, HR_ROLE_COMPANY } =
  adminConstants;

let url = `${process.env.REACT_APP_DEV}/api/adminLogin`;

const fetchAdminRequest = () => {
  return {
    type: LOADING,
  };
};

const fetchAdminSuccess = (users) => {
  return {
    type: LOGIN_ADMIN_SUCCESS,
    payload: users,
  };
};

const fetchAdminFailure = (error) => {
  return {
    type: LOGIN_ADMIN_FAIL,
    payload: error,
  };
};

export function adminLoginAction(username, password) {
  return (dispatch) => {
    dispatch(fetchAdminRequest());
    axios
      .post(url, { username, password })
      .then((res) => {
        if (res.data.type === "Admin") {
          dispatch(fetchAdminSuccess(res.data));
          dispatch(showSuccessSnackbar("Login Sucessfully", "success"));
          sessionStorage.setItem("admin", JSON.stringify(res.data));
          window.location.href = "../admin/dashboard";
        } else if (
          res.data.type === "Hr" ||
          res.data.type === "Recruiter" ||
          res.data.type === "Group HR" ||
          res.data.type === "Head HR"
        ) {
          sessionStorage.setItem("hr", JSON.stringify(res.data));
          axios
            .post(
              `${process.env.REACT_APP_DEV}/api/hr/getEntityForLoggedInHr`,
              {
                adminId: res?.data?.adminId,
              }
            )
            .then((res) => {
              localStorage.setItem("hrList", JSON.stringify(res?.data));
              dispatch(showSuccessSnackbar("Login Successfully", "success"));
              window.location.href = "../hr/dashboard";
            })
            .catch((err) => {
              dispatch(fetchAdminFailure(err?.response));
            });
        } else if (res?.data?.stage === "Pre-Offer") {
          sessionStorage.setItem("candidate", JSON.stringify(res?.data));
          window.location.href = `../candidate/${res?.data.applicantId}/preOffer`;
        } else if (res?.data?.stage === "Offer") {
          if (res?.data?.CompanyId === 156) {
            sessionStorage.setItem("candidate", JSON.stringify(res?.data));
            window.location.href = `../candidate/offer/seeds/offerLetter`;
          } else if (res?.data?.CompanyId === 157) {
            sessionStorage.setItem("candidate", JSON.stringify(res?.data));
            window.location.href = `../candidate/offer/agro/offerLetter`;
          } else if (res?.data?.CompanyId === 158) {
            sessionStorage.setItem("candidate", JSON.stringify(res?.data));
            window.location.href = `../candidate/offer/dairy/offerLetter`;
          } else if (res?.data?.CompanyId === 159) {
            sessionStorage.setItem("candidate", JSON.stringify(res?.data));
            window.location.href = `../candidate/offer/sjs/offerLetter`;
          }
        } else if (res?.data?.stage === "Pre-Onboarding") {
          sessionStorage.setItem("candidate", JSON.stringify(res?.data));
          window.location.href = `../candidate/preOnboard/welcome`;
        }
      })
      .catch((err) => {
        dispatch(fetchAdminFailure(err?.response?.data));
        let edata = err?.response?.data
        dispatch(showErrorSnackbar(`${edata?.error == "Wrong Credentials! Please try again." ? edata?.error : (edata?.error == "Account Deactivated! ") ? edata?.error : "Something went wrong"}`, "error"));
      });
  };
}
