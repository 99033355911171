import axios from "axios";
import { candidateList } from "../constants/constants";
const { SUCCESS, FAIL } = candidateList;

export const getHrCandidateListSuccess = (list) => {
  return {
    type: SUCCESS,
    payload: list,
  };
};
export const getHrCandidateListFail = (error) => {
  return {
    type: FAIL,
    payload: error,
  };
};

export const getHrRoleDataById = (adminId) => {
  return (dispatch) => {
    axios
      .post(`${process.env.REACT_APP_DEV}/api/getHrRoleDataById`, { adminId })
      .then((res) => dispatch(getHrCandidateListSuccess(res.data)))
      .catch((err) => dispatch(getHrCandidateListFail(err)));
  };
};
