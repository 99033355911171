import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import Snackbar from "../../../Components/uiComponets/snackbar/Snackbar";
import { getBuddiesAction } from "../../../redux/actions/getBuddiesList";
import { MdModeEdit } from "react-icons/md";
import {
  EditorState,
  convertToRaw,
  convertFromHTML,
  ContentState,
  htmlToDraft,
} from "draft-js";
import draftToHtml from "draftjs-to-html";
import { Editor } from "react-draft-wysiwyg";
import { editCompanyVision } from "../../adminMainServices/companyServices/companyServices";

import { Loader } from "../../../loader/Loader";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    width: "40rem",
  },
  card: {
    marginBottom: "0",
    width: "100%",
  },
}));

export default function TransitionsModal({ id }) {
  console.log(id, "visionid");
  const { companyList } = useSelector((state) => state.getCompanyListReducer);
  const classes = useStyles();
  const [notification, setNotification] = React.useState({
    success: null,
    fail: null,
  });
  const [open, setOpen] = React.useState(false);
  
  const [loader, setLoader] = useState(false);

  const { loading, error, details } = useSelector(
    (state) => state.getCompanyDetailsListReducer
  );
  const dispatch = useDispatch();
  const [about, setAbout] = React.useState({
    filePicture: "",
    companyName: "",
  });
  const [companyDetails, setCompanyDetails] = React.useState(null);
  const [content, setContent] = React.useState({
    editorStateOne: EditorState.createEmpty(),
    editorStateTwo: EditorState.createEmpty(),
  });
  const [editorValue, setEditorValue] = React.useState({
    editorOneValue: null,
    editorTwoValue: null,
  });
  const htmlDocs = (html, name) => {
    const blocksFromHTML = convertFromHTML(html);
    const state = ContentState.createFromBlockArray(
      blocksFromHTML.contentBlocks,
      blocksFromHTML.entityMap
    );
    
    return state;
  };
  useEffect(() => {
    setTimeout(() => {
      setNotification({ success: false, fail: false });
    }, 4000);
  }, [notification]);

  useEffect(() => {
    if (details.length > 0) {
      let temp = details.find((li) => li.id === id);

      console.log(temp);
      let data1 = temp?.vision
        ? htmlDocs(temp?.vision, "editorStateOne")
        : htmlDocs("<p>No data</p>");

      let data2 = temp?.vision1
        ? htmlDocs(temp?.vision1, "editorStateTwo")
        : htmlDocs("<p>No data</p>");
      setContent({
        editorStateOne: EditorState?.createWithContent(data1),
        editorStateTwo: EditorState?.createWithContent(data2),
      });
    }
  }, [id]);

  // useEffect(() => {
  //   if (details.length > 0) {
  //     let temp = details.find(
  //       (li) => li.companyLocationId === parseInt(about?.companyName)
  //     );
  //     setCompanyDetails(temp);
  //     let data1 = temp?.vision
  //       ? htmlDocs(temp?.vision, "editorStateOne")
  //       : htmlDocs("<p>No data</p>");
  //     let data2 = temp?.vision1
  //       ? htmlDocs(temp?.vision1, "editorStateOne")
  //       : htmlDocs("<p>No data</p>");
  //     setContent({
  //       editorStateOne: EditorState?.createWithContent(data1),
  //       editorStateTwo: EditorState?.createWithContent(data2),
  //     });
  //   }
  // }, [about.companyName]);

  const handleSubmit = async (e) => {
    setLoader(true);
    let editVision = {
      id: id,
      vision: editorValue.editorOneValue,
      vision1: editorValue.editorTwoValue,
    };
    const res = await editCompanyVision(editVision);
    if (res.status === 200) {
      setLoader(false);
      setNotification({ success: true, fail: false });
      window.location.reload();
    } else {
      setNotification({ success: false, fail: true });
    }
  };

  useEffect(() => {
    setEditorValue({
      ...editorValue,
      editorOneValue: draftToHtml(
        convertToRaw(content.editorStateOne.getCurrentContent())
      ),
    });
  }, [content.editorStateOne]);
  useEffect(() => {
    setEditorValue({
      ...editorValue,
      editorTwoValue: draftToHtml(
        convertToRaw(content.editorStateTwo.getCurrentContent())
      ),
    });
  }, [content.editorStateTwo]);

  const onEditorStateChangeOne = (editorState) => {
    setContent({
      ...content,
      editorStateOne: editorState,
    });
  };
  const onEditorStateChangeTwo = (editorState) => {
    setContent({
      ...content,
      editorStateTwo: editorState,
    });
  };
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  
  if (loader)
    return (
        <Loader open="true" />
    );
  return (
    <div>
      <MdModeEdit size={20} onClick={handleOpen} />
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <div className={`card ${classes.card}`} style={{overflow: "scroll", height:"30rem", }}>
              <div className="card-body">
                <div className="card-header">
                  <h3 className="card-title">Edit Vision</h3>
                </div>
                <form onSubmit={handleSubmit} style={{ width: "100%" }}>
                  <section className="d-flex justify-content-between">
                    <div className="form-group  flex-column">
                      <div className="card-header">
                        <h3 className="card-title bold-card-header ">
                          Mission
                        </h3>
                      </div>
                      <Editor
                        editorState={content.editorStateOne}
                        toolbarClassName="toolbarClassName"
                        wrapperClassName="wrapperClassName"
                        editorClassName="editorClassName"
                        onEditorStateChange={onEditorStateChangeOne}
                      />
                    </div>
                  </section>
                  <section className="d-flex justify-content-between">
                    <div className="form-group  flex-column">
                      <div className="card-header">
                        <h3 className="card-title bold-card-header ">
                          Vision
                        </h3>
                      </div>
                      <div className="card-body">
                        <Editor
                          editorState={content.editorStateTwo}
                          toolbarClassName="toolbarClassName"
                          wrapperClassName="wrapperClassName"
                          editorClassName="editorClassName"
                          onEditorStateChange={onEditorStateChangeTwo}
                        />
                      </div>
                    </div>
                  </section>

                  <section className="text-right d-flex ">
                    <button
                      type="submit"
                      className="btn btn-sm btn-primary text-white"
                      style={{backgroundColor: "#03356D"}}
                      >
                      Save
                    </button>
                    <button
                      onClick={handleClose}
                      type="submit"
                      className="btn btn-sm btn-primary ml-1 text-white"
                      style={{backgroundColor: "#03356D"}}
                    >
                      close
                    </button>
                  </section>
                </form>
                {notification.success && (
                  <Snackbar msg="Vision updated successfully" type="success" />
                )}
                {notification.fail && (
                  <Snackbar msg="Something went wrong" type="error" />
                )}
              </div>
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}
