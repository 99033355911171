import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import Snackbar from "../../Components/uiComponets/snackbar/Snackbar";
import { MdModeEdit } from "react-icons/md";
import draftToHtml from "draftjs-to-html";
import "./companyAbout.css";
import { EditorState, convertToRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import { editCompanyAbout } from "../adminMainServices/companyServices/companyServices";
import { useSelector } from "react-redux";
import { htmlDocs } from "./draftToHtml";
import { height } from "@mui/system";
import { Loader } from "../../loader/Loader";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    width: "fit-content",
  },
  card: {
    marginBottom: "0",
    width: "100%",
  },
}));

export default function TransitionsModal({ id }) {
  const [open, setOpen] = useState(false);
  
  const [loader, setLoader] = useState(false);
  const [notification, setNotification] = React.useState({
    success: null,
    fail: null,
  });
  const classes = useStyles();
  const [content, setContent] = React.useState({
    editorStateOne: EditorState.createEmpty(),
    editorStateTwo: EditorState.createEmpty(),
    editorStateThree: EditorState.createEmpty(),
  });
  const [editorValue, setEditorValue] = React.useState({
    editorOneValue: null,
    editorTwoValue: null,
    editorThreeValue: null,
  });
  const [editorLengthValidation, setEditorLengthValidation] = useState({
    aboutUs: null,
    aboutUsOneLength: null,
    aboutUsTwoLength: null,
    aboutUsThreeLength: null,
  });

  const { details } = useSelector(
    (state) => state.getCompanyDetailsListReducer
  );

  useEffect(() => {
    setTimeout(() => {
      setNotification({ success: false, fail: false });
    }, 4000);
  }, [notification]);

  useEffect(() => {
    if (details.length > 0) {
      let temp = details.find((li) => li.id === id);

      console.log(temp);
      let data1 = temp?.aboutUs
        ? htmlDocs(temp?.aboutUs, "editorStateOne")
        : htmlDocs("<p>No data</p>");

      let data2 = temp?.aboutUs2
        ? htmlDocs(temp?.aboutUs2, "editorStateTwo")
        : htmlDocs("<p>No data</p>");
      let data3 = temp?.aboutUs3
        ? htmlDocs(temp?.aboutUs3, "editorStateThree")
        : htmlDocs("<p>No data</p>");

      setContent({
        editorStateOne: EditorState?.createWithContent(data1),
        editorStateTwo: EditorState?.createWithContent(data2),
        editorStateThree: EditorState?.createWithContent(data3),
      });
    }
  }, [id]);

  //About us 1 Editor Validation for 500 words
  const editorTextOne = convertToRaw(content.editorStateOne.getCurrentContent())
    .blocks.map((rowText) => {
      return rowText.text;
    })
    .join("");

  //About us 2 Editor Validation for 500 words
  const editorTextTwo = convertToRaw(content.editorStateTwo.getCurrentContent())
    .blocks.map((rowText) => {
      return rowText.text;
    })
    .join("");

  //About us 3 Editor Validation for 500 words
  const editorTextThree = convertToRaw(
    content.editorStateThree.getCurrentContent()
  )
    .blocks.map((rowText) => {
      return rowText.text;
    })
    .join("");

  const handleSubmit = async (e) => {
    setLoader(true);
    e.preventDefault();
    
    if (
      editorLengthValidation.aboutUsOneLength ||
      editorLengthValidation.aboutUsTwoLength ||
      editorLengthValidation.aboutUsThreeLength
      ) {
        return;
      }
      if (editorTextOne.length < 1) {
        setEditorLengthValidation({ aboutUs: true });
        return;
      }
      
      let data = {
      id,
      aboutUs: editorValue.editorOneValue,
      aboutUs2: editorValue.editorTwoValue,
      aboutUs3: editorValue.editorThreeValue,
    };
    // console.log(data);
    const res = await editCompanyAbout(data);
    if (res.status === 200) {
      setLoader(false);
      setNotification({ success: true, fail: false });
      window.location.reload();
    } else {
      setLoader(false);
      setNotification({ success: false, fail: true });
    }
  };


  useEffect(() => {
    
    setEditorValue({
      ...editorValue,
      editorOneValue: draftToHtml(convertToRaw(content?.editorStateOne?.getCurrentContent()) ),
      editorTwoValue: draftToHtml(convertToRaw(content?.editorStateTwo?.getCurrentContent()) ),
      editorThreeValue: draftToHtml(convertToRaw(content?.editorStateThree?.getCurrentContent()) ),
    });

    if (editorTextOne.length > 500) {
      setEditorLengthValidation({ aboutUsOneLength: true });
    } else {
      setEditorLengthValidation({  aboutUsOneLength: false, });
    }
    if (editorTextTwo.length > 500) {
      setEditorLengthValidation({ aboutUsTwoLength: true });
    } else {
      setEditorLengthValidation({ aboutUsTwoLength: false });
    }
    if (editorTextThree.length > 500) {
      setEditorLengthValidation({ aboutUsThreeLength: true });
    } else {
      setEditorLengthValidation({ aboutUsThreeLength: false });
    }
    
  }, [content]);

  const onEditorStateChangeOne = (editorState) => {
    setContent({
      ...content,
      editorStateOne: editorState,
    });
  };
  const onEditorStateChangeTwo = (editorState) => {
    setContent({
      ...content,
      editorStateTwo: editorState,
    });
  };
  const onEditorStateChangeThree = (editorState) => {
    setContent({
      ...content,
      editorStateThree: editorState,
    });
  };
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  if (loader)
    return (
        <Loader open="true" />
    );
  return (
    <div className="editmodal">
      <MdModeEdit size={20} onClick={handleOpen} />
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <div className={`card ${classes.card}`} style={{overflow: "scroll", height:"40rem", width:"70rem"}}>
              <div className="card-body">
                <div className="card-header">
                  <h3 className="card-title">Edit Company About</h3>
                </div>
                <section style={{ width: "100%" }}>
                  {editorLengthValidation.aboutUsOneLength && (
                    <p style={{ color: "red" }}>Please Enter 500 words only</p>
                  )}
                  {editorLengthValidation.aboutUs && (
                    <p style={{ color: "red" }}>This is editor is mandatory</p>
                  )}
                  <section className="d-flex justify-content-between">
                    <div className="card editor-card">
                      <div className="card-header">
                        <h3 className="card-title bold-card-header ">
                          About Us 1
                        </h3>
                      </div>
                      <div className="card-body">
                        <Editor
                          editorState={content.editorStateOne}
                          toolbarClassName="toolbarClassName"
                          wrapperClassName="wrapperClassName"
                          editorClassName="editorClassName"
                          onEditorStateChange={onEditorStateChangeOne}
                        />
                      </div>
                    </div>
                  </section>
                  {editorLengthValidation.aboutUsTwoLength && (
                    <p>Please Enter 500 words only</p>
                  )}
                  <section className="d-flex justify-content-between">
                    <div className="card  editor-card">
                      <div className="card-header">
                        <h3 className="card-title bold-card-header ">
                          About Us 2
                        </h3>
                      </div>
                      <div className="card-body">
                        <Editor
                          editorState={content.editorStateTwo}
                          toolbarClassName="toolbarClassName"
                          wrapperClassName="wrapperClassName"
                          editorClassName="editorClassName"
                          onEditorStateChange={onEditorStateChangeTwo}
                        />
                      </div>
                    </div>
                  </section>
                  {editorLengthValidation.aboutUsThreeLength && (
                    <p style={{ color: "red" }}>Please Enter 500 words only</p>
                  )}
                  <section className="d-flex justify-content-between">
                    <div className="form-group d-flex flex-column">
                      <div className="card-header">
                        <h3 className="card-title bold-card-header ">
                          About Us 3
                        </h3>
                      </div>
                      <div className="card-body">
                        <Editor
                          editorState={content.editorStateThree}
                          toolbarClassName="toolbarClassName"
                          wrapperClassName="wrapperClassName"
                          editorClassName="editorClassName"
                          onEditorStateChange={onEditorStateChangeThree}
                        />
                      </div>
                    </div>
                  </section>

                  <section className="text-right d-flex ">
                    <button
                      type="button"
                      className="btn btn-sm btn-primary text-white"
                      onClick={handleSubmit}
                      style={{backgroundColor: "#03356D"}}
                      >
                      Save
                    </button>
                    <button
                      onClick={handleClose}
                      type="submit"
                      className="btn btn-sm btn-primary ml-1 text-white"
                      style={{backgroundColor: "#03356D"}}
                    >
                      close
                    </button>
                  </section>
                </section>
                {notification.success && (
                  <Snackbar msg="Company updated successfully" type="success" />
                )}
                {notification.fail && (
                  <Snackbar msg="Something went wrong" type="error" />
                )}
              </div>
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}
