import { React, useState } from "react";
import "./sidebar.css";
import updatedLogo from "../../Images/NamdhariLogo.png";
import { Link, NavLink } from "react-router-dom";
import Logo1 from "../../Images/logo1.svg";
import Employee from "../../Images/Employee.svg";
import Role from "../../Images/role.svg";
import createUser from "../../Images/createUser.svg";
import employeeTest from "../../Images/employeeTest.svg";
import aboutCompany from "../../Images/aboutCompany.svg";
import vision from "../../Images/vision.svg";
import dashboard from "../../Images/dashboard.svg";

function Sidebar() {
  return (
    <aside className="main-sidebar sidebar-dark-primary elevation-4 admin-sidebar">
      <NavLink to="/admin/dashboard" className="nav-link">
        <a href="index3.html" className="brand-link seed-link">
          <img
            style={{ width: "100%", height: "100%", }}
            src={updatedLogo}
            alt="logo"
          />
        </a>
      </NavLink>
      <div className="sidebar">
        <nav className="mt-2">
          <ul
            className="nav nav-pills nav-sidebar flex-column"
            data-widget="treeview"
            role="menu"
            data-accordion="false"
          >
            <li className="nav-item menu-open dashboard-column">
              <NavLink to="/admin/dashboard" className="nav-link active ">
                <img
                  src={dashboard}
                  className="mr-3 sidebar-icons-color"
                  alt=""
                />
                <p>Dashboard</p>
              </NavLink>
            </li>

            <li className="nav-item">
              <a href="#" className="nav-link">
                <i class="fas fa-briefcase mr-3 sidebar-icons-color"></i>
                <p>
                  Company Management
                  <i className="fas fa-angle-right right" />
                </p>
              </a>
              <ul className="nav nav-treeview" style={{ marginLeft: "-1.8rem" }}>
                <li className="nav-item sub-maga-links">
                  <NavLink to="/admin/companyManagement/companyAbout" activeClassName="activeLink" className="nav-link">
                    <img
                      src={aboutCompany}
                      alt=""
                      className="nav-icon p-1 sidebar-icons-color"
                    />
                    <p>About Company</p>
                  </NavLink>
                </li>
                <li className="nav-item sub-maga-links">
                  <NavLink to="/admin/companyManagement/companyValue" activeClassName="activeLink" className="nav-link">
                    <img
                      src={Logo1}
                      alt=""
                      className="nav-icon p-1 sidebar-icons-color"
                    />
                    <p>Values</p>
                  </NavLink>
                </li>
                <li className="nav-item sub-maga-links">
                  <NavLink to="/admin/companyManagement/companyVision" activeClassName="activeLink" className="nav-link">
                    <img
                      className="nav-icon p-1 sidebar-icons-color"
                      src={vision}
                      alt=""
                    />
                    <p>Vision</p>
                  </NavLink>
                </li>
                <li className="nav-item sub-maga-links">
                  <NavLink to="/admin/companyManagement/companyGallery" activeClassName="activeLink" className="nav-link">
                    <i class="nav-icon fas fa-images sidebar-icons-color"></i>
                    <p>Gallery</p>
                  </NavLink>
                </li>
                <li className="nav-item sub-maga-links">
                  <NavLink to="/admin/companyManagement/companyTour" activeClassName="activeLink" className="nav-link">
                    <i class="nav-icon fas fa-plane sidebar-icons-color"></i>
                    <p>Company Tour</p>
                  </NavLink>
                </li>
                <li className="nav-item sub-maga-links">
                  <NavLink to="/admin/companyManagement/companyLogo" activeClassName="activeLink" className="nav-link">
                    <i class="nav-icon fas fa-cube sidebar-icons-color"></i>
                    <p>Company Logo</p>
                  </NavLink>
                </li>
                <li className="nav-item sub-maga-links">
                  <NavLink to="/admin/companyManagement/companySocial" activeClassName="activeLink" className="nav-link">
                    <i class="nav-icon fas fa-user-plus sidebar-icons-color"></i>
                    <p>Company Social</p>
                  </NavLink>
                </li>
                <li className="nav-item sub-maga-links">
                  <NavLink to="/admin/companyManagement/companyLeaders" activeClassName="activeLink" className="nav-link">
                    <i class="nav-icon fas fa-user-tie sidebar-icons-color"></i>
                    <p>Company Leaders</p>
                  </NavLink>
                </li>
                {/* <li className="nav-item sub-maga-links">
                  <NavLink to="/admin/companyManagement/hotelAddress">
                    <i class="fas fa-map-marker-alt mr-2 sidebar-icons-color"></i>
                    <p>Hotel Address</p>
                  </NavLink>
                </li> */}
                <li className="nav-item sub-maga-links">
                  <NavLink to="/admin/companyManagement/companyHoliday" activeClassName="activeLink" className="nav-link">
                    <i class="nav-icon fas fa-calendar-day sidebar-icons-color"></i>
                    <p>Holiday Calender</p>
                  </NavLink>
                </li>
                <li className="nav-item sub-maga-links">
                  <NavLink to="/admin/companyManagement/companyNotification" activeClassName="activeLink" className="nav-link">
                    <i class="nav-icon fas fa-bell sidebar-icons-color"></i>
                    <p>Notifications</p>
                  </NavLink>
                </li>
              </ul>
            </li>
            <li className="nav-item">
              <a href="#" className="nav-link">
                <img className="mr-3" src={Employee} alt="" />
                <p>
                  Employee Management
                  <i className="right fas fa-angle-right" />
                </p>
              </a>
              <ul className="nav nav-treeview" style={{ marginLeft: "-1.8rem" }}>
                <li className="nav-item sub-maga-links">
                  <NavLink to="/admin/employeeManagement/employeeTestimonials" activeClassName="activeLink" className="nav-link">
                    <img
                      src={employeeTest}
                      alt="/"
                      className="nav-icon p-1 sidebar-icons-color"
                    />
                    <p>Employee Testimonials</p>
                  </NavLink>
                </li>
                <li className="nav-item sub-maga-links">
                  <NavLink to="/admin/employeeManagement/companyBuddies" activeClassName="activeLink" className="nav-link">
                    <i class="nav-icon fas fa-users sidebar-icons-color"></i>
                    <p>My Buddies</p>
                  </NavLink>
                </li>
                <li className="nav-item sub-maga-links">
                  <NavLink to="/admin/employeeManagement/companySpocks" activeClassName="activeLink" className="nav-link">
                    <i class="nav-icon fas fa-user-circle sidebar-icons-color"></i>
                    <p>My Key Spocs</p>
                  </NavLink>
                </li>
                <li className="nav-item sub-maga-links">
                  <NavLink to="/admin/employeeManagement/compliance" activeClassName="activeLink" className="nav-link">
                    <i class="nav-icon fas fa-list-alt  mr-2 sidebar-icons-color"></i>
                    <p>Compliance</p>
                  </NavLink>
                </li>
              </ul>
            </li>
            <li className="nav-item">
              <a href="#" className="nav-link">
                <img src={Role} alt="" className="mr-3" />
                <p>
                  Role Management
                  <i className="fas fa-angle-right right" />
                </p>
              </a>
              <ul className="nav nav-treeview" style={{ marginLeft: "-1.8rem" }}>
                <li className="nav-item sub-maga-links">
                  <NavLink to="/admin/roleManagement/createUser" activeClassName="activeLink" className="nav-link">
                    <img src={createUser} alt="" className="nav-icon p-1" />
                    <p>Create User</p>
                  </NavLink>
                </li>
                <li className="nav-item sub-maga-links">
                  <NavLink to="/admin/roleManagement/assignProcess" activeClassName="activeLink" className="nav-link">
                    <i class="nav-icon fas fa-recycle sidebar-icons-color"></i>
                    <p>Assign Process</p>
                  </NavLink>
                </li>
              </ul>
            </li>
          </ul>
        </nav>
        {/* /.sidebar-menu */}
      </div>
      {/* /.sidebar */}
    </aside>
  );
}

export default Sidebar;
