import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { useDispatch, useSelector } from "react-redux";
import { hrSelectedCompany } from "../../redux/actions/hrAccessCompanyList";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

export default function TransitionsModal({ open, handleOpen, handleClose }) {
  const [data, setdata] = React.useState({
    name: "",
    id: "",
    value: ""
  })
  const dispatch = useDispatch();
  const classes = useStyles();
  const { list, hrCompany } = useSelector(
    (state) => state.getHrAccessListReducer
  );
  const handleChange = (e) => {
    sessionStorage.setItem("company", JSON.stringify(e.target.value));
    dispatch(hrSelectedCompany(e.target.value));
    window.location.reload();
  };

  const tdata = JSON.parse(sessionStorage?.getItem("company"));
  React.useEffect(() => {
    if (list.length > 0) {
      let myarray = list.filter(x => x.id == tdata);
      myarray?.map((li) => {
        setdata({
          ...data,
          name: li.name,
          id: li.id,
          value: li.value
        })

      })

    }

  }, [tdata]);
  React.useEffect(() => {
    if (list.length > 0) {
      let myarray = list.filter(x => x.id == tdata);
      myarray?.map((li) => {
        setdata({
          ...data,
          name: li.name,
          id: li.id,
          value: li.value
        })

      })

    }

  }, [list]);
  sessionStorage.setItem("companySelect", JSON.stringify(data));

  return (
    <div>
      <span type="button" onClick={handleOpen}>
        <i class="fas fa-building"></i>
      </span>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <div className="card">
              <div className="card-header">
                <h3 className="card-title">Please select company !!!</h3>
              </div>
              <div className="card-body">
                <p>
                  Please select a company before proceeding to HR
                  modules.
                </p>
                <div className="form-group">
                  <label htmlFor="selectCompany">Select company</label>
                  <select
                    value={hrCompany}
                    className="form-control"
                    onChange={handleChange}

                  >
                    <option>select company</option>
                    {list?.map((li) => (
                      <option value={li.id} kye={li}>
                        {li.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}
