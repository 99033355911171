import React, { useEffect } from "react";
import "./NavbarHR.css";
import { useDispatch, useSelector } from "react-redux";
import Tooltip from "@material-ui/core/Tooltip";
import CompanyModel from "./HrCompanyModel";
import { getStorageData } from "../hrServices/getStorageData";
import { ClickAwayListener, withStyles } from "@material-ui/core";
import { getRolesAction } from "../../redux/actions/getRoles";

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "white",
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
  },
}))(Tooltip);

function NavbarHR() {
  const dispatch = useDispatch();

  const state = useSelector((state) => state.updateNavbarHeadingReducer);
  const createUserData = useSelector((state) => state.getHrRolesReducer);
  const { adminId, companyId } = getStorageData();
  const { navbarHeading } = state;
  const [open, setOpen] = React.useState(false);
  const [tOpen, setTpen] = React.useState(false);

  useEffect(() => {
    dispatch(getRolesAction());
    if (isNaN(companyId)) {
      return setOpen(true);
    }
  }, []);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleTooltipClose = () => {
    setTpen(false);
  };
  const handleTooltipOpen = () => {
    setTpen(true);
  };

  // tooltip  card function
  const UserProfile = createUserData.roleList.map((role, idx) => {
    if (role.id == adminId) {
      return (
        <div className="user-profile">
          <p className="details__id">
            Name : <span className="details__title">{role.name} </span>
          </p>
          <p className="details__id">
            Role : <span className="details__title"></span> {role.type}
          </p>
        </div>
      );
    }
  });

  const handleLogout = () => {
    sessionStorage.removeItem("hr");
    sessionStorage.removeItem("company");
    localStorage.removeItem("hrList");
    window.location.href = "/";
  };
  return (
    <section className="green-back">
      <nav className="main-header navbar navbar-expand navbar-white navbar-light top-nav-toggle">
        <ul className="navbar-nav ml-4">
          <li className="nav-item">
            <a
              className="nav-link"
              data-widget="pushmenu"
              href="#"
              role="button"
            >
              <i style={{ marginTop: "6px" }} className="fas fa-bars" />
            </a>
          </li>
          <li className="nav-item d-none d-sm-inline-block">
            <a href="#" className="nav-link about-company-text">
              {navbarHeading}
            </a>
          </li>
        </ul>

        <ul className="navbar-nav ml-auto">
          <div className="navbar__right-elements">
            <ClickAwayListener onClickAway={handleTooltipClose}>
              <LightTooltip
                title={UserProfile}
                // placement="bottom-start"
                onClose={handleTooltipClose}
                open={tOpen}
                // TransitionComponent={Zoom}
                disableFocusListener
                disableHoverListener
                // disableTouchListener
                arrow
              >
                <img
                  src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTPyGNr2qL63Sfugk2Z1-KBEwMGOfycBribew&usqp=CAU"
                  alt="avatar"
                  className="img-circle elevation-2 avatar"
                  onClick={handleTooltipOpen}
                />
              </LightTooltip>
            </ClickAwayListener>
          </div>

          <Tooltip title="Select Company" placement="top" arrow>
            <li className="nav-item dropdown fa-lg" style={{ color: "gray" }}>
              <span className="nav-link" style={{
                display: "flex",
                alignItems: "center"}}>
                <CompanyModel
                  open={open}
                  handleClose={handleClose}
                  handleOpen={handleOpen}
                />
              </span>
            </li>
          </Tooltip>

          <li
            onClick={handleLogout}
            className="nav-item dropdown"
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Tooltip title="Log Out" placement="top" arrow>
              <div
                style={{
                  backgroundColor: "#FFFFFF",
                  borderRadius: "1rem",
                  height: "28px",
                  minHeight: "28px",
                  minWidth: "28px",
                  width: "28px",
                  marginRight: "1rem",
                  // marginTop: "0.3rem"
                }}
              >
                <i
                  style={{
                    marginTop: "8px",
                    marginLeft: "5px",
                    color: "gray",
                  }}
                  className="fa fa-power-off fa-lg c-pointer"
                ></i>
              </div>
            </Tooltip>
          </li>
        </ul>
      </nav>
    </section>
  );
}

export default NavbarHR;
