import "./Header.css";
import { useEffect, useState } from 'react'
import { getCandidateStorageData } from "../candidates/candidateServices/preOffer/getCandidateStorage";
import { useSelector, useDispatch } from "react-redux";
import { getCommonListAction } from "../redux/actions/getCommonList";
import { useHistory } from "react-router";
import { playVideo } from "../admin/adminUi/adminServices/admin.services";
import Tooltip from "@material-ui/core/Tooltip";
import { candidateDetailsStorage } from "../candidates/PreOnBoardCandidates/Pre-onboarding/utils/candidateDetailsStorage";

import { ClickAwayListener, withStyles } from "@material-ui/core";

let url = `${process.env.REACT_APP_DEV}/api/tour/getLogo`;



const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: 'white',
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
  },
}))(Tooltip);

const Header = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const [candidatePic, setCandidatePic] = useState(null);
  const { CompanyId, applicantId } = getCandidateStorageData();
  const { list } = useSelector(
    (state) => state.getCommonListReducer
  );

  const data = candidateDetailsStorage();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    getPic();
    dispatch(getCommonListAction(url));

  }, []);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  const handleLogout = () => {
    sessionStorage.removeItem("candidate");
    sessionStorage.removeItem("candidateDetails");
    window.location.href = "/";
    sessionStorage.clear();
  };
  const { stage } = JSON.parse(sessionStorage?.getItem("candidate"));

  const handleRoute = () => {
    if (stage === "Pre-Onboarding") {
      history.push("/candidate/preOnboard/dashboard");
    }
  }


  //CANDIDATE PIC LOGIC
  const getPic = async () => {
    const res = await playVideo(data?.hrCandidateData?.photo);
    if (res?.status === 200) {
      setCandidatePic(res?.data);
    }
  };



  let myarray = list.filter((x) => x.compayId === CompanyId);
  const UserProfile = (
    <div className="user-profile">
      <p className="details__id">Applicant_ID: <span className="details__title">{applicantId} </span></p>
      <p className="details__id"> Stage: <span className="details__title"></span> {stage}</p>
    </div>
  );

  return (
    <>
      <nav className="navbar header-color">
        <div className="container-fluid">
          <div>
            <a className="navbar-brand header-brand" href="#">
              <img
                className="d-inline-block align-text-top"
                src={`data:image/png;base64,${myarray[0]?.logo}`}
                alt=""
                onClick={handleRoute}
                style={{ width: "5.5rem" }}
              ></img>
            </a>
          </div>

          <div className="navbar__right-elements">
            <ClickAwayListener onClickAway={handleTooltipClose}>
              <LightTooltip
                title={UserProfile}
                // placement="bottom-start"
                onClose={handleTooltipClose}
                open={open}
                // TransitionComponent={Zoom}
                disableFocusListener
                disableHoverListener
                // disableTouchListener
                arrow
              >
                {
                  candidatePic ? (<img
                    src={URL.createObjectURL(candidatePic)}
                    alt="avatar"
                    className="img-circle elevation-2 avatar"
                    onClick={handleTooltipOpen}
                  />) : (<img
                    src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTPyGNr2qL63Sfugk2Z1-KBEwMGOfycBribew&usqp=CAU"
                    alt="avatar"
                    className="img-circle elevation-2 avatar"
                    onClick={handleTooltipOpen}
                  />)
                }
              </LightTooltip>
            </ClickAwayListener>
            <div onClick={handleLogout} className="c-pointer">
              <Tooltip title="Log Out" placement="top" arrow>
                <div
                  style={{
                    backgroundColor: "#FFFFFF",
                    borderRadius: "1rem",
                    height: "28px",
                    minHeight: "28px",
                    minWidth: "28px",
                    width: "28px",
                    marginRight: "0.5rem",
                  }}
                >
                  <i className="fa fa-power-off header-icon c-pointer fa-lg" style={{ color: "gray" }}></i>
                </div>
              </Tooltip>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Header;


