import { createRole } from "../constants/constants";
const { LOADING, CREATE_USER_SUCCESS, CREATE_USER_FAIL } = createRole;

const intialState = {
  loading: null,
  user: {},
  error: "",
};

export const createRoleReducer = (state = intialState, action) => {
  switch (action.type) {
    case LOADING:
      return {
        ...state,
        loading: true,
      };
    case CREATE_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        user: action.payload,
      };
    case CREATE_USER_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};
