import React from "react";
import "./navbar.css";
import { useSelector } from "react-redux";
import Tooltip from "@material-ui/core/Tooltip";

function Navbar() {
  const state = useSelector((state) => state.updateNavbarHeadingReducer);

  const { navbarHeading } = state;
  const handleLogout = () => {
    localStorage.removeItem("admin");
    sessionStorage.removeItem("admin")
    window.location.href = "/";
  };
  return (
    <section className="green-back">
      <nav className="main-header navbar navbar-expand navbar-white navbar-light top-nav-toggle">
        <ul className="navbar-nav">
          <li className="nav-item">
            <a
              className="nav-link"
              data-widget="pushmenu"
              href="#"
              role="button"
            >
              <i style={{ marginTop: "6px" }} className="fas fa-bars" />
            </a>
          </li>
          <li className="nav-item d-none d-sm-inline-block">
            <a href="" className="nav-link about-company-text">
              {navbarHeading}
            </a>
          </li>
        </ul>

        <ul className="navbar-nav ml-auto">
          <li className="nav-item list-containing-input">
            <div className="input-group input-group-sm">
              {/* <input
                className="toggle-navbar-search"
                type="search"
                placeholder="Search"
                aria-label="Search"
              /> */}
            </div>
          </li>
          {/* <li className="nav-item dropdown">
            <a className="nav-link" data-toggle="dropdown" href="#">
              <i class="fas fa-download"></i>
            </a>
          </li> */}
          <li onClick={handleLogout} className="nav-item dropdown">
            <Tooltip title="Log Out" placement="top" arrow>
              <div style={{
                backgroundColor: "#FFFFFF", borderRadius: "1rem", height: "28px",
                minHeight: "28px",
                minWidth: "28px",
                width: "28px",
                marginRight: "1rem",
              }}>
                <i style={{
                  marginTop: "8px",
                  marginLeft: "5px",
                  color: "gray"
                }} className="fa fa-power-off fa-lg c-pointer"></i>

              </div>
            </Tooltip>
          </li>
        </ul>
      </nav>
    </section>
  );
}

export default Navbar;
