import fi from "date-fns/esm/locale/fi/index.js";

const Validation = (values) => {
  let errors = {};
  let date = new Date(values.birthdate);
  let selectedDate = date.getTime();

  console.log('aa', values)

  if (values.title === "Title") {
    errors.title = "Title is required.";
  }
  if (!values.firstName) {
    errors.firstname = "First name is required.";
  }
  if (!values.lastName) {
    errors.lastname = "Last name is required";
  }
  if (selectedDate > new Date().getTime()) {
    errors.dob = "Please enter the valid DOB";
  } else if (!selectedDate) {
    errors.dob = "DOB is required.";
  }

  if (values.gender === "Gender") {
    errors.gender = "Gender is required.";
  }
  if (!values.mobilePhone) {
    errors.cellnumber = "Cell Number is required.";
  } else if (values.mobilePhone.length != 10) {
    errors.cellnumber = "Please enter valid cell number.";
  } else if (isNaN(values.mobilePhone)) {
    errors.cellnumber = "Please enter valid cell number.";
  }
  if (values.experienceOrFresher === "Experience") {
    errors.experience = "Experience is required.";
  }
  if (!values.addressLine1Permanent) {
    errors.address1 = "Address detail is required.";
  }
  if (!values.addressLine2Permanent) {
    errors.address2 = "Address detail is required.";
  }
  if (values.country === "Country") {
    errors.country = "Country is required.";
  }
  if (!values.cityPermanent) {
    errors.city = "City is required.";
  }
  if (!values.postalCodePermanent) {
    errors.postcode = "Post Code is required.";
  } else if (values.postalCodePermanent.length != 6) {
    errors.postcode = "Please enter the valid postcode.";
  } else if (isNaN(values.postalCodePermanent)) {
    errors.postcode = "Please enter the valid postcode.";
  }
  if (!values.mobilePhone) {
    errors.contact = "Contact Number is required.";
  } else if (values.mobilePhone.length != 10) {
    errors.contact = "Please enter valid contact number.";
  } else if (isNaN(values.mobilePhone)) {
    values.mobilePhone = "Please enter valid contact number.";
  }
  if (!values.facebook) {
    errors.facebook = "Facebook Link is required .";
  }

  if (!values.twitter) {
    errors.twitter = "Twitter Link is required .";
  }
  if (!values.linkedin) {
    errors.linkedin = "LinkedIn Link is required .";
  }
  if (!values.youtube) {
    errors.youtube = "YouTube Link is required .";
  }
  if (!values.hotelName) {
    errors.hotelName = "Hotel Name is required.";
  }
  if (!values.hotelAddress) {
    errors.hotelAddress = "Hotel Address is required.";
  }
  if (!values.hotelAddress2) {
    errors.hotelAddress2 = "Hotel Address is required.";
  }
  if (!values.image || values.image === '') {
    errors.image = "Image is required.";
  }

  console.log('aa 22', errors)


  return errors;
};
//isMandatory logic
export function isMandatory(fieldArr, obj) {
  let str = obj[fieldArr]
  if (str == "" || str == undefined || str == null) {
    return true;
  }
  else {
    return false;
  }
}


// Alphabetical Logic 
export function isAlphabetical(value) {
  if (/^[A-Za-z\s]*$/.test(value)) {
    return true;
  }
  else {
    return false;
  }
}
// Numeric  Logic 
export function isNumeric(value) {
  if (/^[0-9\s]*$/.test(value)) {
    return true;
  }
  else {
    return false;
  }


}
export default { Validation, isMandatory, isAlphabetical, isNumeric }
