import React, { useEffect, useState } from "react";
import "./CreateUser.css";
import { useDispatch, useSelector } from "react-redux";
import { updateNavbarHeading } from "../../../redux/actions/uiActions";
import {
  createUserRequest,
  createUserSuccess,
  createUserFail,
} from "../../../redux/actions/createUser";
import { getRolesAction } from "../../../redux/actions/getRoles";
import PaginationTable from "../../adminUi/PaginationTable";
import axios from "axios";
import Snackbar from "../../../Components/uiComponets/snackbar/Snackbar";
import { showErrorSnackbar, showSuccessSnackbar, } from "../../../redux/actions/uiActions";
import Select from 'react-select';
import { Loader } from "../../../loader/Loader";



function CreateUser() {

  const dispatch = useDispatch();
  const state = useSelector((state) => state.getHrRolesReducer);
  const [sSelect, setSelect] = useState([]);
  
  const [loader, setLoader] = useState(false);
  const [newRole, setNewRole] = useState({
    name: "",
    email: "",
    userId: "",
    password: "",
    entity: sSelect,
    type: "",
  });
  const [notification, setNotification] = useState({
    success: null,
    fail: null,
  });
  const optionData = [
    { value: 'SEEDS', label: 'Namdhari Seeds Pvt.Ltd' },
    { value: 'AGRO', label: 'Namdhari Agro Fresh' },
    { value: 'ANIMAL', label: ' Namdhari Dairy Tales' },
    { value: 'HEALTHCARE', label: ' SJS Health Care Ltd.' },

  ];

  useEffect(() => {
    setNotification({ fail: false, success: false });
    dispatch(updateNavbarHeading("Create User"));
    dispatch(getRolesAction());
  }, [dispatch]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "name") {
      setNewRole({
        ...newRole,
        [e.target.name]: e.target.value.replace(/[^ ^A-Za-z]/gi, "")
      })
    }
    else {
      setNewRole({ ...newRole, [name]: value });
    }
  };
  const handleCompany = (e) => {
    setSelect(Array.isArray(e) ? e.map(x => x.value) : [])
  }
  const handleSubmit = (e) => {
    setLoader(true);
    e.preventDefault();
    if (sSelect?.length == 0) {
      dispatch(showErrorSnackbar("Please select atleast one Company", "error"));
    } else {
      dispatch(createUserRequest());
      let url = `${process.env.REACT_APP_DEV}/api/admin/createNewUserHR`;
      let data = {
        name: newRole?.name,
        email: newRole?.email,
        userId: newRole?.userId,
        password: newRole?.password,
        entity: sSelect,
        type: newRole?.type,
      };
      axios
      .post(url, data)
      .then((res) => {
        if (res?.status === 200) {
          setLoader(false);
          dispatch(createUserSuccess(res.data));
          setNewRole({
            name: "",
            email: "",
            userId: "",
            password: "",
            type: "",
          });
          dispatch(getRolesAction());
          dispatch(showSuccessSnackbar(res?.data?.message, "success"));
          
        }
        
      })
      .catch((err) => {
          setLoader(false);
          setNewRole({
            name: "",
            email: "",
            userId: "",
            password: "",
            type: "",
          });
          dispatch(createUserFail(err));
          setNotification({ success: false, fail: true });
        });

    }
  };
  const columns = [
    {
      Header: "S.No.",
      accessor: "idx",
    },
    {
      Header: "Role Name",
      accessor: "roleName",
    },
    {
      Header: "User Name",
      accessor: "userName",
    },
    {
      Header: "Email id",
      accessor: "email",
    },
    {
      Header: "User id",
      accessor: "userId",
    },
  ];
  const data =
    state.roleList.length > 0 &&
    state.roleList.map((role, idx) => {
      return {
        idx: idx + 1,
        roleName: role.type || "No data found",
        userName: role.name || "No data found",
        email: role.email || "No data found",
        userId: role.adminId || "No data found",
        action: "edit",
      };
    });
  
    if (loader)
    return (
        <Loader open="true" />
    );
    return (
    <>
      <div className="content">
        <div className="content-wrapper">
          <div className="container-fluid">
            <div className="table-responsive mb-3" style={{ height: "17rem" }}>
              <div className="create-user-margin p-3 mb-4">
                <form onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col-md-6 mb-3">
                      <span className="input-labels">
                        Types Of Role{" "}
                        <sup className="fa fa-asterisk asterisk"></sup>
                      </span>
                      <select
                        className="form-control"
                        aria-label=".form-select-sm example"
                        name="type"
                        onChange={handleChange}
                        value={newRole.type}
                        required
                      >
                        <option selected disabled value="">
                          Select role
                        </option>
                        <option value="Recruiter">Recruiter</option>
                        <option value="Hr">HR</option>
                        <option value="Group HR">Group HR</option>
                        <option value="Head HR">Head HR</option>
                      </select>
                    </div>

                    <div className="col-md-6">
                      <span className="input-labels">Select company</span>
                      <sup className="fa fa-asterisk asterisk"></sup>
                      <Select isMulti options={optionData} onChange={handleCompany}
                        required></Select>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-3 mb-3">
                      <span className="input-labels">Name</span>
                      <sup className="fa fa-asterisk asterisk"></sup>
                      <input
                        onChange={handleChange}
                        name="name"
                        className="form-control"
                        value={newRole.name}
                        required
                      />
                    </div>
                    <div className="col-md-3 mb-3">
                      <span className="input-labels">Email</span>
                      <sup className="fa fa-asterisk asterisk"></sup>

                      <input
                        style={{ width: "100%" }}
                        onChange={handleChange}
                        name="email"
                        type="email"
                        className="form-control"
                        value={newRole.email}
                        required
                      />
                    </div>
                    <div className="col-md-3 mb-3">
                      <span className="input-labels">UserId</span>
                      <sup className="fa fa-asterisk asterisk"></sup>
                      <input
                        onChange={handleChange}
                        name="userId"
                        className="form-control"
                        value={newRole.userId}
                        required
                      />
                    </div>
                    <div className="col-md-3 mb-3">
                      <span className="input-labels">Password</span>
                      <sup className="fa fa-asterisk asterisk"></sup>
                      <input
                        onChange={handleChange}
                        name="password"
                        className="form-control"
                        value={newRole.password}
                        required
                      />
                    </div>
                  </div>

                  <div>
                    <button
                      style={{ width: "12rem", backgroundColor: "#03356D" }}
                      type="Submit"
                      className="btn6 btn-primary mb-3"
                    // onClick={handleSubmit}
                    >
                      CREATE NEW ROLE
                    </button>
                  </div>
                </form>
              </div>
            </div>
            <div className="card-footer">
              {state.roleList.length > 0 && (
                <PaginationTable columnsArr={columns} dataArr={data} />
              )}
            </div>
            {notification.success && (
              <Snackbar msg="HR created successfully" type="success" />
            )}
            {notification.fail && (
              <Snackbar msg="Something went wrong" type="error" />
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default CreateUser;
