import { policyList } from "../constants/constants";
const intialState = {
  loading: null,
  policyList: [],
  error: "",
};

export const getPolicyListReducer = (state = intialState, action) => {
  switch (action.type) {
    case policyList.LOADING:
      return {
        ...state,
        loading: true,
      };
    case policyList.POLICY_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        policyList: action.payload,
      };
    case policyList.POLICY_LIST_fail:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
