export const columns = [
  {
    Header: "S.No.",
    accessor: "idx",
  },
  {
    Header: "Employee Name",
    accessor: "employeeName",
  },
  {
    Header: "Designation",
    accessor: "employeeDesignation",
  },
  {
    Header: "Company Name",
    accessor: "companyName",
  },  
  {
    Header: "Message",
    accessor: "employeeMessage",
  },
  {
    Header: "Employee Image",
    accessor: "employeeImage",
  },
  {
    Header: "Action",
    accessor: "action",
  },
  {
    Header: "Delete",
    accessor: "action2",
  },
];
