import { getHrAccessList } from "../constants/constants";
const { ALL_COMPANY_LIST, HR_SELECTED_COMPANY } = getHrAccessList;

const initialState = {
  list: [],
  error: "",
  hrCompany:""
};

export const getHrAccessListReducer = (state = initialState, action) => {
  switch (action.type) {
    case ALL_COMPANY_LIST:
      return {
        ...state,
        list: action.payload,
      };
    case HR_SELECTED_COMPANY:
      return {
        ...state,
        hrCompany: action.payload,
      };

    default:
      return state;
  }
};
