import { holidayCalender } from "../constants/constants";

const { LOADING,HOLIDAY_CALENDER_SUCCESS, HOLIDAY_CALENDER_FAIL } = holidayCalender;

export const companyHolidayRequest = () => {
  return {
    type: LOADING,
  };
};

export const companyHolidaySuccess = (holiday) => {
  return {
    type: HOLIDAY_CALENDER_SUCCESS,
    payload:holiday,
  };
};

export const companyHolidayFail = (error) => {
  return {
    type: HOLIDAY_CALENDER_FAIL,
    payload: error,
  }; 
};