import React, { useEffect, useState } from "react";
import { EditorState, convertToRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import { useDispatch, useSelector } from "react-redux";
import {
  updateNavbarHeading,
  showErrorSnackbar,
} from "../../../redux/actions/uiActions";
import AudioVideoTable from "./AudioVideoTableCompanyValue";
import draftToHtml from "draftjs-to-html";
import Snackbar from "../../../Components/uiComponets/snackbar/Snackbar";
import { companyValuePost } from "../../adminMainServices/companyServices/companyServices";
import { htmlDocs } from "../../companyAbout/draftToHtml";
import { set } from "react-hook-form";
import { Loader } from "../../../loader/Loader";

function CompanyValue() {
  const dispatch = useDispatch();
  const { companyList } = useSelector((state) => state.getCompanyListReducer);
  const [companyDetails, setCompanyDetails] = React.useState(null);
  
  const [loading, setLoading] = useState(false);

  const [notification, setNotification] = React.useState({
    success: null,
    fail: null,
  });
  const [content, setContent] = React.useState({
    editorStateOne: EditorState.createEmpty(),
  });
  const [editorValue, setEditorValue] = React.useState({
    editorOneValue: null,
    editorTwoValue: null,
  });
  const [about, setAbout] = React.useState({
    filePicture: "",
    companyName: "",
  });
  const [editorLengthValidation, setEditorLengthValidation] = useState({
    aboutUsOneLength: null,
    aboutUs: null,
  });

  const { details } = useSelector(
    (state) => state.getCompanyDetailsListReducer
  );

  // console.log("details", details);

  useEffect(() => {
    dispatch(updateNavbarHeading("Company Value"));
    setNotification({ success: false, fail: false });
  }, []);

  useEffect(() => {
    if (details.length > 0) {
      let temp = details.find(
        (li) => li.companyLocationId === parseInt(about?.companyName)
      );
      setCompanyDetails(temp);
      let data1 = temp?.mission
        ? htmlDocs(temp?.mission, "editorStateOne")
        : htmlDocs("<p>No data</p>");

      setContent({
        editorStateOne: EditorState?.createWithContent(data1),
      });
    }
  }, [about.companyName]);

  const handleChange = (e) => {
    if (e.target.type === "file") {
      let file = e.target.files[0];
      // const fileType = about?.type === "image" ? 2097152 : 10485760;
      if (file?.size >= 2097152) {
        const input = document.querySelector("#imageInputFile");
        input.value = "";
        return dispatch(
          showErrorSnackbar(
            "File not supported, Please check the size and type of file",
            "error"
          )
        );
      }
      setAbout({
        ...about,
        [e.target.name]: file,
      });
    } else {
      setAbout({
        ...about,
        [e.target.name]: e.target.value,
      });
    }
  };

  const labels = ["Overview Picture", " ", "Action"];

  const editorTextOne = convertToRaw(content.editorStateOne.getCurrentContent())
    .blocks.map((rowText) => {
      return rowText.text;
    })
    .join("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (editorLengthValidation.aboutUsOneLength) {
      return;
    }
    // if (editorTextOne.length < 1) {
    //   setEditorLengthValidation({ aboutUs: true });
    //   return;
    // }
    
    let data = new FormData();
    data.append("selectCompany", parseInt(about.companyName));
    data.append("file0", about.filePicture);
    data.append("mission", editorValue.editorOneValue);
    const res = await companyValuePost(data);
    if (res.status === 200) {
      setLoading(false);
      setNotification({ success: true, fail: false });
      window.location.reload();
    } else {
      setNotification({ success: false, fail: true });
    }
    setLoading(false);
  };

  useEffect(() => {
    setEditorValue({
      ...editorValue,
      editorOneValue: draftToHtml(
        convertToRaw(content?.editorStateOne?.getCurrentContent())
      ),
    });
    if (editorTextOne.length > 500) {
      setEditorLengthValidation({ aboutUsOneLength: true });
    } else {
      setEditorLengthValidation({
        aboutUsOneLength: false,
      });
    }
  }, [content.editorStateOne]);

  const onEditorStateChangeOne = (editorState) => {
    setContent({
      ...content,
      editorStateOne: editorState,
    });
  };

  
  if (loading)
    return (
        <Loader open="true" />
    );
  return (
    <form onSubmit={handleSubmit}>
      <div className="content">
        <div className="content-wrapper">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <div className="card">
                  <div className="card-header">
                    <p className="card-title all-card-headers Note">
                      <strong>Note:</strong> Kindly upload the image in JPEG/PNG
                      only and Image should not be more than 2MB.
                    </p>
                  </div>
                  <div className="card-body">
                    <section>
                      <div className="row">
                        <div className="col-sm-4">
                          <div className="form-group">
                            <label
                              htmlFor="companyName"
                              className="input-labels"
                            >
                              Company
                            </label>
                            <sup className="fa fa-asterisk asterisk"></sup>
                            <select
                              onChange={handleChange}
                              name="companyName"
                              className="form-control"
                              required
                            >
                              <option value="" selected disabled>
                                Select Company
                              </option>
                              {companyList.map((company) => (
                                <option value={company.companyId} key={company}>
                                  {company.companyName}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                        <div className="col-sm-4">
                          <div className="form-group">
                            <label className="input-labels">
                              Value's Image
                            </label>
                            <sup className="fa fa-asterisk asterisk"></sup>
                            {/* <input
                              name="filePicture"
                              onChange={handleChange}
                              className="form-control"
                              type="file"
                              required
                              accept=".jpg, .jpeg, .png"
                            /> */}
                            <input
                              type="file"
                              class="form-control"
                              id="imageInputFile"
                              name="filePicture"
                              accept=".jpg, .jpeg, .png"
                              required
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                      </div>
                    </section>
                    {editorLengthValidation.aboutUsOneLength && (
                      <p style={{ color: "red" }}>
                        Please Enter 500 words only
                      </p>
                    )}
                    {/* {editorLengthValidation.aboutUs && (
                      <p style={{ color: "red" }}>
                        This is editor is mandatory
                      </p>
                    )} */}
                    <div className="card">
                      <div className="card-header">
                        <h3 className="card-title bold-card-header">
                          Values
                        </h3>
                        <sup className="fa fa-asterisk asterisk"></sup>
                      </div>
                      <div className="card-body">
                        <Editor
                          editorState={content.editorStateOne}
                          toolbarClassName="toolbarClassName"
                          wrapperClassName="wrapperClassName"
                          editorClassName="editorClassName"
                          onEditorStateChange={onEditorStateChangeOne}
                        />
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignContent: "stretch",
                        justifyContent: "flex-end",
                      }}
                      className="text-left"
                    >
                      <button
                        className="btn btn-primary common-btn"
                        type="submit"
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                  <div>
                    {about.companyName === "" ? (
                      <div className="card-footer pt-3 pb-3 ml-1 mr-1">
                        <h5 className="ml-3">
                          Select a company to see details
                        </h5>
                      </div>
                    ) : (
                      <div className="card-footer">
                        <AudioVideoTable
                          labels={labels}
                          imgSrc={companyDetails?.missionPic}
                          videoSrc={companyDetails?.overviewVideo}
                          name="value"
                          id={companyDetails?.id}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            {notification.success && (
              <Snackbar msg="Company about added successfully" type="success" />
            )}
            {notification.fail && (
              <Snackbar msg="Something went wrong" type="error" />
            )}
          </div>
        </div>
      </div>
    </form>
  );
}

export default CompanyValue;
