import React from "react";
import { useTable, usePagination, useGlobalFilter } from "react-table";
import SearchTable from "./SearchTable";

function PaginationTable({ columnsArr, dataArr }) {
  const columns = columnsArr;
  const data = dataArr;
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    state,
    setGlobalFilter,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
    },

    useGlobalFilter,
    usePagination
  );

  const { pageIndex, globalFilter } = state;
  return (
    <>
      <SearchTable filter={globalFilter} setFilter={setGlobalFilter} />
      <div className="table-responsive">
        <table className="table" {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr
                style={{ whiteSpace: "nowrap" }}
                {...headerGroup.getHeaderGroupProps()}
              >
                {headerGroup.headers.map((column) => (
                  <th
                    className="table-heading-top"
                    scope="col"
                    {...column.getHeaderProps()}
                  >
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <td
                        style={{ wordWrap: "break-word" }}
                        className=""
                        {...cell.getCellProps({
                          onClick: (cell) => {
                            console.log(cell);
                          },
                        })}
                      >
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
          <tfoot style={{ float: "right" }}>
            <span>
              Page{" "}
              <strong>
                {pageIndex + 1} Of {pageOptions.length}
              </strong>{" "}
            </span>
            <div style={{ display: "flex" }} className="text-left">
              <button
                className="btn-primary btn btn-sm"
                disabled={!canPreviousPage}
                onClick={() => previousPage()}
                style={{
                  backgroundColor: '#03356D',
                }}
                >
                Previous
              </button>
              <button
                className="btn-primary btn btn-sm ml-1"
                disabled={!canNextPage}
                onClick={() => nextPage()}
                style={{
                  backgroundColor: '#03356D',
                }}
                >
                Next
              </button>
            </div>
          </tfoot>
        </table>
      </div>
    </>
  );
}

export default PaginationTable;
