import React, { useEffect, useState } from "react";
import "./CompanySocialLink.css";
import { useDispatch, useSelector } from "react-redux";
import { updateNavbarHeading } from "../../redux/actions/uiActions";
import axios from "axios";
import Snackbar from "../../Components/uiComponets/snackbar/Snackbar";

import { Loader } from "../../loader/Loader";

const CompanySocialLink = () => {
  const dispatch = useDispatch();

  const [loader, setLoader] = useState(false);

  const { companyList } = useSelector((state) => state.getCompanyListReducer);
  const [social, setSocial] = useState({
    companyName: "",
    facebook: "",
    twitter: "",
    linkedin: "",
    youtube: "",
  });
  const [notification, setNotification] = React.useState({
    success: null,
    fail: null,
  });

  useEffect(() => {
    dispatch(updateNavbarHeading("Company Social"));
  }, []);
  const handleChange = (e) => {
    setSocial({
      ...social,
      [e.target.name]: e.target.value,
    });
  };
  const handleSubmit = (e) => {
    setLoader(true);
    e.preventDefault();
    axios
      .post(
        `${process.env.REACT_APP_DEV}/api/updateCompanyWiseSocialLink`,
        social
      )
      .then((res) => {
        setLoader(false);
        setNotification({ success: true, fail: false });
        setSocial({
          companyName: "",
          facebook: "",
          twitter: "",
          linkedin: "",
          youtube: "",
        });
      })
      .catch((err) => {
        setLoader(false);
        setNotification({ success: false, fail: true });
      });
  };

  if (loader) return <Loader open="true" />;
  return (
    <>
      <div className="content">
        <div className="content-wrapper">
          <div className="container-fluid">
            <div className="Rectangle-company-social-link">
              <form onSubmit={handleSubmit}>
                <div class="form-row row">
                  <div class="col-md-4 mb-3">
                    <label htmlFor="inputEmail4" className="input-labels">
                      Company
                    </label>
                    <sup className="fa fa-asterisk asterisk"></sup>
                    <select
                      name="companyName"
                      onChange={handleChange}
                      className="form-control"
                      required
                      value={social.companyName}
                    >
                      <option value="" selected disabled="selected">
                        Select-Company Location
                      </option>
                      {companyList.map((company) => (
                        <option value={company.companyId} key={company}>
                          {company.companyName}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div class="col-md-4 mb-3">
                    <label htmlFor="inputEmail4" className="input-labels">
                      Facebook Link
                    </label>
                    <sup className="fa fa-asterisk asterisk"></sup>
                    <input
                      type="url"
                      class="form-control "
                      placeholder=""
                      name="facebook"
                      onChange={handleChange}
                      required
                      value={social.facebook}
                    />
                  </div>
                  <div class="col-md-4 mb-3">
                    <label htmlFor="inputEmail4" className="input-labels">
                      Twitter Link
                    </label>

                    <sup className="fa fa-asterisk asterisk"></sup>
                    <input
                      type="url"
                      class="form-control "
                      placeholder=" "
                      name="twitter"
                      onChange={handleChange}
                      required
                      value={social.twitter}
                    />
                  </div>
                </div>
                <div class="form-row row">
                  <div class="col-md-4 mb-3">
                    <label htmlFor="inputEmail4" className="input-labels">
                      LinkedIn Link
                    </label>
                    <sup className="fa fa-asterisk asterisk"></sup>
                    <input
                      type="url"
                      class="form-control"
                      placeholder=" "
                      name="linkedin"
                      onChange={handleChange}
                      value={social.linkedin}
                      required
                    />
                  </div>
                  <div class="col-md-4 mb-3">
                    <label htmlFor="inputEmail4" className="input-labels">
                      YouTube Link
                    </label>
                    <sup className="fa fa-asterisk asterisk"></sup>
                    <input
                      type="url"
                      class="form-control"
                      placeholder=" "
                      name="youtube"
                      onChange={handleChange}
                      value={social.youtube}
                      required
                    />
                  </div>
                </div>
                <div className="form-row">
                  <button type="submit" className="btn4 btn-primary mt-2">
                    SUBMIT
                  </button>
                </div>
              </form>
              {notification.success && (
                <Snackbar msg="Gallery addedd successfully" type="success" />
              )}
              {notification.fail && (
                <Snackbar msg="Something went wrong" type="error" />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default CompanySocialLink;
