export const columns = [
  {
    Header: "S.No.",
    accessor: "idx",
  },
  {
    Header: "Photo",
    accessor: "photo",
  },
  {
    Header: "Name",
    accessor: "name",
  },
  {
    Header: "Department",
    accessor: "department",
  },
  {
    Header: "Email",
    accessor: "email",
  },
  {
    Header: "Phone",
    accessor: "phone",
  },
  {
    Header: "Detail",
    accessor: "detail",
  },
  {
    Header: "Region",
    accessor: "region",
  },
  {
    Header: "Action",
    accessor: "action",
  },
  {
    Header: "",
    accessor: "action2",
  },
];
