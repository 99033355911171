import React from "react";
const Congrats = React.lazy(() => import("../candidates/Congrats/Congrt"));
const PersonalDetails = React.lazy(() =>
  import("../candidates/PersonalDetails/AddressDetails")
);
const UploadPhoto = React.lazy(() =>
  import("../candidates/photoUpload/PhotoUpload")
);
const UploadDocument = React.lazy(() =>
  import("../candidates/UploadDocuments/UploadDocument")
);
const ReviewAndSumbit = React.lazy(() =>
  import("../candidates/Review&submit/review&submit")
);

const ResetPassword = React.lazy(() =>
  import("../Components/home/auth/login/ResetPassword")
);

//offer

const OfferLetterSeeds = React.lazy(() =>
  import(
    "../candidates/OfferedCandidates/seedsOfferLetter/LetterOfEmploymentSeeds/LetterOfEmployment"
  )
);
const OfferLetterAgro = React.lazy(() =>
  import(
    "../candidates/OfferedCandidates/agroOfferLetter/OfferLetterFreshFarms/OfferLetter"
  )
);
const OfferLetterSjs = React.lazy(() =>
  import(
    "../candidates/OfferedCandidates/sjsOfferLetter/Offer_letterSJS/Offer_Letter"
  )
);

const OfferLetterDairy = React.lazy(() =>
  import(
    "../candidates/OfferedCandidates/dairyOfferLetter/Letter of Employment OfferDairy/OfferLetter"
  )
);

//pre onboard

const WelcomePreOnBoard = React.lazy(() =>
  import(
    "../candidates/PreOnBoardCandidates/Pre-onboarding/welcomepage/welcome"
  )
);
const PreOnBoardDashboard = React.lazy(() =>
  import(
    "../candidates/PreOnBoardCandidates/Pre-onboarding/Dashboard/dashboard"
  )
);
const PreOnBoardCompanyOverView = React.lazy(() =>
  import(
    "../candidates/PreOnBoardCandidates/Pre-onboarding/CompanyOverview/overview"
  )
);
const PreOnBoardCompanyVision = React.lazy(() =>
  import("../candidates/PreOnBoardCandidates/Pre-onboarding/Vision/vision")
);

const PreOnBoardCompanyMission = React.lazy(() =>
  import("../candidates/PreOnBoardCandidates/Pre-onboarding/Mission/mission")
);
// const PreOnBoardTaskList = React.lazy(() =>
//   import("../candidates/PreOnBoardCandidates/Pre-onboarding/TaskList/TaskList")
// );
// const PreOnBoardCandidateDetails = React.lazy(() =>
//   import(
//     "../candidates/PreOnBoardCandidates/Pre-onboarding/CandidateDetail/detail1"
//   )
// );
const PreOnBoardUploadPhoto = React.lazy(() =>
  import(
    "../candidates/PreOnBoardCandidates/Pre-onboarding/UploadPhoto/PhotoUpload"
  )
);
const PreOnBoardUploadSignature = React.lazy(() =>
  import(
    "../candidates/PreOnBoardCandidates/Pre-onboarding/UploadSignature/UploadSignature"
  )
);
const PreOnBoardJobDescription = React.lazy(() =>
  import(
    "../candidates/PreOnBoardCandidates/Pre-onboarding/JobDescription/JobDescription"
  )
);
const PreOnBoardReviewForms = React.lazy(() =>
  import(
    "../candidates/PreOnBoardCandidates/Pre-onboarding/ReviewForms/FormsHead"
  )
);
const PreOnBoardUploadDocuments = React.lazy(() =>
  import(
    "../candidates/PreOnBoardCandidates/Pre-onboarding/UploadDocuments/UploadDocument"
  )
);
const PreOnBoardAwarenessChecklist = React.lazy(() =>
  import(
    "../candidates/PreOnBoardCandidates/Pre-onboarding/AwarenessChecklist/checklist"
  )
);
const PreOnBoardCompanyPolicy = React.lazy(() =>
  import(
    "../candidates/PreOnBoardCandidates/Pre-onboarding/CompanyPolicy/policy"
  )
);
const PreOnBoardSocialNetwork = React.lazy(() =>
  import(
    "../candidates/PreOnBoardCandidates/Pre-onboarding/SocialNetwork/socialNetwork"
  )
);
const PreOnBoardCompanyMision = React.lazy(() =>
  import("../candidates/PreOnBoardCandidates/Pre-onboarding/Mission/mission")
);
const PreOnBoardHearItFromFamily = React.lazy(() =>
  import(
    "../candidates/PreOnBoardCandidates/Pre-onboarding/HearItFromFamily/HearItFromFamily"
  )
);
const PreOnBoardCompanyGallery = React.lazy(() =>
  import("../candidates/PreOnBoardCandidates/Pre-onboarding/Gallery/Gallery")
);
const PreOnBoardTour = React.lazy(() =>
  import("../candidates/PreOnBoardCandidates/Pre-onboarding/Tour/tour")
);
const PreOnBoardLeaders = React.lazy(() =>
  import("../candidates/PreOnBoardCandidates/Pre-onboarding/Leaders/leader")
);
const PreOnBoardMeetBuddies = React.lazy(() =>
  import("../candidates/PreOnBoardCandidates/Pre-onboarding/Buddies/buddies")
);

const PreOnBoardReferFriend = React.lazy(() =>
  import(
    "../candidates/PreOnBoardCandidates/Pre-onboarding/ReferFriend/ReferFriend"
  )
);
const PreOnBoardTaskList = React.lazy(() =>
  import("../candidates/PreOnBoardCandidates/Pre-onboarding/TaskList/TaskList")
);
const PreOnBoardTaskDetails = React.lazy(() =>
  import(
    "../candidates/PreOnBoardCandidates/Pre-onboarding/CandidateDetail/DetailHead"
  )
);
const ChecklistSeedsDairy = React.lazy(() =>
  import(
    "../candidates/PreOnBoardCandidates/Pre-onboarding/Forms/Seeds&Dairy/CheckListForJoinee/CheckListForJoinee"
  )
);
const EmployeePeronal = React.lazy(() =>
  import(
    "../candidates/PreOnBoardCandidates/Pre-onboarding/Forms/Seeds&Dairy/EmployeePersonal/EmployeePersonalProfile"
  )
);
const EmployeeApplication = React.lazy(() =>
  import(
    "../candidates/PreOnBoardCandidates/Pre-onboarding/Forms/Seeds&Dairy/EmploymentApplication/EmploymentApplication"
  )
);
const GratuityForm = React.lazy(() =>
  import(
    "../candidates/PreOnBoardCandidates/Pre-onboarding/Forms/Seeds&Dairy/GratuityForm/GratuityForm"
  )
);
const Form11 = React.lazy(() =>
  import(
    "../candidates/PreOnBoardCandidates/Pre-onboarding/Forms/Seeds&Dairy/Form11/Form11"
  )
);
const InductionTraining = React.lazy(() =>
  import(
    "../candidates/PreOnBoardCandidates/Pre-onboarding/Forms/Seeds&Dairy/InductionTraining/InductionTraining"
  )
);
const interviewAssesmentForm = React.lazy(() =>
  import(
    "../candidates/PreOnBoardCandidates/Pre-onboarding/Forms/Seeds&Dairy/InterviewAssesmentForms/InterviewAssesmentForms"
  )
);
const CheckListAgro = React.lazy(() =>
  import(
    "../candidates/PreOnBoardCandidates/Pre-onboarding/Forms/Agro/ChecklistEmployeeFile/ChecklistEmployeeFile"
  )
);
const CodeOfConduct = React.lazy(() =>
  import(
    "../candidates/PreOnBoardCandidates/Pre-onboarding/Forms/Agro/CodeOfConduct/CodeOfConduct"
  )
);
const DeclarationForm = React.lazy(() =>
  import(
    "../candidates/PreOnBoardCandidates/Pre-onboarding/Forms/Agro/DeclarationForm/DeclarationForm"
  )
);
const EmployeeInformation = React.lazy(() =>
  import(
    "../candidates/PreOnBoardCandidates/Pre-onboarding/Forms/Agro/EmployeeInformation/EmployeeInformation"
  )
);
const Form2 = React.lazy(() =>
  import(
    "../candidates/PreOnBoardCandidates/Pre-onboarding/Forms/Agro/Form2/Form2"
  )
);
const JoiningBooklet = React.lazy(() =>
  import(
    "../candidates/PreOnBoardCandidates/Pre-onboarding/Forms/Agro/JoiningBooklet/JoiningBooklet"
  )
);
const NDA = React.lazy(() =>
  import("../candidates/PreOnBoardCandidates/Pre-onboarding/Forms/Agro/NDA/NDA")
);

//pre onboard reviewa
const PreOnBoardReviewEmployeeVerification = React.lazy(() =>
  import(
    "../candidates/PreOnBoardCandidates/Pre-onboarding/Forms/SJS/EmployeeVerificationForm/EmployeeVerificationForm"
  )
);
const PreOnBoardReviewEmployeeDocSubmission = React.lazy(() =>
  import(
    "../candidates/PreOnBoardCandidates/Pre-onboarding/Forms/SJS/EmpDocSubmissionForm/EmpDocSubmissionForm"
  )
);
const PreOnBoardReviewNda = React.lazy(() =>
  import(
    "../candidates/PreOnBoardCandidates/Pre-onboarding/Forms/SJS/SjsNdaForm/SjsNdaForm"
  )
);
const PreOnBoardReviewVacicination = React.lazy(() =>
  import(
    "../candidates/PreOnBoardCandidates/Pre-onboarding/Forms/SJS/VaccinationStatus/VaccinationStatus"
  )
);
const PreOnBoardReviewStaff = React.lazy(() =>
  import(
    "../candidates/PreOnBoardCandidates/Pre-onboarding/Forms/SJS/NursingStaff/NursingStaff"
  )
);
const PreOnBoardReviewDecleration = React.lazy(() =>
  import(
    "../candidates/PreOnBoardCandidates/Pre-onboarding/Forms/SJS/Delaration/Declaration"
  )
);
const PreOnBoardReviewIdForm = React.lazy(() =>
  import(
    "../candidates/PreOnBoardCandidates/Pre-onboarding/Forms/SJS/Id_form/Identity"
  )
);
// const PreOnBoardReviewGratituty = React.lazy(() =>
//   import(
//     "../candidates/PreOnBoardCandidates/Pre-onboarding/Forms/SJS/"
//   )
// );
const PreOnBoardReviewEmployementForm = React.lazy(() =>
  import(
    "../candidates/PreOnBoardCandidates/Pre-onboarding/Forms/SJS/EmployementForm/Emplymentform"
  )
);

export const candidateRoutesArr = [
  {
    path: "/reset-password",
    component: ResetPassword,
    excat: true,
  },
  
  //pre offer
  {
    path: "/candidate/:id/preOffer",
    component: Congrats,
    excat: true,
  },
  {
    path: "/candidate/preOffer/personalDetails",
    component: PersonalDetails,
    excat: true,
  },
  {
    path: "/candidate/preOffer/uploadPhoto",
    component: UploadPhoto,
    excat: true,
  },
  {
    path: "/candidate/preOffer/uploadDocument",
    component: UploadDocument,
    excat: true,
  },
  {
    path: "/candidate/preOffer/reviewSubmit",
    component: ReviewAndSumbit,
    excat: true,
  },

  //offer
  {
    path: "/candidate/offer/seeds/offerLetter",
    component: OfferLetterSeeds,
    excat: true,
  },
  {
    path: "/candidate/offer/agro/offerLetter",
    component: OfferLetterAgro,
    excat: true,
  },
  {
    path: "/candidate/offer/dairy/offerLetter",
    component: OfferLetterDairy,
    excat: true,
  },
  {
    path: "/candidate/offer/sjs/offerLetter",
    component: OfferLetterSjs,
    excat: true,
  },

  //pre onboard
  {
    path: "/candidate/preOnboard/welcome",
    component: WelcomePreOnBoard,
    excat: true,
  },
  {
    path: "/candidate/preOnboard/dashboard",
    component: PreOnBoardDashboard,
    excat: true,
  },
  {
    path: "/candidate/preOnboard/companyOverview",
    component: PreOnBoardCompanyOverView,
    excat: true,
  },
  {
    path: "/candidate/preOnboard/companyVision",
    component: PreOnBoardCompanyVision,
    excat: true,
  },
  {
    path: "/candidate/preOnboard/companyMision",
    component: PreOnBoardCompanyMission,
    excat: true,
  },
  
  // {
  //   path: "/candidate/preOnboard/TaskList",
  //   component: PreOnBoardTaskList,
  //   excat: true,
  // },
  // {
  //   path: "/candidate/preOnboard/CandidateDetails",
  //   component: PreOnBoardCandidateDetails,
  //   excat: true,
  // },
  {
    path: "/candidate/preOnboard/UploadPhoto",
    component: PreOnBoardUploadPhoto,
    excat: true,
  },
  {
    path: "/candidate/preOnboard/UploadSignature",
    component: PreOnBoardUploadSignature,
    excat: true,
  },
  {
    path: "/candidate/preOnboard/JobDescription",
    component: PreOnBoardJobDescription,
    excat: true,
  },
  {
    path: "/candidate/preOnboard/ReviewForms",
    component: PreOnBoardReviewForms,
    excat: true,
  },
  {
    path: "/candidate/preOnboard/UploadDocuments",
    component: PreOnBoardUploadDocuments,
    excat: true,
  },
  {
    path: "/candidate/preOnboard/AwarenessChecklist",
    component: PreOnBoardAwarenessChecklist,
    excat: true,
  },
  {
    path: "/candidate/preOnboard/CompanyPolicy",
    component: PreOnBoardCompanyPolicy,
    excat: true,
  },
  {
    path: "/candidate/preOnboard/SocialNetworks",
    component: PreOnBoardSocialNetwork,
    excat: true,

  },
  {
    path: "/candidate/preOnboard/hearItFromFamily",
    component: PreOnBoardHearItFromFamily,
    excat: true,
  },
  {
    path: "/candidate/preOnboard/companyGallery",
    component: PreOnBoardCompanyGallery,
    excat: true,
  },
  {
    path: "/candidate/preOnboard/tour",
    component: PreOnBoardTour,
    excat: true,
  },
  {
    path: "/candidate/preOnboard/meetLeaders",
    component: PreOnBoardLeaders,
    excat: true,
  },
  {
    path: "/candidate/preOnboard/meetYourBuddies",
    component: PreOnBoardMeetBuddies,
    excat: true,
  },
  {
    path: "/candidate/preOnboard/referFriend",
    component: PreOnBoardReferFriend,
    excat: true,
  },
  {
    path: "/candidate/preOnboard/task",
    component: PreOnBoardTaskList,
    excat: true,
  },
  {
    path: "/candidate/preOnboard/task/details",
    component: PreOnBoardTaskDetails,
    excat: true,
  },
  {
    path: "/candidate/preOnboard/forms/ChecklistForJoinee",
    component: ChecklistSeedsDairy,
    excat: true,
  },
  {
    path: "/candidate/preOnboard/forms/EmployeePersonal",
    component: EmployeePeronal,
    excat: true,
  },
  {
    path: "/candidate/preOnboard/forms/EmploymentApplication",
    component: EmployeeApplication,
    excat: true,
  },
  {
    path: "/candidate/preOnboard/forms/GratuityForm",
    component: GratuityForm,
    excat: true,
  },
  {
    path: "/candidate/preOnboard/forms/Form11",
    component: Form11,
    excat: true,
  },
  {
    path: "/candidate/preOnboard/forms/InductionTraining",
    component: InductionTraining,
    excat: true,
  },
  {
    path: "/candidate/preOnboard/forms/InterviewAssesmentForms",
    component: interviewAssesmentForm,
    excat: true,
  },
  {
    path: "/candidate/preOnboard/forms/ChecklistEmployee",
    component: CheckListAgro,
    excat: true,
  },
  {
    path: "/candidate/preOnboard/forms/CodeOfConduct",
    component: CodeOfConduct,
    excat: true,
  },
  {
    path: "/candidate/preOnboard/forms/DeclarationForm",
    component: DeclarationForm,
    excat: true,
  },
  {
    path: "/candidate/preOnboard/forms/EmploymentInformation",
    component: EmployeeInformation,
    excat: true,
  },
  {
    path: "/candidate/preOnboard/forms/InterviewAssesmentForms",
    component: interviewAssesmentForm,
    excat: true,
  },
  {
    path: "/candidate/preOnboard/forms/Form2",
    component: Form2,
    excat: true,
  },
  {
    path: "/candidate/preOnboard/forms/JoiningBooklet",
    component: JoiningBooklet,
    excat: true,
  },
  {
    path: "/candidate/preOnboard/forms/NDA",
    component: NDA,
    excat: true,
  },

  //sjs reviews
  {
    path: "/candidate/preOnboard/review/employeeVerification",
    component: PreOnBoardReviewEmployeeVerification,
    excat: true,
  },
  {
    path: "/candidate/preOnboard/review/employeeDoc",
    component: PreOnBoardReviewEmployeeDocSubmission,
    excat: true,
  },
  {
    path: "/candidate/preOnboard/review/nda",
    component: PreOnBoardReviewNda,
    excat: true,
  },
  {
    path: "/candidate/preOnboard/review/vaccinationStatus",
    component: PreOnBoardReviewVacicination,
    excat: true,
  },
  {
    path: "/candidate/preOnboard/review/nurseingStaff",
    component: PreOnBoardReviewStaff,
    excat: true,
  },
  {
    path: "/candidate/preOnboard/review/decleration",
    component: PreOnBoardReviewDecleration,
    excat: true,
  },
  {
    path: "/candidate/preOnboard/review/idForm",
    component: PreOnBoardReviewIdForm,
    excat: true,
  },
  {
    path: "/candidate/preOnboard/review/emplumentForm",
    component: PreOnBoardReviewEmployementForm,
    excat: true,
  },
];
