export const columns = [
  {
    Header: "S.No.",
    accessor: "idx",
  },
  {
    Header: "Company Name",
    accessor: "companyName",
  },
  {
    Header: "Logo",
    accessor: "logo",
  },
];
