import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import Snackbar from "../../../Components/uiComponets/snackbar/Snackbar";
import { getSpocksAction } from "../../../redux/actions/getBuddiesList";
import { MdModeEdit } from "react-icons/md";
import { editKey_spocs } from "../../adminMainServices/key_spocsservices/key_spocs_service";
import { showErrorSnackbar } from "../../../redux/actions/uiActions";
import { Loader } from "../../../loader/Loader";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    width: "40rem",
  },
  card: {
    marginBottom: "0",
    width: "100%",
  },
}));

export default function KeySpocksModal({ id }) {
  const dispatch = useDispatch();
  
  const [loader, setLoader] = useState(false);

  const { loading, buddies, error } = useSelector(
    (state) => state.getBuddiesReducer
  );
  const { companyList } = useSelector((state) => state.getCompanyListReducer);
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [notification, setNotification] = useState({
    success: null,
    fail: null,
  });

  const [addKey, setAddKey] = useState({
    companyId: "",
    // photo: "",
    name: "",
    designation: "",
    department: "",
    region: "",
    email: "",
    phone: "",
    details: "",
    // details: "",
  });

  const [errors, setErrors] = useState({});

  const [values, setValues] = useState({
    name: "",
    department: "",
    region: "",
  });
  const [phone, setPhone] = useState({
    phone: "",
  });

  useEffect(() => {
    if (buddies.length > 0) {
      let temp = buddies.find((li) => li.id === id);
      setAddKey({
        companyId: temp.companyId,
        name: temp.name,
        department: temp.department,
        region: temp.region,
        email: temp.email,
        phone: temp.phone,
        details: temp.details,
      });
    }
  }, [id]);

  const handleChange = (e) => {
    if (e.target.type === "file") {
      let file = e.target.files[0];
      // const fileType = about?.type === "image" ? 2097152 : 10485760;
      if (file?.size >= 2097152) {
        const input = document.querySelector("#imageInputFile");
        input.value = "";
        return dispatch(
          showErrorSnackbar(
            "File not supported, Please check the size and type of file",
            "error"
          )
        );
      }
      setAddKey({
        ...addKey,
        [e.target.name]: e.target.files[0],
      });
    } else {
      setAddKey({
        ...addKey,
        [e.target.name]: e.target.value,
      });
    }
    setValues({
      ...values,
      [e.target.name]: e.target.value.replace(/[^ ^A-Za-z]/gi, ""),
    });
    setPhone({
      ...phone,
      [e.target.name]: e.target.value.replace(/[^0-9]/gi, ""),
    });
  };
  const handleSubmit = async (e) => {
    setLoader(true);
    e.preventDefault();
    
    let data = {
      id: id,
      // photo: addKey.photo,
      companyId: addKey.companyId,
      department: addKey.department,
      designation: addKey.name,
      region: addKey.region,
      email: addKey.email,
      phone: addKey.phone,
      details: addKey.details,
    };
    // const data = new FormData();
    // data.append("id", id);
    // // data.append("photo", addKey.photo);
    // data.append("companyId", addKey.companyId);
    // data.append("name", addKey.name);
    // data.append("designation", addKey.designation);
    // // data.append("department", addKey.designation);
    // data.append("region", addKey.region);
    // data.append("email", addKey.email);
    // data.append("phone", addKey.phone);
    // data.append("detail", addKey.details);
    // data.append("details2", addKey.details);
    // console.log(keyspccss)
    // const res = await editKey_spocs(keyspccss);
    // if (res.status === 200) {
      //   handleClose()
      // } else {
        //   setNotification({ fail: true, success: false })
        // }
        console.log(data);
        axios
        .post(`${process.env.REACT_APP_DEV}/api/editorUpdateKeySpocs`, {
          keySpocsData: data,
        })
        .then((res) => {
          setLoader(false);
          setNotification({ success: true, fail: false });
          dispatch(getSpocksAction());
          handleClose();
        })
      .catch((err) => {
        setLoader(false);
        setNotification({ success: false, fail: true });
      });
  };
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  if (loader)
    return (
        <Loader open="true" />
    );
  return (
    <div>
      <MdModeEdit onClick={handleOpen} size={20} />
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <div className={`card ${classes.card}`}>
              <div className="card-header">
                <h3 className="card-title">EDIT KEY SPOCKS</h3>
              </div>
              <div className="card-body">
                <form onSubmit={handleSubmit} style={{ width: "100%" }}>
                  <section className="d-flex justify-content-between">
                    <div className="form-group d-flex flex-column">
                      <label>Company</label>
                      <select
                        name="companyId"
                        onChange={handleChange}
                        className="form-control"
                        required
                        value={addKey.companyId}
                      >
                        <option disabled selected value="">
                          Select Company
                        </option>
                        {companyList.map((company) => (
                          <option value={company.companyId} key={company}>
                            {company.companyName}
                          </option>
                        ))}
                      </select>
                    </div>
                    {/* <div className="form-group d-flex flex-column">
                      <label>Photo*</label>
                      <input
                        name="photo"
                        onChange={handleChange}
                        type="file"
                        className="form-control"
                        required
                      />
                    </div> */}
                  </section>
                  <section className="d-flex justify-content-between">
                    <div className="form-group d-flex flex-column">
                      <label>Name</label>
                      <input
                        name="name"
                        onChange={handleChange}
                        className="form-control"
                        value={values.name || addKey.name}
                        required
                      />
                    </div>
                    <div className="form-group d-flex flex-column">
                      <label>Designation</label>
                      <input
                        name="designation"
                        onChange={handleChange}
                        className="form-control"
                        value={values.designation || addKey.department}
                        required
                      />
                    </div>
                  </section>
                  <section className="d-flex justify-content-between">
                    {/* <div className="form-group d-flex flex-column">
                      <label>Department</label>
                      <input
                        name="department"
                        onChange={handleChange}
                        className="form-control"
                        required
                      />
                    </div> */}
                    <div className="form-group d-flex flex-column">
                      <label>Region</label>
                      <input
                        name="region"
                        onChange={handleChange}
                        value={values.region || addKey.region}
                        className="form-control"
                        required
                      />
                    </div>
                  </section>

                  <section className="d-flex justify-content-between">
                    <div className="form-group d-flex flex-column">
                      <label>Email</label>
                      <input
                        name="email"
                        onChange={handleChange}
                        className="form-control"
                        value={addKey.email}
                        required
                      />
                    </div>
                    <div className="form-group d-flex flex-column">
                      <label>Phone</label>
                      <input
                        name="phone"
                        onChange={handleChange}
                        className="form-control"
                        value={phone.phone || addKey.phone}
                        maxLength="10"
                      />
                    </div>
                  </section>
                  <section className="d-flex justify-content-between">
                    <div className="form-group d-flex flex-column">
                      <label>Details</label>
                      <textarea
                        name="details"
                        onChange={handleChange}
                        className="form-control"
                        value={addKey.details}
                        required
                      />
                    </div>
                    {/* <div className="form-group d-flex flex-column">
                      <label>Details2</label>
                      <textarea
                        name="details"
                        onChange={handleChange}
                        required
                        className="form-control"
                      />
                    </div> */}
                  </section>
                  <section className="text-right d-flex ">
                    <button
                      type="submit"
                      className="btn btn-sm btn-primary text-white"
                      style={{backgroundColor: "#03356D"}}
                      >
                      Edit Key Spocks
                    </button>
                    <button
                      onClick={handleClose}
                      type="submit"
                      className="btn btn-sm btn-primary ml-1 text-white"
                      style={{backgroundColor: "#03356D"}}
                    >
                      close
                    </button>
                  </section>
                </form>
                {notification.success && (
                  <Snackbar
                    msg="Key Spocks updated successfully"
                    type="success"
                  />
                )}
                {notification.fail && (
                  <Snackbar msg="Something went wrong" type="error" />
                )}
              </div>
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}
