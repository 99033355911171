import React, { useEffect, useState } from "react";
import Sidebar from "../admin/sidebar/Sidebar";
import Navbar from "../admin/navbar/Navbar";
import { useSelector, useDispatch } from "react-redux";
import {
  getCompanyList,
  getCompanyListSuccess,
  getCompanyListFail,
} from "../redux/actions/getCompanyList";
import { Redirect } from "react-router-dom";
import axios from "axios";
import { getCompanyDetailsAction } from "../redux/actions/getCompanyDetails";

const DashboardLayoutRoute = ({ children }) => {
  const dispatch = useDispatch();
  let admin = JSON.parse(sessionStorage.getItem("admin"));
  useEffect(() => {
    dispatch(getCompanyList());
    axios
      .get(`${process.env.REACT_APP_DEV}/api/getAllLocationListEntityWise`)
      .then((res) => {
        let list = res.data.map((company) => {
          return {
            companyName: company.companyName,
            companyId: parseInt(company.companyId),
          };
        });
        dispatch(getCompanyListSuccess(list));
      })
      .catch((err) => dispatch(getCompanyListFail(err)));
    dispatch(getCompanyDetailsAction());
  }, []);
  if (admin == null) {
    return <Redirect to="/" />;
  }
  return (
    <>
      <Navbar />
      <Sidebar />
      {children}
    </>
  );
};

export default DashboardLayoutRoute;
