import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import { AiFillEye } from "react-icons/ai";
import { playVideo } from "./adminServices/admin.services";

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    position: "absolute",
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: 500,
    height: 500,
    boxShadow: theme.shadows[5],
  },
}));

export default function SimpleModal({ src }) {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [modalStyle] = React.useState(getModalStyle);
  const [open, setOpen] = React.useState(false);
  const [videoSrc, setVideoSrc] = React.useState();

  const handleOpen = async () => {
    setLoading(true);
    const res = await playVideo(src);
    if (res.status === 200) {
      setVideoSrc(res?.data);
      setOpen(true);
      setLoading(false);
    }
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const body = (
    <div style={modalStyle} className={classes.paper}>
      <section style={{ width: "100%", height: "100%" }}>
        {videoSrc && (
          <video
            controls
            style={{ width: "100%", height: "100%", objectFit: "cover" }}
          >
            <source src={URL.createObjectURL(videoSrc)} type="video/webm" />
            Sorry, your browser doesn't support embedded videos.
          </video>
        )}
        <button onClick={handleClose} className="pop-up-close-btn">
          X
        </button>
      </section>
    </div>
  );
  if (loading) return <div className="text-center">Loading Video...</div>;
  return (
    <div>
      <AiFillEye
        style={{ fontSize: "2rem", marginRight: "5rem",cursor:"pointer" }}
        onClick={handleOpen}
      />
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {body}
      </Modal>
    </div>
  );
}
