import { candidateList } from "../constants/constants";
const { SUCCESS, FAIL } = candidateList;

const initialState = {
  hrRoleList: null,
  error: "",
};

export const getHrRoleDataByIdReducer = (state = initialState, action) => {
  switch (action.type) {
    case SUCCESS:
      return {
        ...state,
        hrRoleList: action.payload,
      };
    case FAIL:
      return {
        ...state,
        error: action.payload,
      };

    default:
      return state;
  }
};
