import { companyNotification } from "../constants/constants";

const { LOADING, COMPANY_NOTIFI_SUCCESS,COMPANY_NOTIFI_FAIL } = companyNotification;

const intialState = {
  loading: null,
  notifi: {},
  error: "",
};

export const companyNotificationReducer = (state = intialState, action) => {
  switch (action.type) {
    case LOADING:
      return {
        ...state,
        loading: true,
      };
    case COMPANY_NOTIFI_SUCCESS:
      return {
        ...state,
        loading: false,
        notifi: action.payload,
      };
    case COMPANY_NOTIFI_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};
