import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import axios from "axios";
import Snackbar from "../../../../Components/uiComponets/snackbar/Snackbar";
import { useSelector, useDispatch } from "react-redux";
import { getBenefitListAction } from "../../../../redux/actions/getBenefitList";
import { MdModeEdit } from "react-icons/md";
import { showErrorSnackbar } from "../../../../redux/actions/uiActions";
import { Loader } from "../../../../loader/Loader";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    width: "40rem",
  },
  card: {
    marginBottom: "0",
    width: "100%",
  },
}));

export default function TransitionsModal({ companyId, id }) {
  const dispatch = useDispatch();
  const { benefitList } = useSelector((state) => state.getBenefitListReducer);
  const { companyList } = useSelector((state) => state.getCompanyListReducer);
  const classes = useStyles();

  const [loader, setLoader] = useState(false);
  const [open, setOpen] = useState(false);
  const [notification, setNotification] = useState({
    success: null,
    fail: null,
  });

  const [addKey, setAddKey] = useState({
    benefitName: "",
    benefitType: "",
    benefitPdf: "",
    companyId: "",
    id: "",
  });

  const [values, setValues] = useState({
    benefitName: "",
  });

  React.useEffect(() => {
    if (benefitList.length > 0) {
      let temp = benefitList.find((li) => li.id === id);
      setAddKey({
        benefitName: temp.benefitName,
        benefitType: temp.benefitType,
        id: temp.id,
      });
      // setNewLeader({
      //   country: temp.country,
      //   location: temp.location,
      //   itLeader: temp.itLeader,
      //   jobTitle: temp.jobTitle,
      //   description: temp.description,
      //   description2: temp.description2,
      // });
    }
  }, [id]);

  const handleChange = (e) => {
    if (e.target.type === "file") {
      let file = e.target.files[0];
      // const fileType = about?.type === "image" ? 2097152 : 10485760;
      if (file?.size >= 2097152) {
        const input = document.querySelector("#imageInputFile");
        input.value = "";
        return dispatch(
          showErrorSnackbar(
            "File not supported, Please check the size and type of file",
            "error"
          )
        );
      }
      setAddKey({
        ...addKey,
        [e.target.name]: e.target.files[0],
      });
    } else {
      setAddKey({
        ...addKey,
        [e.target.name]: e.target.value,
      });
    }

    setValues({
      ...values,
      [e.target.name]: e.target.value.replace(/[^ ^A-Za-z]/gi, ""),
    });
  };
  const handleSubmit = (e) => {
    setLoader(true);
    e.preventDefault();
    const data = new FormData();
    data.append("companyId", companyId);
    data.append("id", id);
    data.append("benefitName", addKey.benefitName);
    data.append("benefitType", addKey.benefitType);
    data.append("file1", addKey.benefitPdf);
    axios
      .post(`${process.env.REACT_APP_DEV}/api/editBenefit`, data)
      .then((res) => {
        setLoader(false);
        setNotification({ success: true, fail: false });
        dispatch(getBenefitListAction({ companyId }));
        handleClose();
      })
      .catch((err) => {
        setLoader(false);
        setNotification({ success: false, fail: true });
      });
  };
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  if (loader) return <Loader open="true" />;
  return (
    <div>
      <span type="button" onClick={handleOpen}>
        <MdModeEdit size={20} color />
      </span>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <div className={`card ${classes.card}`}>
              <div className="card-header">
                <h3 className="card-title">EDIT BENEFIT</h3>
              </div>
              <div className="card-body">
                <form onSubmit={handleSubmit} style={{ width: "100%" }}>
                  <section>
                    <div className="form-group">
                      <label>Benefit Name</label>
                      <input
                        name="benefitName"
                        onChange={handleChange}
                        className="form-control"
                        value={addKey.benefitName}
                        required
                      />
                    </div>
                    {/* <div className="form-group">
                      <label>Select Company</label>
                      <select
                        name="companyId"
                        onChange={handleChange}
                        className="form-control"
                      >
                        <option>Select Company</option>
                        {companyList.map((company) => (
                          <option value={company.companyId} key={company}>
                            {company.companyName}
                          </option>
                        ))}
                      </select>
                    </div> */}
                  </section>
                  <section>
                    <div className="form-group ">
                      <label>Benefit Type</label>
                      <select
                        name="benefitType"
                        onChange={handleChange}
                        className="form-control"
                        value={addKey.benefitType}
                      >
                        <option disabled selected value="">
                          Choose
                        </option>
                        <option>LTA</option>
                        <option>Retirement</option>
                        <option>Professional Development</option>
                        <option>Relocation</option>
                        <option>Tution Rembersment</option>
                        <option>Fringe Benefits and Perk</option>
                        <option>others</option>
                      </select>
                    </div>
                  </section>
                  <section>
                    <div className="form-group ">
                      <label>Benefit PDF</label>
                      <input
                        name="benefitPdf"
                        type="file"
                        className="form-control"
                        onChange={handleChange}
                        accept=".pdf"
                        required
                        id="imageInputFile"
                      />
                    </div>
                  </section>

                  <section className="text-right ">
                    <button
                      type="submit"
                      className="btn btn-sm btn-primary text-white"
                      style={{ backgroundColor: "#03356D" }}
                    >
                      Submit
                    </button>
                    <button
                      type="submit"
                      className="btn btn-sm btn-primary ml-1 text-white"
                      onClick={handleClose}
                      style={{ backgroundColor: "#03356D" }}
                    >
                      close
                    </button>
                  </section>
                </form>
                {notification.success && (
                  <Snackbar msg="Benefit addedd successfully" type="success" />
                )}
                {notification.fail && (
                  <Snackbar msg="Something went wrong" type="error" />
                )}
              </div>
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}
