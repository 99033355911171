import { getEmployeeTestimonial } from "../constants/constants";
const { LOADING, EMPLOYEE_TESTIMONIAL_SUCCESS, EMPLOYEE_TESTIMONIAL_FAIL } =
  getEmployeeTestimonial;

const intialState = {
  loading: null,
  testimonial: [],
  error: "",
};

export const getEmployeeTestimonialReducer = (state = intialState, action) => {
  switch (action.type) {
    case LOADING:
      
      return {
        ...state,
        loading: true,
      };
    case EMPLOYEE_TESTIMONIAL_SUCCESS:
      return {
        ...state,
        loading: false,
        testimonial: action.payload,
      };
    case EMPLOYEE_TESTIMONIAL_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};
