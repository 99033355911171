import axios from "axios";
import { getCompanyDetails } from "../constants/constants";
const {
  LOADING,
  GET_COMPANY_DETAILS_LIST_SUCCESS,
  GET_COMPANY_DETAILS_LIST_FAIL,
} = getCompanyDetails;


let url = `${process.env.REACT_APP_DEV}/api/admin/getCompanyDataInformation`;

export const getCompanyDetailsListRequest = () => {
  return {
    type: LOADING,
  };
};

export const getCompanyDetailsListSuccess = (list) => {
  return {
    type: GET_COMPANY_DETAILS_LIST_SUCCESS,
    payload: list,
  };
};

export const getCompanyDetailsListFail = (error) => {
  return {
    type: GET_COMPANY_DETAILS_LIST_FAIL,
    payload: error,
  };
};

export function getCompanyDetailsAction() {
  return (dispatch) => {
    dispatch(getCompanyDetailsListRequest());
    try {
      axios
        .get(url)
        .then((res) => dispatch(getCompanyDetailsListSuccess(res.data)))
        .catch((err) => dispatch(getCompanyDetailsListFail(err)));
    } catch (error) {
      dispatch(getCompanyDetailsListFail(error));
    }
  };
}
