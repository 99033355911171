import axios from "axios";
import { policyList } from "../constants/constants";
const { LOADING, POLICY_LIST_SUCCESS, POLICY_LIST_FAIL } = policyList;


let url = `${process.env.REACT_APP_DEV}/api/getCompanyPolicyList`;

export const getPolicyList = () => {
  return {
    type: LOADING,
  };
};

export const getPolicyListSuccess = (list) => {
  return {
    type: POLICY_LIST_SUCCESS,
    payload: list,
  };
};

export const getPolicyListFail = (error) => {
  return {
    type: POLICY_LIST_FAIL,
    payload: error,
  };
};

export function getPolicyListAction(data) {
  return (dispatch) => {
    dispatch(getPolicyList());
    try {
      axios
        .post(url,data)
        .then((res) => dispatch(getPolicyListSuccess(res.data)))
        .catch((err) => dispatch(getPolicyListFail(err)));
    } catch (error) {
      dispatch(getPolicyListFail(error));
    }
  };
}
