import React, { useState } from 'react';
import './ResetPassword.css';
import NamdhariLogo from "../../../../Images/Nmlogo.png"
import { Link } from "react-router-dom";
import axios from 'axios';
import {
    showErrorSnackbar,
    showSuccessSnackbar,
} from "../../../../redux/actions/uiActions";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Loader } from '../../../../loader/Loader';

const ResetPassword = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const intilaObj = {
        applicantId: ""
    }

    const [reset, setReset] = useState(intilaObj);
    //Loader state 
    const [loading, setLoading] = useState(false);
    const handleChange = (e) => {
        setReset({
            ...reset,
            [e.target.name]: e.target.value,
        })
    }
    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true);

        axios
            .post(
                `${process.env.REACT_APP_DEV}/api/generateOtp`,
                reset
            )
            .then((res) => {
                if (res?.status === 200) {
                    setLoading(false);
                    dispatch(showSuccessSnackbar(res?.data?.message, "success"));
                    setReset({
                        applicantId: ""
                    });
                    sessionStorage.setItem("resetApplicant", JSON.stringify(reset));
                    sessionStorage.setItem("Otp", JSON.stringify(res?.data));
                    history.push(`/create-password`);

                } else {
                    setLoading(false);
                    dispatch(showErrorSnackbar("Something went wrong", "warning"));
                }
            })
            .catch((err) => {
                setLoading(false);
                dispatch(showErrorSnackbar("Something went wrong", "warning"));
            });
    }

    // loader Render logic
    if (loading) {
        return <Loader open={true} />
    }

    return (
        <>
            <div class="loginPage">
                <form className="login-containerReset" onSubmit={handleSubmit}>
                    <div className="imgHead">
                        <img className="loginHead" src={NamdhariLogo} alt="NamdhariLogo"></img>
                    </div>
                    <div className="container">
                        <p className="reset">Reset Password</p>
                        <div class="reset-container" style={{ marginLeft: '30px' }}>
                            <input type="text" id="resetpassword" name="applicantId" class="form__input InputReset " autocomplete="off" placeholder=" " onChange={handleChange} required />
                            <label for="resetpassword" class="form__label">Username <sup className="fa fa-asterisk asterisk" ></sup></label>

                        </div>
                        <div className="reset-back-to-login">
                            <Link to="/">
                                <p className="forgetReset">Back to login</p>
                            </Link>
                        </div>
                        <div style={{ textAlign: "center", marginTop: '1rem' }}>
                            <button type="submit" className="resetBtn btn btn-success">
                                Send OTP
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </>
    );

}

export default ResetPassword;

