import { navbarHeading, snackbarHeading } from "../constants/constants";

const { UPDATE_HEADING } = navbarHeading;
const { SHOW_NOTIFICATION_SNACKBAR } = snackbarHeading;

export const updateNavbarHeading = (heading) => {
  return {
    type: UPDATE_HEADING,
    payload: heading,
  };
};

// actions/snackbarActions.js
export const showSuccessSnackbar = (message,saverity) => {
  return dispatch => {
    dispatch({ type: "SNACKBAR_SUCCESS", message,saverity });
  };
};
export const showErrorSnackbar = (message,saverity) => {
  return dispatch => {
    dispatch({ type: "SNACKBAR_ERROR", message,saverity });
  };
};
export const clearSnackbar = () => {
  return dispatch => {
    dispatch({ type: "SNACKBAR_CLEAR" });
  };
};


//steeper for candidate details 

export const updateStepper = (data) => {
  return dispatch => {
    dispatch({type:"UPDATE_STEPPER",payload:data})
  }
}
export const goBackStepper = (data) => {
  return dispatch => {
    dispatch({type:"BACK_STEPPER",payload:data})
  }
}

