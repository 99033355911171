import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { EditorState, convertToRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import {
  updateNavbarHeading,
  showErrorSnackbar,
} from "../../../redux/actions/uiActions";
import AudioVideo_Table from "../../companyAbout/AudioVideo_Table";
import draftToHtml from "draftjs-to-html";
import Snackbar from "../../../Components/uiComponets/snackbar/Snackbar";
import { companyVisionPost } from "../../adminMainServices/companyServices/companyServices";
import { htmlDocs } from "../../companyAbout/draftToHtml";
import { Loader } from "../../../loader/Loader";

function CompanyVision() {
  const { companyList } = useSelector((state) => state.getCompanyListReducer);

  const [loading, setLoading] = useState(false);

  const { details } = useSelector(
    (state) => state.getCompanyDetailsListReducer
  );
  const dispatch = useDispatch();
  const [about, setAbout] = React.useState({
    filePicture: "",
    companyName: "",
  });

  const [notification, setNotification] = React.useState({
    success: null,
    fail: null,
  });

  const [companyDetails, setCompanyDetails] = React.useState();
  const [content, setContent] = React.useState({
    editorStateOne: EditorState.createEmpty(),
    editorStateTwo: EditorState.createEmpty(),
  });

  const [editorValue, setEditorValue] = React.useState({
    editorOneValue: null,
    editorTwoValue: null,
  });

  const [editorLengthValidation, setEditorLengthValidation] = useState({
    aboutUsOne: null,
    aboutUsTwo: null,
    aboutUsOneLength: null,
    aboutUsTwoLength: null,
  });

  useEffect(() => {
    setNotification({ success: false, fail: false });
  }, []);

  useEffect(() => {
    if (details.length > 0) {
      let temp = details.find(
        (li) => li.companyLocationId === parseInt(about?.companyName)
      );
      setCompanyDetails(temp);
      let data1 = temp?.vision
        ? htmlDocs(temp?.vision, "editorStateOne")
        : htmlDocs("<p>No data</p>");
      let data2 = temp?.vision1
        ? htmlDocs(temp?.vision1, "editorStateTwo")
        : htmlDocs("<p>No data</p>");
      setContent({
        editorStateOne: EditorState?.createWithContent(data1),
        editorStateTwo: EditorState?.createWithContent(data2),
      });
    }
  }, [about.companyName]);

  console.log(companyDetails, "vision details");

  const handleChange = (e) => {
    if (e.target.type === "file") {
      let file = e.target.files[0];
      // const fileType = about?.type === "image" ? 2097152 : 10485760;
      if (file?.size >= 2097152) {
        const input = document.querySelector("#imageInputFile");
        input.value = "";
        return dispatch(
          showErrorSnackbar(
            "File not supported, Please check the size and type of file",
            "error"
          )
        );
      }
      setAbout({
        ...about,
        [e.target.name]: file,
      });
    } else {
      setAbout({
        ...about,
        [e.target.name]: e.target.value,
      });
    }
  };

  //About us 1 Editor Validation for 500 words
  const editorTextOne = convertToRaw(content.editorStateOne.getCurrentContent())
    .blocks.map((rowText) => {
      return rowText.text;
    })
    .join("");

  //About us 2 Editor Validation for 500 words
  const editorTextTwo = convertToRaw(content.editorStateTwo.getCurrentContent())
    .blocks.map((rowText) => {
      return rowText.text;
    })
    .join("");

  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    
    if (
      editorLengthValidation.aboutUsOneLength ||
      editorLengthValidation.aboutUsTwoLength
      ) {
        return;
      }
      if (editorTextOne.length < 1) {
        setEditorLengthValidation({ aboutUsOne: true });
        return;
      }
      if (editorTextTwo.length < 1) {
        setEditorLengthValidation({ aboutUsTwo: true });
        return;
      }
      
      let data = new FormData();
      data.append("selectCompany", parseInt(about.companyName));
      data.append("file0", about.filePicture);
      data.append("vision", editorValue.editorOneValue);
      data.append("vision1", editorValue.editorTwoValue);
      
      const res = await companyVisionPost(data);
      if (res.status === 200) {
      setLoading(false);
      setNotification({ success: true, fail: false });
      window.location.reload();
    } else {
      setNotification({ success: false, fail: true });
    }
    setLoading(false);
  };
  useEffect(() => {
    dispatch(updateNavbarHeading("Company Vision"));
    setNotification({ success: false, fail: false });
  }, []);

  useEffect(() => {
    setEditorValue({
      ...editorValue,
      editorOneValue: draftToHtml(
        convertToRaw(content.editorStateOne.getCurrentContent())
      ),
    });
    if (editorTextOne.length > 500) {
      setEditorLengthValidation({ aboutUsOneLength: true });
    } else {
      setEditorLengthValidation({
        aboutUsOneLength: false,
      });
    }
  }, [content.editorStateOne]);

  useEffect(() => {
    setEditorValue({
      ...editorValue,
      editorTwoValue: draftToHtml(
        convertToRaw(content.editorStateTwo.getCurrentContent())
      ),
    });
    if (editorTextTwo.length > 500) {
      setEditorLengthValidation({ aboutUsTwoLength: true });
    } else {
      setEditorLengthValidation({ aboutUsTwoLength: false });
    }
  }, [content.editorStateTwo]);

  const onEditorStateChangeOne = (editorState) => {
    setContent({
      ...content,
      editorStateOne: editorState,
    });
  };
  const onEditorStateChangeTwo = (editorState) => {
    setContent({
      ...content,
      editorStateTwo: editorState,
    });
  };

  const labels = ["Vison Picture", "Edit Vision"];

  
  if (loading)
    return (
        <Loader open="true" />
    );
  return (
    <form onSubmit={handleSubmit}>
      <div className="content">
        <div className="content-wrapper">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <div className="card">
                  <div className="card-header">
                    <h4 className="card-title all-card-headers Note">
                      <strong>Note:</strong> Kindly upload the image in JPEG/PNG
                      only and Image should not be more than 2MB.
                    </h4>
                  </div>
                  <div className="card-body">
                    <section>
                      <div className="row">
                        <div className="col-sm-4">
                          <div className="form-group">
                            <label
                              htmlFor="companyName"
                              className="input-labels"
                            >
                              Company
                            </label>
                            <sup className="fa fa-asterisk asterisk"></sup>
                            <select
                              onChange={handleChange}
                              name="companyName"
                              className="form-control"
                              required
                            >
                              <option selected disabled value="">
                                Select Company
                              </option>
                              {companyList.map((company) => (
                                <option value={company.companyId} key={company}>
                                  {company.companyName}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                        <div className="col-sm-4">
                          <div className="form-group">
                            <label className="input-labels">
                              Vision Image
                            </label>
                            <sup className="fa fa-asterisk asterisk"></sup>
                            {/* <input
                              onChange={handleChange}
                              className="form-control"
                              name="filePicture"
                              type="file"
                              accept=".jpg, .jpeg, .png"
                              required
                            /> */}
                            <input
                              type="file"
                              class="form-control"
                              id="imageInputFile"
                              name="filePicture"
                              accept=".jpg, .jpeg, .png"
                              required
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                      </div>
                    </section>
                    {editorLengthValidation.aboutUsOneLength && (
                      <p style={{ color: "red" }}>
                        Please Enter 500 words only
                      </p>
                    )}
                    {editorLengthValidation?.aboutUsOne && (
                      <p style={{ color: "red" }}>
                        This is editor is mandatory
                      </p>
                    )}
                    <div className="card">
                      <div className="card-header">
                        <h3 className="card-title bold-card-header">
                          Vision
                        </h3>
                        <sup className="fa fa-asterisk asterisk"></sup>
                      </div>
                      <div className="card-body">
                        <Editor
                          editorState={content.editorStateOne}
                          toolbarClassName="toolbarClassName"
                          wrapperClassName="wrapperClassName"
                          editorClassName="editorClassName"
                          onEditorStateChange={onEditorStateChangeOne}

                        />
                      </div>
                    </div>
                    {editorLengthValidation.aboutUsTwoLength && (
                      <p>Please Enter 500 words only</p>
                    )}
                    {editorLengthValidation.aboutUsTwo && (
                      <p style={{ color: "red" }}>
                        This is editor is mandatory
                      </p>
                    )}
                    <div className="card">
                      <div className="card-header">
                        <h3 className="card-title bold-card-header">
                          Vision 2
                        </h3>
                        <sup className="fa fa-asterisk asterisk"></sup>
                      </div>
                      <div className="card-body">
                        <Editor
                          editorState={content.editorStateTwo}
                          toolbarClassName="toolbarClassName"
                          wrapperClassName="wrapperClassName"
                          editorClassName="editorClassName"
                          onEditorStateChange={onEditorStateChangeTwo}
                        />
                      </div>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        alignContent: "stretch",
                        justifyContent: "flex-end",
                      }}
                      className="text-left"
                    >
                      <button
                        type="submit"
                        className="btn btn-primary common-btn"
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                  {about.companyName === "" ? (
                    <div className="card-footer pt-3 pb-3 ml-1 mr-1">
                      <h5 className="ml-3">Select a company to see details</h5>
                    </div>
                  ) : (
                    <div className="card-footer">
                      <AudioVideo_Table
                        labels={labels}
                        imgSrc={companyDetails?.visionPic}
                        name="vision"
                        id={companyDetails?.id}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
            {notification.success && (
              <Snackbar msg="Company about added successfully" type="success" />
            )}
            {notification.fail && (
              <Snackbar msg="Something went wrong" type="error" />
            )}
          </div>
        </div>
      </div>
    </form>
  );
}

export default CompanyVision;
