import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import Snackbar from "../../../Components/uiComponets/snackbar/Snackbar";
import { RiDeleteBin6Line } from "react-icons/ri";
import { getTestimonialAction } from "../../../redux/actions/getEmployeeTestimonial";
import { Loader } from "../../../loader/Loader";

let url = `${process.env.REACT_APP_DEV}/api/admin/getEmployeeTestimonialList`;

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    width: "40rem",
  },
  card: {
    marginBottom: "0",
    width: "100%",
  },
}));

export default function TransitionsModal({ id }) {
  // const deleteEmployeeTestimonial = (deleteValue) => {
  //   const config = {
  //     method: "post",
  //     url: `${process.env.REACT_APP_DEV}/api/admin/deleteEmployeeTestimonialbyId`,
  //     data: deleteValue,
  //   };
  //   return axios(config);
  // };
  
  const [loader, setLoader] = useState(false);
  const dispatch = useDispatch();

  const classes = useStyles();

  const [notification, setNotification] = React.useState({
    success: null,
    fail: null,
  });

  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const deleteHandler = (e) => {
    setLoader(true);
    e.preventDefault();
    let deleteTestimonial = {
      id: id,
    };
    axios
    .post(
      `${process.env.REACT_APP_DEV}/api/admin/deleteEmployeeTestimonialbyId`,
      deleteTestimonial
      )
      .then((res) => {
        setLoader(false);
        setNotification({ success: true, fail: false });
        dispatch(getTestimonialAction(url));
        handleClose();
      })
      .catch((err) => {
        setLoader(false);
        setNotification({ success: false, fail: true });
      });
  };

  if (loader)
    return (
        <Loader open="true" />
    );
  return (
    <div>
      <span type="button" onClick={handleOpen}>
        <RiDeleteBin6Line size={20} />
      </span>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <div className={`card ${classes.card}`}>
              <div className="card-header">
                <h3 className="card-title">Are you sure you want to Delete?</h3>
              </div>
              <div className="card-body">
                <form style={{ width: "100%" }}>
                  <section className="text-right d-flex ">
                    <button
                      type="submit"
                      className="btn btn-sm btn-primary text-white"
                      onClick={deleteHandler}
                    >
                      Yes
                    </button>
                    <button
                      onClick={handleClose}
                      type="submit"
                      className="btn btn-sm btn-primary ml-1 text-white"
                    >
                      No
                    </button>
                  </section>
                </form>
                {notification.success && (
                  <Snackbar msg="Leader Deleted successfully" type="success" />
                )}
                {notification.fail && (
                  <Snackbar msg="Something went wrong" type="error" />
                )}
              </div>
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}
