import React, { useState, useEffect } from "react";
import "./SidebarHR.css";
import { /* Link */ NavLink, useLocation } from "react-router-dom";
import createUser from "../../Images/createUser.svg";
import employeeTest from "../../Images/employeeTest.svg";
import dashboard from "../../Images/dashboard.svg";
import { useSelector, useDispatch } from "react-redux";
import { getStorageData } from "../hrServices/getStorageData";
import { getCommonListAction } from "../../redux/actions/getCommonList";
let url = `${process.env.REACT_APP_DEV}/api/tour/getLogo`;

function SidebarHR() {
  const [accessList, setAccessList] = useState(null);
  const { hrRoleList } = useSelector((state) => state.getHrRoleDataByIdReducer);
  const { companyId } = getStorageData();

  //assigning location variable
  const location = useLocation();

  //destructuring pathname from location
  const { pathname } = location;

  //Javascript split method to get the name of the path in array
  const splitLocation = pathname.split("/");
  // console.log(splitLocation);
  console.dir(splitLocation);

  const dispatch = useDispatch();
  useEffect(() => {
    if (hrRoleList) {
      setAccessList(hrRoleList);
    }
    dispatch(getCommonListAction(url));
  }, [hrRoleList]);
  const { loading, error, list } = useSelector(
    (state) => state.getCommonListReducer
  );

  let myarray = list.filter((x) => x.compayId === companyId);

  return (
    <aside className="main-sidebar sidebar-dark-primary elevation-4 HR-sidebar">
      <NavLink
        to="/hr/dashboard"
        href="index3.html"
        className="brand-link hr-logo-link"
      >
        <img src={`data:image/png;base64,${myarray[0]?.logo}`} alt="logo" />
      </NavLink>
      <div className="sidebar">
        <nav className="mt-2">
          <ul
            className="nav nav-pills nav-sidebar flex-column"
            data-widget="treeview"
            role="menu"
            data-accordion="false"
          >
            <li className="nav-item menu-open dashboard-column">
              <NavLink to="/hr/dashboard" className="nav-link">
                <img
                  src={dashboard}
                  className="mr-3 sidebar-icons-color"
                  alt=""
                />
                <p>Dashboard</p>
              </NavLink>
            </li>

            {accessList?.uploadCampusCandidateData && (
              <li className="nav-item">
                <NavLink
                  to="/hr/uploadCandidate"
                  className="nav-link"
                  activeClassName="activeLink"
                >
                  <i class="nav-icon fas fa-file-upload sidebar-icons-color"></i>
                  <p>Upload Candidate</p>
                </NavLink>
              </li>
            )}

            {accessList?.preOfferCandidate && (
              <li
                className={`nav-item ${
                  splitLocation[2] === "preOffer" ||
                  (splitLocation[4] === "offerLetter" &&
                    splitLocation.length === 5)
                    ? "activeLink"
                    : ""
                }`}
              >
                <NavLink
                  to="/hr/preOfferCandidate"
                  className="nav-link"
                  activeClassName="activeLink"
                >
                  <i class="nav-icon fas fa-address-card sidebar-icons-color"></i>
                  <p>Pre-Offer Candidate</p>
                </NavLink>
              </li>
            )}

            <li className="nav-item">
              <a href="#" className="nav-link">
                <i class="nav-icon fas fa-envelope-open-text sidebar-icons-color"></i>
                <p>
                  Offer Candidates
                  <i className="fas fa-angle-right right" />
                </p>
              </a>
              <ul
                className="nav nav-treeview"
                style={{ marginLeft: "-1.8rem" }}
              >
                {accessList?.preOfferCandidate && (
                  <li className={`nav-item sub-maga-links ${
                    // splitLocation[2] === "preOffer" ||
                    (splitLocation[4] === "offerLetter" &&
                      splitLocation.length === 6)
                      ? "activeLink"
                      : ""
                  }`}>
                    <NavLink
                      to="/hr/offerCandidate"
                      activeClassName="activeLink"
                      className="nav-link"
                    >
                      <i class="nav-icon fab fa-accusoft sidebar-icons-color"></i>
                      <p>Offered Candidate</p>
                    </NavLink>
                  </li>
                )}
                {accessList?.preOfferCandidate && (
                  <li className="nav-item sub-maga-links">
                    <NavLink
                      to="/hr/rejectedCandidates"
                      activeClassName="activeLink"
                      className="nav-link"
                    >
                      <i class="nav-icon fas fa-times-circle sidebar-icons-color"></i>
                      <p>Declined Candidate</p>
                    </NavLink>
                  </li>
                )}
              </ul>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#">
                <i class="nav-icon fab fa-flipboard sidebar-icons-color"></i>
                <p>
                  Pre-Onboard
                  <i className="right fas fa-angle-right" />
                </p>
              </a>
              <ul
                className="nav nav-treeview"
                style={{ marginLeft: "-1.8rem" }}
              >
                {accessList?.offeredCandidate && (
                  <li className={`nav-item sub-maga-links ${
                    // splitLocation[2] === "preOffer" ||
                    (splitLocation[3] === "offerCandidateDetails")
                      ? "activeLink"
                      : ""
                  }`}>
                    <NavLink
                      to="/hr/preOnBoard/offeredCandidates"
                      activeClassName="activeLink"
                      className="nav-link"
                    >
                      <img
                        src={employeeTest}
                        alt="/"
                        className="p-1 nav-icon sidebar-icons-color"
                      />
                      <p>Pre-Onboarded Candidates</p>
                    </NavLink>
                  </li>
                )}

                {/* {accessList?.accommodation && (
                  <li className="nav-item sub-maga-links">
                    <Link to="/hr/preOnBoard/accommodation">
                      <i class="fas fa-users mr-2 sidebar-icons-color"></i>
                      <p>Accommodation</p>
                    </Link>
                  </li>
                )} */}
                {accessList?.docVerification && (
                  <li className="nav-item sub-maga-links">
                    <NavLink
                      to="/hr/preOnBoard/documentVerification"
                      activeClassName="activeLink"
                      className="nav-link"
                    >
                      <i class="nav-icon fas fa-file-word sidebar-icons-color"></i>
                      <p>Document Verification</p>
                    </NavLink>
                  </li>
                )}
                {accessList?.formVerificaion && (
                  <li className="nav-item sub-maga-links">
                    <NavLink
                      to="/hr/preOnBoard/formVerification"
                      activeClassName="activeLink"
                      className="nav-link"
                    >
                      <i class="nav-icon fas fa-align-justify sidebar-icons-color"></i>
                      <p>Form Verification</p>
                    </NavLink>
                  </li>
                )}
                {accessList?.readyToHire && (
                  <li className="nav-item sub-maga-links">
                    <NavLink
                      to="/hr/preOnBoard/readyToHire"
                      activeClassName="activeLink"
                      className="nav-link"
                    >
                      <i class="nav-icon fas fa-user-circle sidebar-icons-color"></i>
                      <p>Ready To Hire Candidates</p>
                    </NavLink>
                  </li>
                )}
                {accessList?.hireCandidate && (
                  <li className="nav-item sub-maga-links">
                    <NavLink
                      to="/hr/preOnBoard/hiredCandidates"
                      activeClassName="activeLink"
                      className="nav-link"
                    >
                      <i class="nav-icon fas fa-user-shield sidebar-icons-color"></i>
                      <p>Hired Candidates</p>
                    </NavLink>
                  </li>
                )}
                {/* {accessList?.assetManagement && (
                  <li className="nav-item sub-maga-links">
                    <Link to="/hr/preOnBoard/assetsManagement">
                      <i class="fas fa-suitcase sidebar-icons-color"></i>
                      <p>Assets Management</p>
                    </Link>
                  </li>
                )} */}
              </ul>
            </li>
            {/* <li className="nav-item">
              <a href="#" className="nav-link">
                <img className="mr-3" src={Employee} alt="" />
                <p>
                  Onboard
                  <i className="right fas fa-angle-right" />
                </p>
              </a>
              <ul
                className="nav nav-treeview"
                style={{ marginLeft: "-1.2rem" }}
              >
                {accessList?.onboarding && (
                  <li className="nav-item sub-maga-links">
                    <Link to="onboarding-employee">
                      <img
                        src={employeeTest}
                        alt="/"
                        className="mr-2 sidebar-icons-color"
                      />
                      <p>Onboard Employees</p>
                    </Link>
                  </li>
                )}
                {accessList?.onboardingPlan && (
                  <li className="nav-item sub-maga-links">
                    <Link to="/create-onboarding-plan">
                      <i class="fas fa-users mr-2 sidebar-icons-color"></i>
                      <p>Create Onboarding Plan</p>
                    </Link>
                  </li>
                )}
                <li className="nav-item sub-maga-links">
                  <Link to="/create-faq">
                    <i class="fas fa-user-circle mr-2 sidebar-icons-color"></i>
                    <p>Create FAQ</p>
                  </Link>
                </li>
                <li className="nav-item sub-maga-links">
                  <Link to="/create-survey">
                    <i class="fas fa-list-alt  mr-2 sidebar-icons-color"></i>
                    <p>Create Survey</p>
                  </Link>
                </li>
              </ul>
            </li> */}
            {/* {accessList?.bgv && (
              <li className="nav-item">
                <Link to="/hr/backgroundVerification" className="nav-link">
                  <i class="fas fa-user-check sidebar-icons-color"></i>
                  <p>Background Verification</p>
                </Link>
              </li>
            )} */}
            <li className="nav-item">
              <a href="#" className="nav-link">
                <i class="nav-icon fas fa-user-tie sidebar-icons-color"></i>
                <p>
                  Candidate Archive
                  <i className="fas fa-angle-right right" />
                </p>
              </a>
              <ul
                className="nav nav-treeview"
                style={{ marginLeft: "-1.8rem" }}
              >
                <li className="nav-item sub-maga-links">
                  <NavLink
                    to="/hr/candidateArchive/candidateSetting"
                    activeClassName="activeLink"
                    className="nav-link"
                  >
                    <img src={createUser} alt="" className="nav-icon p-1" />
                    <p>Candidate Setting</p>
                  </NavLink>
                </li>
                <li className="nav-item sub-maga-links">
                  <NavLink
                    to="/hr/candidateArchive/referredCandidates"
                    activeClassName="activeLink"
                    className="nav-link"
                  >
                    <i class="nav-icon fas fa-recycle sidebar-icons-color"></i>
                    <p>Referred Candidates</p>
                  </NavLink>
                </li>
                <li className="nav-item sub-maga-links">
                  <NavLink
                    to="/hr/candidateArchive/cancelledCandidate"
                    activeClassName="activeLink"
                    className="nav-link"
                  >
                    <i class="nav-icon far fa-window-close sidebar-icons-color"></i>
                    <p>Cancelled Candidates</p>
                  </NavLink>
                </li>
              </ul>
            </li>
            {accessList?.massNotification && (
              <li className="nav-item">
                <NavLink
                  to="/hr/reachOutCandidates"
                  activeClassName="activeLink"
                  className="nav-link"
                >
                  <i class="nav-icon fas fa-chalkboard-teacher sidebar-icons-color "></i>
                  <p>Reach-out Candidate</p>
                </NavLink>
              </li>
            )}
            {/* !⚠ why there are two same elements for reach-out candidate */}
            <li className="nav-item">
              <NavLink
                to="/hr/reachOutCandidates"
                className="nav-link"
                activeClassName="activeLink"
              >
                <i class="nav-icon fas fa-chalkboard-teacher sidebar-icons-color "></i>
                <p>Reach-out Candidate</p>
              </NavLink>
            </li>
            {/* <li className="nav-item">
              <Link to="/integration-data" className="nav-link">
                <img className="mr-3" src={Employee} alt="" />
                <p>Integration Data</p>
              </Link>
            </li> */}
            <li className="nav-item">
              <a href="#" className="nav-link">
                <i class="nav-icon fas fa-chart-bar sidebar-icons-color"></i>
                <p>
                  Analytics & Reports
                  <i className="fas fa-angle-right right" />
                </p>
              </a>
              <ul
                className="nav nav-treeview"
                style={{ marginLeft: "-1.8rem" }}
              >
                {accessList?.generateReport && (
                  <li className="nav-item sub-maga-links">
                    <NavLink
                      to="/hr/analytics/generateReport"
                      activeClassName="activeLink"
                      className="nav-link"
                    >
                      <img src={createUser} alt="" className=" p-1 nav-icon" />
                      <p>Generate Reports</p>
                    </NavLink>
                  </li>
                )}
                <li className={`nav-item sub-maga-links ${
                  splitLocation[3] === "recruiterData" ||
                  (splitLocation[3] === "positionsClosed")
                    ? "activeLink"
                    : ""
                }`}>
                  <NavLink
                    to="/hr/analytics/analyticalGraphs"
                    activeClassName="activeLink"
                    className="nav-link"
                  >
                    <i class="nav-icon fas fa-recycle sidebar-icons-color"></i>
                    <p>Analytics Graphs</p>
                  </NavLink>
                </li>
              </ul>
            </li>
            <li className="nav-item">
              <a href="#" className="nav-link">
                <i class="nav-icon fas fa-info sidebar-icons-color "></i>
                <p>
                  Help & Support
                  <i className="fas fa-angle-right right sidebar-icons-color"></i>
                </p>
              </a>
              <ul
                className="nav nav-treeview"
                style={{ marginLeft: "-1.8rem" }}
              >
                <li className="nav-item sub-maga-links">
                  <NavLink
                    to="/hr/help/queries"
                    activeClassName="activeLink"
                    className="nav-link"
                  >
                    <img src={createUser} alt="" className="p-1 nav-icon" />
                    <p>Queries</p>
                  </NavLink>
                </li>
                <li className="nav-item sub-maga-links">
                  <NavLink
                    to="/hr/help/faq"
                    activeClassName="activeLink"
                    className="nav-link"
                  >
                    <i class="nav-icon fas fa-question-circle sidebar-icons-color"></i>
                    <p>FAQ's</p>
                  </NavLink>
                </li>
              </ul>
            </li>
          </ul>
        </nav>
        {/* /.sidebar-menu */}
      </div>
      {/* /.sidebar */}
    </aside>
  );
}

export default SidebarHR;
