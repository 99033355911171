import React, { useEffect } from "react";
import { updateNavbarHeading } from "../../redux/actions/uiActions";
import { useDispatch } from "react-redux";
import "./Dashboard.css";
import image27 from "../../Images/dash3.png";
import image345 from "../../Images/dash1.png";
import image2222 from "../../Images/dash2.png";
const Dashboard = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(updateNavbarHeading("Dashboard"));
  }, []);
  return (
    <>
      <div className="content">
        <div className="content-wrapper">
          <div className="container-fluid">
            <div className="card ">
              <div className="row">
                <div className="col-md-6">
                  <div
                    className="card-img-overlay"
                    style={{ position: "relative" }}
                  >
                    <h5 className="card-title dash-card-title">You Can Do</h5>
                    <br />
                    <h5 className="employee ">Employee Management</h5>
                    <br />
                    <p className="card-text pb-1 pt-1 text-black">
                      You can arrange Employee Testimonials, <br />
                      Buddies, Key Spocs, Task Management, <br />
                      Compliance and Gamiflation
                    </p>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="Image-27 ">
                    {/* <img className="card-img-top" src={image27} alt="Dist Photo 3" /> */}
                  </div>
                  <div className="Image-345 ">
                    {" "}
                    <img
                      className="card-img-top"
                      src={image345}
                      alt="Dist Photo 3"
                    />
                  </div>
                  {/* <div className="Image-2222 ">
              {" "}
              <img
                className="card-img-top"
                src={image2222}
                alt="Dist Photo 3"
              />
            </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Dashboard;
