import { commonLister } from "../constants/constants";
const { LOADING, COMMON_LIST_SUCCESS, COMMON_LIST_FAIL } = commonLister;

const intialState = {
  loading: null,
  list: [],
  error: "",
};

export const getCommonListReducer = (state = intialState, action) => {
  switch (action.type) {
    case LOADING:
      return {
        ...state,
        loading: true,
      };
    case COMMON_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        list: action.payload,
      };
    case COMMON_LIST_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};
