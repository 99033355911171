import React, { useEffect, useState, useRef } from "react";
import "./MyCompanyLogo.css";
import { useDispatch, useSelector } from "react-redux";
import {
  updateNavbarHeading,
  showErrorSnackbar,
} from "../../redux/actions/uiActions";
import { getCommonListAction } from "../../redux/actions/getCommonList";
import axios from "axios";
import PaginationTable from "../adminUi/PaginationTable";
import { columns } from "./columns";
import Snackbar from "../../Components/uiComponets/snackbar/Snackbar";

import { Loader } from "../../loader/Loader";

let url = `${process.env.REACT_APP_DEV}/api/tour/getLogo`;
const MyCompanyLogo = () => {
  const dispatch = useDispatch();

  const [loader, setLoader] = useState(false);

  const { companyList } = useSelector((state) => state.getCompanyListReducer);
  useEffect(() => {
    dispatch(updateNavbarHeading("Company Logo"));
  }, []);
  const { loading, error, list } = useSelector(
    (state) => state.getCommonListReducer
  );
  const [gallery, setGallery] = useState({
    companyId: "",
    file: "",
  });
  const [notification, setNotification] = React.useState({
    success: null,
    fail: null,
  });
  useEffect(() => {
    dispatch(updateNavbarHeading("Company Logo"));
    dispatch(getCommonListAction(url));
    setNotification({ success: false, fail: false });
  }, []);

  const imageResetRef = useRef();

  const resetImageInput = () => {
    imageResetRef.current.value = "";
  };

  const handleChange = (e) => {
    if (e.target.type === "file") {
      let file = e.target.files[0];
      const fileType = gallery?.type === "image" ? 2097152 : 10485760;
      if (file?.size >= 2097152) {
        const input = document.querySelector("#imageInputFile");
        input.value = "";
        return dispatch(
          showErrorSnackbar(
            "File not supported, Please check the size and type of file",
            "error"
          )
        );
      }
      setGallery({
        ...gallery,
        [e.target.name]: file,
      });
    } else {
      setGallery({
        ...gallery,
        [e.target.name]: e.target.value,
      });
    }
  };
  const handleSubmit = (e) => {
    setLoader(true);
    e.preventDefault();
    let data = new FormData();
    data.append("selectCompany", parseInt(gallery.companyId));
    data.append("file0", gallery.file);
    axios
      .post(`${process.env.REACT_APP_DEV}/api/logo`, data)
      .then((res) => {
        setLoader(false);
        setNotification({ success: true, fail: false });
        dispatch(getCommonListAction(url));
        setGallery({ companyId: "", file: "" });
        resetImageInput();
      })
      .catch((err) => {
        setLoader(false);
        setNotification({ success: false, fail: true });
      });
  };
  // if (loading) {
  //   return <div>Loading...</div>;
  // }

  // console.log(list);

  let data =
    list.length > 0 &&
    list.map((li, idx) => {
      return {
        idx: idx + 1,
        companyName:
          li.compayId === 156
            ? "Namdhari Seeds Pvt. Ltd."
            : li.compayId === 157
            ? "Namdhari AGRO Fresh"
            : li.compayId === 158
            ? "Namdhari Dairy Tales"
            : li.compayId === 159
            ? "SJS Healthcare Ltd."
            : "No Company Found",
        logo: (
          <img
            style={{ width: "2rem", height: "2rem" }}
            src={`data:image/png;base64,${li.logo}`}
            alt="logo"
          />
        ),
      };
    });

  // console.log(data);

  if (loader) return <Loader open="true" />;
  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className="content">
          <div className="content-wrapper">
            <div className="container-fluid">
              <div className="Rectangle-My-company-logo">
                <div className="row">
                  <div className="col-lg-12 col-md-12 col-sm-12">
                    <p className="Note">
                      <b>Note:</b> Kindly upload the image in JPEG/PNG only,
                      Image should not be more than 2MB.
                    </p>
                  </div>
                </div>
                <form>
                  <div class="form-row row">
                    <div class="col-md-6">
                      <div>
                        <div></div>
                        <div className="form-group">
                          <div className="required">
                            <label className="cmpny-logo input-labels ">
                              Company
                            </label>
                          </div>
                          <select
                            name="companyId"
                            onChange={handleChange}
                            className="form-control"
                            required
                            value={gallery.companyId}
                          >
                            <option value="" selected disabled>
                              Select-Company Location
                            </option>
                            {companyList.map((company) => (
                              <option value={company.companyId} key={company}>
                                {company.companyName}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div className="required">
                        <label className="upload-image-cmpny-logo input-labels ">
                          Logo Image
                        </label>
                      </div>

                      <div>
                        {/* <input
                          type="file"
                          class="form-control"
                          id="exampleInputFile"
                          name="file"
                          onChange={handleChange}
                          accept=".jpg, .jpeg, .png"
                          ref={imageResetRef}
                          required
                        /> */}
                        <input
                          type="file"
                          class="form-control"
                          id="imageInputFile"
                          name="file"
                          accept=".jpg, .jpeg, .png"
                          required
                          onChange={handleChange}
                          ref={imageResetRef}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form-row">
                    <button
                      type="Submit"
                      class="btn4 btn-primary  mt-2"
                      onClick={handleSubmit}
                    >
                      Submit
                    </button>
                  </div>
                </form>
              </div>

              {list.length > 0 && (
                <PaginationTable columnsArr={columns} dataArr={data} />
              )}
              {notification.success && (
                <Snackbar msg="Logo addedd successfully" type="success" />
              )}
              {notification.fail && (
                <Snackbar msg="Something went wrong" type="error" />
              )}
            </div>
          </div>
        </div>
      </form>
    </>
  );
};
export default MyCompanyLogo;
