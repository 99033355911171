import { postEmployeeTestimonials } from "../constants/constants";

const { LOADING, POST_TESTIMONIALS_SUCCESS, POST_TESTIMONIALS_FAIL } =
  postEmployeeTestimonials;

export const employeeTestimonialRequest = () => {
  return {
    type: LOADING,
  };
};

export const employeeTestimonialSuccess = (user) => {
  return {
    type: POST_TESTIMONIALS_SUCCESS,
    payload: user,
  };
};

export const employeeTestimonialFail = (error) => {
  return {
    type: POST_TESTIMONIALS_FAIL,
    payload: error,
  };
};
