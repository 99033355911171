import React, { useEffect, useState } from "react";
import { EditorState, convertToRaw, convertFromHTML } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import AudioVideoTable from "./AudioVideoTable";
import { useDispatch, useSelector } from "react-redux";
import {
  updateNavbarHeading,
  showErrorSnackbar,
} from "../../redux/actions/uiActions";
import "./companyAbout.css";
import draftToHtml from "draftjs-to-html";
import Snackbar from "../../Components/uiComponets/snackbar/Snackbar";
import { companyAboutPost } from "../adminMainServices/companyServices/companyServices";
import { htmlDocs } from "./draftToHtml";

import { Loader } from "../../loader/Loader";

function CompanyAvout() {
  const dispatch = useDispatch();
  const { companyList } = useSelector((state) => state.getCompanyListReducer);
  const [companyDetails, setCompanyDetails] = React.useState(null);
  
  const [loading, setLoading] = useState(false);

  const [notification, setNotification] = React.useState({
    success: null,
    fail: null,
  });
  const [content, setContent] = React.useState({
    editorStateOne: EditorState.createEmpty(),
    editorStateTwo: EditorState.createEmpty(),
    editorStateThree: EditorState.createEmpty(),
  });
  const [editorValue, setEditorValue] = React.useState({
    editorOneValue: null,
    editorTwoValue: null,
    editorThreeValue: null,
  });
  const AboutObj = {
    filePicture: "",
    fileVideo: "",
    companyName: "",
  };
  const [about, setAbout] = React.useState(AboutObj);
  const [editorLengthValidation, setEditorLengthValidation] = useState({
    aboutUs: null,
    aboutUsOneLength: null,
    aboutUsTwoLength: null,
    aboutUsThreeLength: null,
  });

  const { details } = useSelector(
    (state) => state.getCompanyDetailsListReducer
  );

  useEffect(() => {
    dispatch(updateNavbarHeading("Company About"));
    setNotification({ success: false, fail: false });
  }, []);

  useEffect(() => {
    if (details.length > 0) {
      let temp = details.find(
        (li) => li.companyLocationId === parseInt(about?.companyName)
      );
      setCompanyDetails(temp);
      let data1 = temp?.aboutUs
        ? htmlDocs(temp?.aboutUs, "editorStateOne")
        : htmlDocs("<p>No data</p>");

      let data2 = temp?.aboutUs2
        ? htmlDocs(temp?.aboutUs2, "editorStateTwo")
        : htmlDocs("<p>No data</p>");
      let data3 = temp?.aboutUs3
        ? htmlDocs(temp?.aboutUs3, "editorStateThree")
        : htmlDocs("<p>No data</p>");

      setContent({
        editorStateOne: EditorState?.createWithContent(data1),
        editorStateTwo: EditorState?.createWithContent(data2),
        editorStateThree: EditorState?.createWithContent(data3),
      });
      
      let editorStateOne = EditorState?.createWithContent(data1);
      let editorStateTwo = EditorState?.createWithContent(data2);
      let editorStateThree = EditorState?.createWithContent(data3);
      
      setEditorValue({
        editorOneValue: draftToHtml(convertToRaw(editorStateOne?.getCurrentContent()) ),
        editorTwoValue: draftToHtml(convertToRaw(editorStateTwo?.getCurrentContent()) ),
        editorThreeValue: draftToHtml(convertToRaw(editorStateThree?.getCurrentContent()) ),
      })
    }
  }, [about.companyName]);

  const handleChange = (e) => {
    if (e.target.type === "file") {
      let file = e.target.files[0];
      // const fileType = about?.type === "image" ? 2097152 : 10485760;
      if (file?.size >= 2097152) {
        const input = document.querySelector("#imageInputFile");
        input.value = "";
        return dispatch(
          showErrorSnackbar(
            "File not supported, Please check the size and type of file",
            "error"
          )
        );
      }
      setAbout({
        ...about,
        [e.target.name]: file,
      });
    } else {
      setAbout({
        ...about,
        [e.target.name]: e.target.value,
      });
    }
  };

  const handleChangeVideo = (e) => {
    if (e.target.type === "file") {
      let file = e.target.files[0];
      // const fileType = about?.type === "image" ? 2097152 : 10485760;
      if (file?.size >= 10485760) {
        const input = document.querySelector("#imageInputVideo");
        input.value = "";
        return dispatch(
          showErrorSnackbar(
            "File not supported, Please check the size and type of file ",
            "error"
          )
        );
      }
      setAbout({
        ...about,
        [e.target.name]: file,
      });
    } else {
      setAbout({
        ...about,
        [e.target.name]: e.target.value,
      });
    }
  };

  const labels = ["Overview Picture", "Overview Video", "Action"];

  //About us 1 Editor Validation for 500 words
  const editorTextOne = convertToRaw(content.editorStateOne.getCurrentContent())
    .blocks.map((rowText) => {
      return rowText.text;
    })
    .join("");

  //About us 2 Editor Validation for 500 words
  const editorTextTwo = convertToRaw(content.editorStateTwo.getCurrentContent())
    .blocks.map((rowText) => {
      return rowText.text;
    })
    .join("");

  //About us 3 Editor Validation for 500 words
  const editorTextThree = convertToRaw(
    content.editorStateThree.getCurrentContent()
  )
    .blocks.map((rowText) => {
      return rowText.text;
    })
    .join("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (
      editorLengthValidation.aboutUsOneLength ||
      editorLengthValidation.aboutUsTwoLength ||
      editorLengthValidation.aboutUsThreeLength
      ) {
        return;
      }
      if (editorTextOne.length < 1) {
      setEditorLengthValidation({ aboutUs: true });
      return;
    }

    let data = new FormData();
    data.append("selectCompany", parseInt(about.companyName));
    data.append("file0", about.filePicture);
    data.append("file1", about.fileVideo);
    data.append("aboutUs", editorValue.editorOneValue);
    data.append("aboutUs2", editorValue.editorTwoValue);
    data.append("aboutUs3", editorValue.editorThreeValue);
    const res = await companyAboutPost(data);
    if (res.status === 200) {
      setLoading(false);
      setNotification({ success: true, fail: false });
      window.location.reload();
    } else {
      setNotification({ success: false, fail: true });
    }
    setLoading(false);
  };

  useEffect(() => {
    setEditorValue({
      ...editorValue,
      editorOneValue: draftToHtml(
        convertToRaw(content?.editorStateOne?.getCurrentContent())
      ),
    });
    if (editorTextOne.length > 500) {
      setEditorLengthValidation({ aboutUsOneLength: true });
    } else {
      setEditorLengthValidation({
        aboutUsOneLength: false,
      });
    }
  }, [content.editorStateOne]);

  useEffect(() => {
    setEditorValue({
      ...editorValue,
      editorTwoValue: draftToHtml(
        convertToRaw(content?.editorStateTwo?.getCurrentContent())
      ),
    });
    if (editorTextTwo.length > 500) {
      setEditorLengthValidation({ aboutUsTwoLength: true });
    } else {
      setEditorLengthValidation({ aboutUsTwoLength: false });
    }
  }, [content.editorStateTwo]);

  useEffect(() => {
    setEditorValue({
      ...editorValue,
      editorThreeValue: draftToHtml(
        convertToRaw(content?.editorStateThree?.getCurrentContent())
      ),
    });
    if (editorTextThree.length > 500) {
      setEditorLengthValidation({ aboutUsThreeLength: true });
    } else {
      setEditorLengthValidation({ aboutUsThreeLength: false });
    }
  }, [content.editorStateThree]);
  const onEditorStateChangeOne = (editorState) => {
    setContent({
      ...content,
      editorStateOne: editorState,
    });
  };
  const onEditorStateChangeTwo = (editorState) => {
    setContent({
      ...content,
      editorStateTwo: editorState,
    });
  };
  const onEditorStateChangeThree = (editorState) => {
    setContent({
      ...content,
      editorStateThree: editorState,
    });
  };

  
  if (loading)
    return (
        <Loader open="true" />
    );
  return (
    <form onSubmit={handleSubmit}>
      <div className="content">
        <div className="content-wrapper">
          <div className="container-fluid">
            <div className="card">
              <div className="card-header">
                <p className="card-title all-card-headers Note">
                  <strong>Note: </strong>
                  Kindly upload the image in JPEG/PNG only, Image should not be
                  more than 2MB and the vide in MP4 format only, Video size
                  should not be more than 10MB.
                </p>
              </div>
              <div className="card-body">
                <section>
                  <div className="row">
                    <div className="col-sm-4">
                      <div className="form-group">
                        <label
                          htmlFor="companyName"
                          className="input-labels input-label-position"
                        >
                          Company 
                        </label>
                        <sup className="fa fa-asterisk asterisk"></sup>
                        <select
                          onChange={handleChange}
                          name="companyName"
                          className="form-control"
                          required
                        >
                          <option value="" disabled selected>
                            Select Company
                          </option>
                          {companyList.map((company) => (
                            <option value={company.companyId} key={company}>
                              {company.companyName}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="col-sm-4">
                      <div className="form-group">
                        <label className="input-labels">Overview Image</label>
                        <sup className="fa fa-asterisk asterisk"></sup>
                        {/* <input
                          onChange={handleChange}
                          className="form-control"
                          type="file"
                          required
                          name="filePicture"
                          accept=".jpg, .jpeg, .png"
                        /> */}
                        <input
                          type="file"
                          class="form-control"
                          id="imageInputFile"
                          name="filePicture"
                          accept=".jpg, .jpeg, .png"
                          required
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="col-sm-4">
                      <div className="form-group">
                        <label className="input-labels">Overview Video</label>
                        <sup className="fa fa-asterisk asterisk"></sup>
                        {/* <input
                          onChange={handleChangeVideo}
                          className="form-control"
                          type="file"
                          required
                          name="fileVideo"
                          accept=".mp4"
                        /> */}
                        <input
                          type="file"
                          class="form-control"
                          id="imageInputVideo"
                          name="fileVideo"
                          accept=".mp4"
                          required
                          onChange={handleChangeVideo}
                        />
                      </div>
                    </div>
                  </div>
                </section>
                {editorLengthValidation.aboutUsOneLength && (
                  <p style={{ color: "red" }}>Please Enter 500 words only</p>
                )}
                {editorLengthValidation.aboutUs && (
                  <p style={{ color: "red" }}>This is editor is mandatory</p>
                )}
                <div className="card editor-card">
                  <div className="card-header">
                    <h3 className="card-title bold-card-header ">
                      About Us 1
                    </h3>
                    <sup className="fa fa-asterisk asterisk"></sup>
                  </div>
                  <div className="card-body">
                    <Editor
                      editorState={content.editorStateOne}
                      toolbarClassName="toolbarClassName"
                      wrapperClassName="wrapperClassName"
                      editorClassName="editorClassName"
                      onEditorStateChange={onEditorStateChangeOne}
                    />
                  </div>
                </div>
                {editorLengthValidation.aboutUsTwoLength && (
                  <p>Please Enter 500 words only</p>
                )}
                <div className="card  editor-card">
                  <div className="card-header">
                    <h3 className="card-title bold-card-header ">About Us 2</h3>
                  </div>
                  <div className="card-body">
                    <Editor
                      editorState={content.editorStateTwo}
                      toolbarClassName="toolbarClassName"
                      wrapperClassName="wrapperClassName"
                      editorClassName="editorClassName"
                      onEditorStateChange={onEditorStateChangeTwo}
                    />
                  </div>
                </div>
                {editorLengthValidation.aboutUsThreeLength && (
                  <p style={{ color: "red" }}>Please Enter 500 words only</p>
                )}
                <div className="card  editor-card">
                  <div className="card-header">
                    <h3 className="card-title bold-card-header ">About Us 3</h3>
                  </div>
                  <div className="card-body">
                    <Editor
                      editorState={content.editorStateThree}
                      toolbarClassName="toolbarClassName"
                      wrapperClassName="wrapperClassName"
                      editorClassName="editorClassName"
                      onEditorStateChange={onEditorStateChangeThree}
                    />
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignContent: "stretch",
                    justifyContent: "flex-end",
                  }}
                  className="text-left mt-5"
                >
                  <button className="btn btn-primary common-btn" type="submit">
                    Submit
                  </button>
                </div>
              </div>
              {about.companyName === "" ? (
                <div className="card-footer pt-3 pb-3 ml-1 mr-1">
                  <h5 className="ml-3">Select a company to see details</h5>
                </div>
              ) : (
                <div className="card-footer">
                  <AudioVideoTable
                    labels={labels}
                    imgSrc={companyDetails?.overviewPicture}
                    videoSrc={companyDetails?.overviewVideo}
                    name="about"
                    id={companyDetails?.id}
                  />
                </div>
              )}
            </div>
            {notification.success && (
              <Snackbar msg="Company about added successfully" type="success" />
            )}
            {notification.fail && (
              <Snackbar msg="Something went wrong" type="error" />
            )}
          </div>
        </div>
      </div>
    </form>
  );
}

export default CompanyAvout;
