import axios from "axios";

export const editbudddies = (Buddie) => {
    const config = {
      method: "post",
      url: `${process.env.REACT_APP_DEV}/api/editBuddieInfo`,
      data: {editBuddie:Buddie},
    };
    return axios(config);
  };
