import { createRole } from "../constants/constants";

const { LOADING, CREATE_USER_SUCCESS, CREATE_USER_FAIL } = createRole;

export const createUserRequest = () => {
  return {
    type: LOADING,
  };
};

export const createUserSuccess = (user) => {
  return {
    type: CREATE_USER_SUCCESS,
    payload: user,
  };
};

export const createUserFail = (error) => {
  return {
    type: CREATE_USER_FAIL,
    payload: error,
  };
};

// export function createUserAction(role) {
//   return (dispatch) => {
//     dispatch(createUserRequest());
//     axios
//       .post(url, role)
//       .then((res) => {
//         dispatch(createUserSuccess(res.data));
//       })
//       .catch((err) => {
//         dispatch(createUserFail(err));
//       });
//   };
// }
