import { getRoles } from "../constants/constants";
const { LOADING, USER_LIST_SUCCESS, USER_LIST_FAIL } = getRoles;

const intialState = {
  loading: null,
  roleList: [],
  error: "",
};

export const getHrRolesReducer = (state = intialState, action) => {
  switch (action.type) {
    case LOADING:
      return {
        ...state,
        loading: true,
      };
    case USER_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        roleList: action.payload,
      };
    case USER_LIST_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};
