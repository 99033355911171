import React from "react";

function SearchTable({ filter, setFilter }) {
  return (
    <div className="form-group d-flex align-items-center">
      <strong>Search:</strong>
      <input
        className="form-control w-25 ml-2"
        value={filter}
        onChange={(e) => setFilter(e.target.value)}
        placeholder = "search..."
      />
    </div>
  );
}

export default SearchTable;
