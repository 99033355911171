import { getCompanyDetails } from "../constants/constants";
const {
  LOADING,
  GET_COMPANY_DETAILS_LIST_SUCCESS,
  GET_COMPANY_DETAILS_LIST_FAIL,
} = getCompanyDetails;

const intailState = {
  loading: null,
  details: [],
  error: "",
};

export const getCompanyDetailsListReducer = (state = intailState, action) => {
  switch (action.type) {
    case LOADING:
      return {
        ...state,
        loading: true,
      };
    case GET_COMPANY_DETAILS_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        details: action.payload,
      };
    case GET_COMPANY_DETAILS_LIST_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};
