import axios from "axios";

export const editCompanyTestimonials = (Testimonial) => {
    const config = {
      method: "post",
      url: `${process.env.REACT_APP_DEV}/api/admin/editTestimonialInfo`,
      data: { editTestimonial:Testimonial},
    };
    return axios(config);
  };
