import axios from "axios";
import { getBuddies } from "../constants/constants";
const { LOADING, BUDDIES_LIST_SUCCESS, BUDDIES_LIST_FAIL } = getBuddies;

let url = `${process.env.REACT_APP_DEV}/api/getNamdhariBuddie`;
let url2 = `${process.env.REACT_APP_DEV}/api/getKeySpocsById`;

const getBuddiesRequest = () => {
  return {
    type: LOADING,
  };
};

const getBuddiesSuccess = (list) => {
  return {
    type: BUDDIES_LIST_SUCCESS,
    payload: list,
  };
};
const getBuddiesFail = (error) => {
  return {
    type: BUDDIES_LIST_FAIL,
    payload: error,
  };
};


//key spocks
const getSpocksRequest = () => {
  return {
    type: LOADING,
  };
};

const getSpocksSuccess = (list) => {
  return {
    type: BUDDIES_LIST_SUCCESS,
    payload: list,
  };
};
const getSpocksFail = (error) => {
  return {
    type: BUDDIES_LIST_FAIL,
    payload: error,
  };
};

export function getBuddiesAction() {
  return (dispatch) => {
    dispatch(getBuddiesRequest());
    try {
      axios
        .get(url)
        .then((res) => dispatch(getBuddiesSuccess(res.data)))
        .catch((err) => dispatch(getBuddiesFail(err)));
    } catch (error) {
      dispatch(getBuddiesFail(error));
    }
  };
}

export function getSpocksAction() {
  return (dispatch) => {
    dispatch(getSpocksRequest());
    try {
      axios
        .get(url2)
        .then((res) => dispatch(getSpocksSuccess(res.data)))
        // .catch((err) => dispatch(getSpocksFail(err)));
    } catch (error) {
      dispatch(getSpocksFail(error));
    }
  };
}
