import axios from "axios";
import { getRoles } from "../constants/constants";
const { LOADING, USER_LIST_SUCCESS, USER_LIST_FAIL } = getRoles;

let url = `${process.env.REACT_APP_DEV}/api/getHrRoleList`;

const getRolesRequest = () => {
  return {
    type: LOADING,
  };
};

const getRolesSuccess = (list) => {
  return {
    type: USER_LIST_SUCCESS,
    payload: list,
  };
};
const getRolesFail = (error) => {
  return {
    type: USER_LIST_FAIL,
    payload: error,
  };
};

export function getRolesAction() {
  return (dispatch) => {
    dispatch(getRolesRequest());
    try {
      axios
        .get(url)
        .then((res) => dispatch(getRolesSuccess(res.data)))
        .catch((err) => dispatch(getRolesFail(err)));
    } catch (error) {
      dispatch(getRolesFail(error));
    }
  };
}
