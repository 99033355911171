import React from "react";
import { Redirect } from "react-router-dom";



import { Container, Row, Col } from "reactstrap";
import Header from "../candidates/Header";
import Footer from "../candidates/Footer";

const CandidateLayoutRoute = ({ children }) => {
  const candidateType = JSON.parse(sessionStorage.getItem("candidate")); 
  if(candidateType===null){
    return <Redirect to="/" />
  }
  return (
    <><Header />
    <Container className="TopBottomSpace">
      <Row>
        <Col md={12}>
        {children}
        </Col>
      </Row>
    </Container>
    <Footer />
      
    </>
  );
};

export default CandidateLayoutRoute;
