import { postEmployeeTestimonials } from "../constants/constants";

const { LOADING, POST_TESTIMONIALS_SUCCESS, POST_TESTIMONIALS_FAIL } =
  postEmployeeTestimonials;

const intialState = {
  loading: null,
  testimonial: null,
  error: "",
};

export const employeeTestimonialReducer = (state = intialState, action) => {
  switch (action.type) {
    case LOADING:
      return {
        ...state,
        loading: true,
      };
    case POST_TESTIMONIALS_SUCCESS:
      return {
        ...state,
        loading: false,
        testimonial: action.payload,
      };
    case POST_TESTIMONIALS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};
