import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./Company_Gallery.css";
import {
  showErrorSnackbar,
  showSuccessSnackbar,
  updateNavbarHeading,
} from "../../../redux/actions/uiActions";
import axios from "axios";
import { getCommonListAction } from "../../../redux/actions/getCommonList";
import { columns } from "./columns";
import PaginationTable from "../../adminUi/PaginationTable";
import ViewImage from "./ViewImage";
import DeleteModal from "./DeleteGalleryModal";
import VideoModel from "../../adminUi/VideoModel";

import { Loader } from "../../../loader/Loader";

let url = `${process.env.REACT_APP_DEV}/api/admin/getCompanyGallery`;

const Company_Gallery = () => {
  const { companyList } = useSelector((state) => state.getCompanyListReducer);

  const [loader, setLoader] = useState(false);

  const { loading, list } = useSelector((state) => state.getCommonListReducer);
  const [gallery, setGallery] = useState({
    companyId: "",
    file: "",
    type: "",
  });
  const dispatch = useDispatch();
  const restFileRef = useRef();
  const restInputFile = () => {
    restFileRef.current.value = "";
  };

  useEffect(() => {
    dispatch(updateNavbarHeading("Company Gallery"));
    dispatch(getCommonListAction(url));
  }, []);

  const handleChange = (e) => {
    if (e.target.type === "file") {
      let file = e.target.files[0];
      const fileType = gallery?.type === "image" ? 2097152 : 10485760;
      if (file?.size >= fileType) {
        const input = document.querySelector("#imageInputFile");
        input.value = "";
        return dispatch(
          showErrorSnackbar(
            "File not supported, Please check the size and type of file",
            "error"
          )
        );
      }
      setGallery({
        ...gallery,
        [e.target.name]: file,
      });
    } else if (e.target.name === "type") {
      setGallery({
        ...gallery,
        [e.target.name]: e.target.value,
      });
      if (gallery?.type !== "") {
        restInputFile();
      }
      else {
        restInputFile();
      }
    }
    else {
      setGallery({
        ...gallery,
        [e.target.name]: e.target.value,
      });
    }
  };
  const handleSubmit = (e) => {
    setLoader(true);
    e.preventDefault();
    let data = new FormData();
    data.append("selectCompany", parseInt(gallery.companyId));
    data.append("file0", gallery.file);
    data.append("type", gallery.type);
    if (gallery.type == "") {
      setLoader(false);
      dispatch(showErrorSnackbar("please select image/video type", "error"));
    } else {
      setLoader(true);
      axios
        .post(`${process.env.REACT_APP_DEV}/api/admin/addCompanyGallery`, data)
        .then((res) => {
          setLoader(false);
          dispatch(
            showSuccessSnackbar("Gallery addedd successfully", "success")
          );
          dispatch(getCommonListAction(url));
        })
        .catch((err) => {
          setLoader(false);
          dispatch(showErrorSnackbar("Something went wrong", "error"));
        });
    }
  };
  if (loading) {
    return <div>Loading...</div>;
  }

  let data =
    list.length > 0 &&
    list
      ?.filter((li) => li?.companyLocationId == gallery?.companyId)
      ?.map((li, idx) => {
        let str = li.video;
        let strr = str?.length;
        let name = str?.substring(0, strr - 4);
        return {
          idx: idx + 1,
          name: name,
          type: li.galleryType,
          image: li.image.endsWith("mp4") ? (
            <VideoModel src={li.image} />
          ) : (
            <ViewImage src={li.image} />
          ),
          delete: <DeleteModal id={li.id} />,
        };
      });

  if (loader) return <Loader open="true" />;
  return (
    <>
      <form onSubmit={handleSubmit}>
        <section className="content">
          <div className="content-wrapper">
            <div className="container-fluid">
              <div className="Company_Gallery Company_Gallery-primary ">
                <div className="row">
                  <div className="col-lg-12 col-md-12 col-sm-12">
                    <p className="Note">
                      <b>Note:</b> Kindly upload the image in JPEG/PNG only,
                      Image should not be more than 2MB and the Video in MP4
                      format only, Video size should not be more than 10MB.{" "}
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-6 col-md-6 col-sm-6">
                    <div className="required">
                      <p className="input-labels">Company</p>
                    </div>
                    <div className="form-group compny_grp">
                      <select
                        name="companyId"
                        onChange={handleChange}
                        className="form-control"
                        required
                      >
                        <option value="" selected disabled="selected">
                          Select-Company Location
                        </option>
                        {companyList.map((company) => (
                          <option value={company.companyId} key={company}>
                            {company.companyName}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-10 col-md-10 col-sm-6">
                    <div className="upload">
                      <p className="input-labels">What do you want to upload</p>
                      <br />
                      <div className="form-group grp">
                        <div className="form-check checks_image">
                          <input
                            className="form-check-input check"
                            class="selectfileclass"
                            type="radio"
                            value="image"
                            name="type"
                            onChange={handleChange}
                          />
                          <label className="form-check-label labels input-labels">
                            Image
                          </label>
                        </div>{" "}
                        <br />
                        <div className="form-check checks_video">
                          <input
                            className="form-check-input check"
                            class="selectfileclass"
                            type="radio"
                            value="video"
                            name="type"
                            onChange={handleChange}
                          />
                          <label className="form-check-label labels input-labels">
                            Video
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-6 col-md-6 col-sm-6">
                    <div className="upload-image">
                      <p className="input-labels"> Upload</p>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-6 col-md-6 col-sm-6">
                    <div className="">
                      {gallery?.type === "video" ? (
                        <input
                          type="file"
                          class="form-control"
                          id="imageInputFile"
                          name="file"
                          accept=" .mp4"
                          required
                          ref={restFileRef}
                          onChange={handleChange}
                        />
                      ) : (
                        <input
                          type="file"
                          class="form-control"
                          id="imageInputFile"
                          name="file"
                          accept=".jpg, .jpeg, .png"
                          required
                          ref={restFileRef}
                          onChange={handleChange}
                        />
                      )}
                    </div>
                    <div className="submmit">
                      <button type="Submit" class="btn4 btn-primary mt-2">
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12">
                  <div>
                    {gallery.companyId === "" ? (
                      <div className="card-footer pt-3 pb-3 ml-1 mr-1">
                        <h5 className="ml-3">
                          Select a company to see details
                        </h5>
                      </div>
                    ) : (
                      <div className="card-footer">
                        {data?.length > 0 ? (
                          <PaginationTable
                            columnsArr={columns}
                            dataArr={data}
                          />
                        ) : (
                          <div>Nothing found for this company</div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </form>
    </>
  );
};
export default Company_Gallery;
