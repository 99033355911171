import { getAssignProcess } from "../constants/constants";
const { LOADING, GET_ASSIGN_LIST_SUCCESS, GET_ASSIGN_LIST_FAIL } =
  getAssignProcess;

const intialState = {
  loading: null,
  processList: [],
  error: "",
};

export const getAssignProcessReducer = (state = intialState, action) => {
  switch (action.type) {
    case LOADING:
      return {
        ...state,
        loading: true,
      };
    case GET_ASSIGN_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        processList: action.payload,
      };
    case GET_ASSIGN_LIST_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};
