import axios from "axios";
import { Get_Leader } from "../constants/constants";
const { LOADING, LEADER_GET_SUCCESS, LEADER_GET_FAIL } = Get_Leader;

let url = `${process.env.REACT_APP_DEV}/api/admin/getCompanyWiseLeadersList `;

const getleaderRequest = () => {
  return {
    type: LOADING,
  };
};

const getleaderSuccess = (list) => {
  return {
    type: LEADER_GET_SUCCESS,
    payload: list,
  };
};
const getleaderFail = (error) => {
  return {
    type: LEADER_GET_FAIL,
    payload: error,
  };
};

export function getLeaderAction() {
  return (dispatch) => {
    dispatch(getleaderRequest());
    try {
      axios
        .get(url)
        .then((res) => dispatch(getleaderSuccess(res.data)))
        .catch((err) => dispatch(getleaderFail(err)));
    } catch (error) {
      dispatch(getleaderFail(error));
    }
  };
}
