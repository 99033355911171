import { reviewForm } from "../constants/constants";
const initialState = {
    loading: null,
    reviewFormList: [],
    error: "",
};

const { LOADING, REVIEW_FORM_SUCCESS, REVIEW_FORM_FAIL, } =
    reviewForm;

export const reviewFormReducer = (state = initialState, action) => {
    switch (action.type) {
        case LOADING:
            return {
                ...state,
                loading: true,
            };
        case REVIEW_FORM_SUCCESS:
            return {
                ...state,
                loading: false,
                reviewFormList: action.payload,
            };
        case REVIEW_FORM_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        default:
            return state;
    }
};
