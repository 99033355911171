export const columns = [
  {
    Header: "S.No.",
    accessor: "idx",
  },
  {
    Header: "Hotel Name",
    accessor: "hotelName",
  },
  {
    Header: "Hotel Address",
    accessor: "hotelAddress",
  },
  {
    Header: "Hotel Address2",
    accessor: "hotelAddress2",
  },
  {
    Header: "Hotel Address3",
    accessor: "hotelAddress3",
  },
  {
    Header: "Hotel Address4",
    accessor: "hotelAddress4",
  },
  {
    Header: "Hotel Address5",
    accessor: "hotelAddress5",
  },
  {
    Header: "Action",
    accessor: "action_button",
  },
  {
    Header: "",
    accessor: "action_button2",
  },
];
