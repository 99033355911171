import { combineReducers } from "redux";
import { adminReducer } from "./adminReducer";
import { updateNavbarHeadingReducer } from "./uiReducers";
import { createRoleReducer } from "./createUserReducer";
import { getHrRolesReducer } from "./getHrRoles";
import { snackbarReducer } from "./uiReducers";
import { getCompanyListReducer } from "./getCompanyListReducer";
import { employeeTestimonialReducer } from "./employeeTestimonialReducer";
import { getEmployeeTestimonialReducer } from "./getEmployeeTestimonialReducer";
import { getBuddiesReducer } from "./getBuddiesReducer";
import { getCompanyDetailsListReducer } from "./getCompanyDetailsReducer";
import { getAssignProcessReducer } from "./getAssignProcessReducer";
import { getCommonListReducer } from "./getCommonListReducer";
import { getPolicyListReducer } from "./getPolicyListReducers";
import { getBenefitListReducer } from "./getBenefitListreducers";
import { getAssetListReducer } from "./getAssetListReducer";

import { companyHolidayReducer } from "./companyHolidayReducer";
import { companyNotificationReducer } from "./companyNotificationReducer";
import { getLeaderReducer } from "./getleaderreducer";
import { getHrAccessListReducer } from "./hrAccessListReducer";
import { getHrRoleDataByIdReducer } from "./getHrRoleDataByIdReducer";
import { candidatePreOnBoardReducer } from "./cendiateDetailsPreOnboardReducer"
import { reviewFormReducer } from "./reviewFormReducer";
import {getReviewFormReducer} from "./getReviewFormReducer"
const RootReducer = combineReducers({
  adminReducer,
  updateNavbarHeadingReducer,
  createRoleReducer,
  getHrRolesReducer,
  snackbarReducer,
  getCompanyListReducer,
  employeeTestimonialReducer,
  getEmployeeTestimonialReducer,
  getBuddiesReducer,
  getCompanyDetailsListReducer,
  getAssignProcessReducer,
  getCommonListReducer,
  getPolicyListReducer,
  getBenefitListReducer,
  getAssetListReducer,
  companyHolidayReducer,
  companyNotificationReducer,
  getLeaderReducer,
  getHrAccessListReducer,
  getHrRoleDataByIdReducer,
  candidatePreOnBoardReducer,
  reviewFormReducer,
  getReviewFormReducer
});

export default RootReducer;
