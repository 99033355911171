import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import Snackbar from "../../../Components/uiComponets/snackbar/Snackbar";
import { getCommonListAction } from "../../../redux/actions/getCommonList";
import { RiDeleteBin6Line } from "react-icons/ri";
import { showErrorSnackbar, showSuccessSnackbar } from "../../../redux/actions/uiActions";
import { Loader } from "../../../loader/Loader";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    width: "40rem",
  },
  card: {
    marginBottom: "0",
    width: "100%",
  },
}));

let url = `${process.env.REACT_APP_DEV}/api/admin/getCompanyGallery`;
export default function TransitionsModal({ id }) {
  const dispatch = useDispatch();
  const deleteCompanyGallery = (deleteValue) => {
    const config = {
      method: "post",
      url: `${process.env.REACT_APP_DEV}/api/admin/deleteCompanyGallery`,
      data: deleteValue,
    };
    return axios(config);
  };

  const classes = useStyles();

  const [open, setOpen] = useState(false);
  
  const [loader, setLoader] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const deleteHandler = async (e) => {
    setLoader(true);
    e.preventDefault();
    let editValue = {
      id: id,
    };
    const res = await deleteCompanyGallery(editValue);
    if (res.status === 200) {
      setLoader(false);
      dispatch(showSuccessSnackbar("Company Gallery deleted successfully","success"))
      dispatch(getCommonListAction(url));
      handleClose();
    } else {
      setLoader(false);
      dispatch(showErrorSnackbar("something went wrong","error"))
    }
  };

  if (loader)
    return (
        <Loader open="true" />
    );
  return (
    <div>
      <span type="button" onClick={handleOpen}>
        <RiDeleteBin6Line size={20} />
      </span>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <div className={`card ${classes.card}`}>
              <div className="card-header">
                <h3 className="card-title">Are you sure you want to Delete?</h3>
              </div>
              <div className="card-body">
                <form style={{ width: "100%" }}>
                  <section className="text-right d-flex ">
                    <button
                      type="submit"
                      className="btn btn-sm btn-primary text-white"
                      onClick={deleteHandler}
                    >
                      Yes
                    </button>
                    <button
                      onClick={handleClose}
                      type="submit"
                      className="btn btn-sm btn-primary ml-1 text-white"
                    >
                      No
                    </button>
                  </section>
                </form>
              </div>
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}
