import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import axios from "axios";
import Snackbar from "../../../../Components/uiComponets/snackbar/Snackbar";
import { useDispatch } from "react-redux";
import { getPolicyListAction } from "../../../../redux/actions/getPolicyList";
import { AiFillEye } from "react-icons/ai";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    width: "40rem",
  },
  card: {
    marginBottom: "0",
    width: "100%",
  },
}));

export default function TransitionsModal({ companyId }) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [notification, setNotification] = React.useState({
    success: null,
    fail: null,
  });
  const [addKey, setAddKey] = React.useState({
    file: "",
    policyName: "",
    category: "",
    type1: "",
    type2: "",
  });

  const handleChange = (e) => {
    if (e.target.type === "file") {
      setAddKey({
        ...addKey,
        [e.target.name]: e.target.files[0],
      });
    } else {
      setAddKey({
        ...addKey,
        [e.target.name]: e.target.value,
      });
    }
  };
  React.useEffect(() => {
    setNotification({ success: false, fail: false });
  }, []);
  const handleSubmit = (e) => {
    e.preventDefault();
    let data = new FormData();
    data.append("file", addKey.file);
    data.append("policyName", addKey.policyName);
    data.append("type1", true);
    data.append("type2", false);
    data.append("category", addKey.category);
    data.append("companyId", companyId);
    axios
      .post(`${process.env.REACT_APP_DEV}/api/addPolicy`, data)
      .then((res) => {
        setNotification({ success: true, fail: false });
        dispatch(getPolicyListAction({ companyId }));
        handleClose();
      })
      .catch((err) => {
        setNotification({ success: false, fail: true });
      });
  };
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <span type="button" onClick={handleOpen}>
        <AiFillEye />
      </span>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <div className={`card ${classes.card}`}>
              <div className="card-header">
                <h3 className="card-title"> POLICY</h3>
              </div>
              <div className="card-body">
                <form onSubmit={handleSubmit} style={{ width: "100%" }}>
                  {/* <section>
                    <select
                      name="companyId"
                      onChange={handleChange}
                      className="form-control"
                    >
                      <option>Select Company</option>
                      {companyList.map((company) => (
                        <option value={company.companyId} key={company}>
                          {company.companyName}
                        </option>
                      ))}
                    </select>
                  </section> */}
                  <section>
                    <div className="form-group">
                      <label>Policy Name</label>
                      <input
                        name="policyName"
                        className="form-control"
                        onChange={handleChange}
                        required
                        placeholder="Enter a policy name"
                      />
                    </div>
                  </section>
                  <section className="d-flex mb-4 justify-content-around">
                    <div class="form-check">
                      <input
                        class="form-check-input"
                        type="radio"
                        name="type1"
                        id="flexRadioDefault1"
                        onChange={handleChange}
                      />
                      <label class="form-check-label" for="flexRadioDefault1">
                        Pre OnBoarding
                      </label>
                    </div>
                    <div class="form-check">
                      <input
                        class="form-check-input"
                        type="radio"
                        name="type2"
                        id="flexRadioDefault2"
                        onChange={handleChange}
                      />
                      <label class="form-check-label" for="flexRadioDefault2">
                        Boarding
                      </label>
                    </div>
                  </section>

                  <section className="d-flex justify-content-between">
                    <div className="form-group ">
                      <label>Policy Category</label>
                      <select
                        name="category"
                        onChange={handleChange}
                        className="form-control"
                      >
                        <option>Choose</option>
                        <option>test123</option>
                        <option>ttes123</option>
                      </select>
                    </div>
                    <div className="form-group d-flex flex-column">
                      <label>Photo*</label>
                      <input
                        name="file"
                        type="file"
                        className="form-control"
                        accept=".jpg, .jpeg, .png"
                        required
                        onChange={handleChange}
                      />
                    </div>
                  </section>

                  <section className="text-right d-flex ">
                    <button
                      type="submit"
                      className="btn btn-sm btn-primary text-white"
                    >
                      Submit
                    </button>
                    <button
                      className="btn btn-sm btn-primary ml-1 text-white"
                      onClick={handleClose}
                    >
                      close
                    </button>
                  </section>
                </form>
                {notification.fail && (
                  <Snackbar msg="Something went wrong" type="error" />
                )}
              </div>
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}
